import { useState, useEffect } from 'react';
import BottomBar from './BottomBar';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Accordion, Button, Form, Card } from 'react-bootstrap';
import ReactSnackBar from 'react-js-snackbar';
import ReactHtmlParser from 'react-html-parser';
import { convertTextToHanja, addHanjaMeaningTooltip } from './hanjaConverter';
import ReactGA from 'react-ga';

function App() {
    ReactGA.initialize('UA-185250602-4');
    ReactGA.pageview(window.location.pathname + window.location.search);

    const [hanjaDifficultyDegree, setHanjaDifficultyDegree] = useState(50);
    const [input, setInput] = useState(
        '한글로 아무 문장이나 쓰면 음이 비슷한 한자로 글자를 변환해 드립니다. 척 보기엔 중국어 문장처럼 보이지만 실제로는 우리말로 읽을 수 있는 한자 문장을 만들 수 있어요.\n\n친구에게 보낼 메시지를 한자로 바꿔서 보내면 친구의 한자음독 능력을 테스트할 수 있습니다. 난이도 조절도 가능해요.'
    );
    const [output, setOutput] = useState('');
    const [outputSounds, setOutputSounds] = useState('');
    const [snackbarShow, setSnackbarShow] = useState(false);
    const [snackbarShowing, setSnackbarShowing] = useState(false);

    useEffect(() => {
        convertText();
    }, [hanjaDifficultyDegree]);

    const handleInputChange = (e) => {
        e.preventDefault();
        setInput(e.target.value);
    };
    const handleChangeForHanjaDifficultyDegree = (e) => {
        setHanjaDifficultyDegree(e.target.value);
    };
    const clearText = (e) => {
        setInput('');
        setOutput('');
        setOutputSounds('');
    };
    const convertText = (e) => {
        const [newOutput, newOutputSounds] = convertTextToHanja(
            input,
            hanjaDifficultyDegree
        );
        setOutput(newOutput);
        setOutputSounds(newOutputSounds);
    };
    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        document.body.appendChild(textArea);
        textArea.value = text;
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };
    const showSnackbar = () => {
        if (snackbarShowing) {
            return;
        }
        setSnackbarShowing(true);
        setSnackbarShow(true);
        setTimeout(() => {
            setSnackbarShow(false);
        }, 2000);
        setTimeout(() => {
            setSnackbarShowing(false);
        }, 3600);
    };
    const copyOutputToClipboard = (e) => {
        copyToClipboard(output);
        showSnackbar();
    };

    return (
        <>
            <div className="container-fluid padding">
                <div className="row title text-center">
                    <div className="col-12 m-0 pb-0">
                        <h1 className="display-4 my-2">
                            <a
                                href="https://tohanja.hanmesoft.com"
                                className="text-decoration-none"
                            >
                                한자음독 능력시험
                            </a>
                        </h1>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                    <div className="col-sm-10 col-md-6 col-xl-4 m-0 py-0 px-3">
                        <textarea
                            className="form-control my-2"
                            placeholder="한자로 변환할 문장들을 여기에 입력하고 '변환하기' 버튼을 누르세요."
                            rows="8"
                            onChange={handleInputChange}
                            value={input}
                        />
                        <Button
                            variant="primary"
                            className="m-2"
                            onClick={convertText}
                            disabled={input === ''}
                            style={{
                                minWidth: '266px',
                                minHeight: '60px',
                            }}
                        >
                            변환하기
                        </Button>
                        <Accordion>
                            <Button
                                variant="info"
                                className="m-2"
                                onClick={clearText}
                                disabled={input === ''}
                            >
                                입력창 지우기
                            </Button>
                            <Accordion.Toggle
                                as={Button}
                                variant="info"
                                className="m-2"
                                eventKey="0"
                            >
                                난이도 조절
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card bg="light" className="m-2 my-0">
                                    <Card.Body>
                                        <Form>
                                            <Form.Group
                                                controlId="formRange1"
                                                className="m-0 p-0"
                                            >
                                                <Form.Label className="m-0 p-0 mb-2">
                                                    한자 난이도:{' '}
                                                    {hanjaDifficultyDegree}
                                                </Form.Label>
                                                <Form.Control
                                                    type="range"
                                                    step="10"
                                                    value={
                                                        hanjaDifficultyDegree
                                                    }
                                                    onChange={
                                                        handleChangeForHanjaDifficultyDegree
                                                    }
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </Accordion>
                        {output !== '' && (
                            <>
                                <h5 className="mt-2 mb-0">변환된 문장:</h5>
                                <Card
                                    bg="light"
                                    className="mx-0 my-2 pl-1 text-left"
                                >
                                    <Card.Body className="p-2">
                                        {ReactHtmlParser(
                                            addHanjaMeaningTooltip(
                                                output
                                            ).replace(/\n/g, '<br>')
                                        )}
                                    </Card.Body>
                                </Card>
                                <Button
                                    variant="info"
                                    className="m-2"
                                    onClick={copyOutputToClipboard}
                                    style={{
                                        minWidth: '266px',
                                    }}
                                >
                                    클립보드에 복사
                                </Button>
                                <h5 className="mt-2 mb-0">한자 음독 정답:</h5>
                                <Card
                                    bg="light"
                                    className="mx-0 my-2 pl-1 text-left"
                                >
                                    <Card.Body className="p-2">
                                        {ReactHtmlParser(
                                            outputSounds.replace(/\n/g, '<br>')
                                        )}
                                    </Card.Body>
                                </Card>
                            </>
                        )}
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                </div>
            </div>
            <ReactSnackBar Icon={<span>📝</span>} Show={snackbarShow}>
                <div className="text-center">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;변환된 텍스트가
                    복사되었습니다.
                </div>
            </ReactSnackBar>
            <BottomBar />
        </>
    );
}

export default App;
