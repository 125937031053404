import Hangul from 'hangul-js';

const toClosestHanjaSound = {
    가: '가',
    각: '각',
    갂: '각',
    갃: '각',
    간: '간',
    갅: '간',
    갆: '간',
    갇: '각',
    갈: '갈',
    갉: '갈',
    갊: '갈',
    갋: '갈',
    갌: '갈',
    갍: '갈',
    갎: '갈',
    갏: '갈',
    감: '감',
    갑: '갑',
    값: '갑',
    갓: '각',
    갔: '각',
    강: '강',
    갖: '각',
    갗: '각',
    갘: '각',
    같: '각',
    갚: '갑',
    갛: '가',
    개: '개',
    객: '객',
    갞: '객',
    갟: '객',
    갠: '간',
    갡: '간',
    갢: '간',
    갣: '객',
    갤: '갈',
    갥: '갈',
    갦: '갈',
    갧: '갈',
    갨: '갈',
    갩: '갈',
    갪: '갈',
    갫: '갈',
    갬: '감',
    갭: '갑',
    갮: '갑',
    갯: '객',
    갰: '객',
    갱: '갱',
    갲: '객',
    갳: '객',
    갴: '객',
    갵: '객',
    갶: '갑',
    갷: '개',
    갸: '가',
    갹: '각',
    갺: '각',
    갻: '각',
    갼: '간',
    갽: '간',
    갾: '간',
    갿: '각',
    걀: '갈',
    걁: '갈',
    걂: '갈',
    걃: '갈',
    걄: '갈',
    걅: '갈',
    걆: '갈',
    걇: '갈',
    걈: '감',
    걉: '갑',
    걊: '갑',
    걋: '각',
    걌: '각',
    걍: '강',
    걎: '각',
    걏: '각',
    걐: '각',
    걑: '각',
    걒: '갑',
    걓: '가',
    걔: '개',
    걕: '객',
    걖: '객',
    걗: '객',
    걘: '갱',
    걙: '갱',
    걚: '갱',
    걛: '객',
    걜: '갱',
    걝: '갱',
    걞: '갱',
    걟: '갱',
    걠: '갱',
    걡: '갱',
    걢: '갱',
    걣: '갱',
    걤: '갱',
    걥: '갱',
    걦: '갱',
    걧: '객',
    걨: '객',
    걩: '갱',
    걪: '객',
    걫: '객',
    걬: '객',
    걭: '객',
    걮: '객',
    걯: '개',
    거: '거',
    걱: '격',
    걲: '격',
    걳: '격',
    건: '건',
    걵: '건',
    걶: '건',
    걷: '격',
    걸: '걸',
    걹: '걸',
    걺: '걸',
    걻: '걸',
    걼: '걸',
    걽: '걸',
    걾: '걸',
    걿: '걸',
    검: '검',
    겁: '검',
    겂: '검',
    것: '격',
    겄: '격',
    겅: '경',
    겆: '격',
    겇: '격',
    겈: '격',
    겉: '격',
    겊: '검',
    겋: '거',
    게: '개',
    겍: '객',
    겎: '객',
    겏: '객',
    겐: '견',
    겑: '견',
    겒: '견',
    겓: '객',
    겔: '결',
    겕: '결',
    겖: '결',
    겗: '결',
    겘: '결',
    겙: '결',
    겚: '결',
    겛: '결',
    겜: '겸',
    겝: '겸',
    겞: '겸',
    겟: '객',
    겠: '객',
    겡: '갱',
    겢: '객',
    겣: '객',
    겤: '객',
    겥: '객',
    겦: '겸',
    겧: '개',
    겨: '계',
    격: '격',
    겪: '격',
    겫: '격',
    견: '견',
    겭: '견',
    겮: '견',
    겯: '격',
    결: '결',
    겱: '결',
    겲: '결',
    겳: '결',
    겴: '결',
    겵: '결',
    겶: '결',
    겷: '결',
    겸: '겸',
    겹: '겸',
    겺: '겸',
    겻: '격',
    겼: '격',
    경: '경',
    겾: '격',
    겿: '격',
    곀: '격',
    곁: '격',
    곂: '겸',
    곃: '계',
    계: '계',
    곅: '격',
    곆: '격',
    곇: '격',
    곈: '견',
    곉: '견',
    곊: '견',
    곋: '격',
    곌: '결',
    곍: '결',
    곎: '결',
    곏: '결',
    곐: '결',
    곑: '결',
    곒: '결',
    곓: '결',
    곔: '겸',
    곕: '겸',
    곖: '겸',
    곗: '격',
    곘: '격',
    곙: '경',
    곚: '격',
    곛: '격',
    곜: '격',
    곝: '격',
    곞: '겸',
    곟: '계',
    고: '고',
    곡: '곡',
    곢: '곡',
    곣: '곡',
    곤: '곤',
    곥: '곤',
    곦: '곤',
    곧: '곡',
    골: '골',
    곩: '골',
    곪: '골',
    곫: '골',
    곬: '골',
    곭: '골',
    곮: '골',
    곯: '골',
    곰: '감',
    곱: '갑',
    곲: '갑',
    곳: '곡',
    곴: '곡',
    공: '공',
    곶: '곡',
    곷: '곡',
    곸: '곡',
    곹: '곡',
    곺: '갑',
    곻: '고',
    과: '과',
    곽: '곽',
    곾: '곽',
    곿: '곽',
    관: '관',
    괁: '관',
    괂: '관',
    괃: '곽',
    괄: '갈',
    괅: '갈',
    괆: '갈',
    괇: '갈',
    괈: '갈',
    괉: '갈',
    괊: '갈',
    괋: '갈',
    괌: '감',
    괍: '갑',
    괎: '갑',
    괏: '곽',
    괐: '곽',
    광: '광',
    괒: '곽',
    괓: '곽',
    괔: '곽',
    괕: '곽',
    괖: '갑',
    괗: '과',
    괘: '괘',
    괙: '객',
    괚: '객',
    괛: '객',
    괜: '권',
    괝: '권',
    괞: '권',
    괟: '객',
    괠: '궐',
    괡: '궐',
    괢: '궐',
    괣: '궐',
    괤: '궐',
    괥: '궐',
    괦: '궐',
    괧: '궐',
    괨: '검',
    괩: '검',
    괪: '검',
    괫: '객',
    괬: '객',
    괭: '갱',
    괮: '객',
    괯: '객',
    괰: '객',
    괱: '객',
    괲: '검',
    괳: '괘',
    괴: '괴',
    괵: '곡',
    괶: '곡',
    괷: '곡',
    괸: '곤',
    괹: '곤',
    괺: '곤',
    괻: '곡',
    괼: '골',
    괽: '골',
    괾: '골',
    괿: '골',
    굀: '골',
    굁: '골',
    굂: '골',
    굃: '골',
    굄: '검',
    굅: '공',
    굆: '공',
    굇: '곡',
    굈: '곡',
    굉: '공',
    굊: '곡',
    굋: '곡',
    굌: '곡',
    굍: '곡',
    굎: '검',
    굏: '괴',
    교: '교',
    굑: '곡',
    굒: '곡',
    굓: '곡',
    굔: '곤',
    굕: '곤',
    굖: '곤',
    굗: '곡',
    굘: '골',
    굙: '골',
    굚: '골',
    굛: '골',
    굜: '골',
    굝: '골',
    굞: '골',
    굟: '골',
    굠: '겸',
    굡: '겸',
    굢: '겸',
    굣: '곡',
    굤: '곡',
    굥: '공',
    굦: '곡',
    굧: '곡',
    굨: '곡',
    굩: '곡',
    굪: '겸',
    굫: '교',
    구: '구',
    국: '국',
    굮: '국',
    굯: '국',
    군: '군',
    굱: '군',
    굲: '군',
    굳: '국',
    굴: '굴',
    굵: '굴',
    굶: '굴',
    굷: '굴',
    굸: '굴',
    굹: '굴',
    굺: '굴',
    굻: '굴',
    굼: '금',
    굽: '급',
    굾: '급',
    굿: '국',
    궀: '국',
    궁: '궁',
    궂: '국',
    궃: '국',
    궄: '국',
    궅: '국',
    궆: '급',
    궇: '구',
    궈: '거',
    궉: '국',
    궊: '국',
    궋: '국',
    권: '권',
    궍: '권',
    궎: '권',
    궏: '국',
    궐: '궐',
    궑: '궐',
    궒: '궐',
    궓: '궐',
    궔: '궐',
    궕: '궐',
    궖: '궐',
    궗: '궐',
    궘: '검',
    궙: '검',
    궚: '검',
    궛: '국',
    궜: '국',
    궝: '궁',
    궞: '국',
    궟: '국',
    궠: '국',
    궡: '국',
    궢: '검',
    궣: '거',
    궤: '궤',
    궥: '곽',
    궦: '곽',
    궧: '곽',
    궨: '권',
    궩: '권',
    궪: '권',
    궫: '곽',
    궬: '궐',
    궭: '궐',
    궮: '궐',
    궯: '궐',
    궰: '궐',
    궱: '궐',
    궲: '궐',
    궳: '궐',
    궴: '검',
    궵: '검',
    궶: '검',
    궷: '곽',
    궸: '곽',
    궹: '광',
    궺: '곽',
    궻: '곽',
    궼: '곽',
    궽: '곽',
    궾: '검',
    궿: '궤',
    귀: '귀',
    귁: '국',
    귂: '국',
    귃: '국',
    귄: '군',
    귅: '군',
    귆: '군',
    귇: '국',
    귈: '굴',
    귉: '굴',
    귊: '굴',
    귋: '굴',
    귌: '굴',
    귍: '굴',
    귎: '굴',
    귏: '굴',
    귐: '궁',
    귑: '궁',
    귒: '궁',
    귓: '국',
    귔: '국',
    귕: '궁',
    귖: '국',
    귗: '국',
    귘: '국',
    귙: '국',
    귚: '국',
    귛: '귀',
    규: '규',
    귝: '국',
    귞: '국',
    귟: '국',
    균: '균',
    귡: '균',
    귢: '균',
    귣: '국',
    귤: '굴',
    귥: '굴',
    귦: '굴',
    귧: '굴',
    귨: '굴',
    귩: '굴',
    귪: '굴',
    귫: '굴',
    귬: '궁',
    귭: '궁',
    귮: '궁',
    귯: '국',
    귰: '국',
    귱: '궁',
    귲: '국',
    귳: '국',
    귴: '국',
    귵: '국',
    귶: '국',
    귷: '규',
    그: '구',
    극: '극',
    귺: '극',
    귻: '극',
    근: '근',
    귽: '근',
    귾: '근',
    귿: '극',
    글: '굴',
    긁: '굴',
    긂: '굴',
    긃: '굴',
    긄: '굴',
    긅: '굴',
    긆: '굴',
    긇: '굴',
    금: '금',
    급: '급',
    긊: '급',
    긋: '극',
    긌: '극',
    긍: '긍',
    긎: '극',
    긏: '극',
    긐: '극',
    긑: '극',
    긒: '급',
    긓: '구',
    긔: '기',
    긕: '극',
    긖: '극',
    긗: '극',
    긘: '긴',
    긙: '근',
    긚: '근',
    긛: '극',
    긜: '길',
    긝: '길',
    긞: '길',
    긟: '길',
    긠: '길',
    긡: '길',
    긢: '길',
    긣: '길',
    긤: '금',
    긥: '급',
    긦: '급',
    긧: '급',
    긨: '극',
    긩: '긍',
    긪: '극',
    긫: '극',
    긬: '극',
    긭: '극',
    긮: '급',
    긯: '기',
    기: '기',
    긱: '객',
    긲: '객',
    긳: '객',
    긴: '긴',
    긵: '긴',
    긶: '긴',
    긷: '객',
    길: '길',
    긹: '길',
    긺: '길',
    긻: '길',
    긼: '길',
    긽: '길',
    긾: '길',
    긿: '길',
    김: '긴',
    깁: '입',
    깂: '입',
    깃: '객',
    깄: '객',
    깅: '갱',
    깆: '객',
    깇: '객',
    깈: '객',
    깉: '객',
    깊: '입',
    깋: '기',
    까: '가',
    깍: '각',
    깎: '각',
    깏: '각',
    깐: '간',
    깑: '간',
    깒: '간',
    깓: '각',
    깔: '갈',
    깕: '갈',
    깖: '갈',
    깗: '갈',
    깘: '갈',
    깙: '갈',
    깚: '갈',
    깛: '갈',
    깜: '감',
    깝: '갑',
    깞: '갑',
    깟: '각',
    깠: '각',
    깡: '강',
    깢: '각',
    깣: '각',
    깤: '각',
    깥: '각',
    깦: '갑',
    깧: '가',
    깨: '개',
    깩: '객',
    깪: '객',
    깫: '객',
    깬: '간',
    깭: '간',
    깮: '간',
    깯: '객',
    깰: '갈',
    깱: '갈',
    깲: '갈',
    깳: '갈',
    깴: '갈',
    깵: '갈',
    깶: '갈',
    깷: '갈',
    깸: '감',
    깹: '갑',
    깺: '갑',
    깻: '객',
    깼: '객',
    깽: '갱',
    깾: '객',
    깿: '객',
    꺀: '객',
    꺁: '객',
    꺂: '갑',
    꺃: '개',
    꺄: '가',
    꺅: '각',
    꺆: '각',
    꺇: '각',
    꺈: '간',
    꺉: '간',
    꺊: '간',
    꺋: '각',
    꺌: '갈',
    꺍: '갈',
    꺎: '갈',
    꺏: '갈',
    꺐: '갈',
    꺑: '갈',
    꺒: '갈',
    꺓: '갈',
    꺔: '감',
    꺕: '갑',
    꺖: '갑',
    꺗: '각',
    꺘: '각',
    꺙: '강',
    꺚: '각',
    꺛: '각',
    꺜: '각',
    꺝: '각',
    꺞: '갑',
    꺟: '가',
    꺠: '개',
    꺡: '객',
    꺢: '객',
    꺣: '객',
    꺤: '갱',
    꺥: '갱',
    꺦: '갱',
    꺧: '객',
    꺨: '갱',
    꺩: '갱',
    꺪: '갱',
    꺫: '갱',
    꺬: '갱',
    꺭: '갱',
    꺮: '갱',
    꺯: '갱',
    꺰: '갱',
    꺱: '갱',
    꺲: '갱',
    꺳: '객',
    꺴: '객',
    꺵: '갱',
    꺶: '객',
    꺷: '객',
    꺸: '객',
    꺹: '객',
    꺺: '객',
    꺻: '개',
    꺼: '거',
    꺽: '격',
    꺾: '격',
    꺿: '격',
    껀: '건',
    껁: '건',
    껂: '건',
    껃: '격',
    껄: '걸',
    껅: '걸',
    껆: '걸',
    껇: '걸',
    껈: '걸',
    껉: '걸',
    껊: '걸',
    껋: '걸',
    껌: '검',
    껍: '검',
    껎: '검',
    껏: '격',
    껐: '격',
    껑: '경',
    껒: '격',
    껓: '격',
    껔: '격',
    껕: '격',
    껖: '검',
    껗: '거',
    께: '개',
    껙: '객',
    껚: '객',
    껛: '객',
    껜: '견',
    껝: '견',
    껞: '견',
    껟: '객',
    껠: '결',
    껡: '결',
    껢: '결',
    껣: '결',
    껤: '결',
    껥: '결',
    껦: '결',
    껧: '결',
    껨: '겸',
    껩: '겸',
    껪: '겸',
    껫: '객',
    껬: '객',
    껭: '갱',
    껮: '객',
    껯: '객',
    껰: '객',
    껱: '객',
    껲: '겸',
    껳: '개',
    껴: '계',
    껵: '격',
    껶: '격',
    껷: '격',
    껸: '견',
    껹: '견',
    껺: '견',
    껻: '격',
    껼: '결',
    껽: '결',
    껾: '결',
    껿: '결',
    꼀: '결',
    꼁: '결',
    꼂: '결',
    꼃: '결',
    꼄: '겸',
    꼅: '겸',
    꼆: '겸',
    꼇: '격',
    꼈: '격',
    꼉: '경',
    꼊: '격',
    꼋: '격',
    꼌: '격',
    꼍: '격',
    꼎: '겸',
    꼏: '계',
    꼐: '계',
    꼑: '격',
    꼒: '격',
    꼓: '격',
    꼔: '견',
    꼕: '견',
    꼖: '견',
    꼗: '격',
    꼘: '결',
    꼙: '결',
    꼚: '결',
    꼛: '결',
    꼜: '결',
    꼝: '결',
    꼞: '결',
    꼟: '결',
    꼠: '겸',
    꼡: '겸',
    꼢: '겸',
    꼣: '격',
    꼤: '격',
    꼥: '경',
    꼦: '격',
    꼧: '격',
    꼨: '격',
    꼩: '격',
    꼪: '겸',
    꼫: '계',
    꼬: '고',
    꼭: '곡',
    꼮: '곡',
    꼯: '곡',
    꼰: '곤',
    꼱: '곤',
    꼲: '곤',
    꼳: '곡',
    꼴: '골',
    꼵: '골',
    꼶: '골',
    꼷: '골',
    꼸: '골',
    꼹: '골',
    꼺: '골',
    꼻: '골',
    꼼: '감',
    꼽: '갑',
    꼾: '갑',
    꼿: '곡',
    꽀: '곡',
    꽁: '공',
    꽂: '곡',
    꽃: '곡',
    꽄: '곡',
    꽅: '곡',
    꽆: '갑',
    꽇: '고',
    꽈: '과',
    꽉: '곽',
    꽊: '곽',
    꽋: '곽',
    꽌: '관',
    꽍: '관',
    꽎: '관',
    꽏: '곽',
    꽐: '갈',
    꽑: '갈',
    꽒: '갈',
    꽓: '갈',
    꽔: '갈',
    꽕: '갈',
    꽖: '갈',
    꽗: '갈',
    꽘: '감',
    꽙: '갑',
    꽚: '갑',
    꽛: '곽',
    꽜: '곽',
    꽝: '광',
    꽞: '곽',
    꽟: '곽',
    꽠: '곽',
    꽡: '곽',
    꽢: '갑',
    꽣: '과',
    꽤: '쾌',
    꽥: '객',
    꽦: '객',
    꽧: '객',
    꽨: '권',
    꽩: '권',
    꽪: '권',
    꽫: '객',
    꽬: '궐',
    꽭: '궐',
    꽮: '궐',
    꽯: '궐',
    꽰: '궐',
    꽱: '궐',
    꽲: '궐',
    꽳: '궐',
    꽴: '검',
    꽵: '검',
    꽶: '검',
    꽷: '객',
    꽸: '객',
    꽹: '갱',
    꽺: '객',
    꽻: '객',
    꽼: '객',
    꽽: '객',
    꽾: '검',
    꽿: '쾌',
    꾀: '쾌',
    꾁: '곡',
    꾂: '곡',
    꾃: '곡',
    꾄: '곤',
    꾅: '곤',
    꾆: '곤',
    꾇: '곡',
    꾈: '골',
    꾉: '골',
    꾊: '골',
    꾋: '골',
    꾌: '골',
    꾍: '골',
    꾎: '골',
    꾏: '골',
    꾐: '검',
    꾑: '공',
    꾒: '공',
    꾓: '곡',
    꾔: '곡',
    꾕: '공',
    꾖: '곡',
    꾗: '곡',
    꾘: '곡',
    꾙: '곡',
    꾚: '검',
    꾛: '쾌',
    꾜: '교',
    꾝: '곡',
    꾞: '곡',
    꾟: '곡',
    꾠: '곤',
    꾡: '곤',
    꾢: '곤',
    꾣: '곡',
    꾤: '골',
    꾥: '골',
    꾦: '골',
    꾧: '골',
    꾨: '골',
    꾩: '골',
    꾪: '골',
    꾫: '골',
    꾬: '겸',
    꾭: '겸',
    꾮: '겸',
    꾯: '곡',
    꾰: '곡',
    꾱: '공',
    꾲: '곡',
    꾳: '곡',
    꾴: '곡',
    꾵: '곡',
    꾶: '겸',
    꾷: '교',
    꾸: '구',
    꾹: '국',
    꾺: '국',
    꾻: '국',
    꾼: '군',
    꾽: '군',
    꾾: '군',
    꾿: '국',
    꿀: '굴',
    꿁: '굴',
    꿂: '굴',
    꿃: '굴',
    꿄: '굴',
    꿅: '굴',
    꿆: '굴',
    꿇: '굴',
    꿈: '금',
    꿉: '급',
    꿊: '급',
    꿋: '국',
    꿌: '국',
    꿍: '궁',
    꿎: '국',
    꿏: '국',
    꿐: '국',
    꿑: '국',
    꿒: '급',
    꿓: '구',
    꿔: '쾌',
    꿕: '국',
    꿖: '국',
    꿗: '국',
    꿘: '권',
    꿙: '권',
    꿚: '권',
    꿛: '국',
    꿜: '궐',
    꿝: '궐',
    꿞: '궐',
    꿟: '궐',
    꿠: '궐',
    꿡: '궐',
    꿢: '궐',
    꿣: '궐',
    꿤: '검',
    꿥: '검',
    꿦: '검',
    꿧: '국',
    꿨: '국',
    꿩: '궁',
    꿪: '국',
    꿫: '국',
    꿬: '국',
    꿭: '국',
    꿮: '검',
    꿯: '쾌',
    꿰: '궤',
    꿱: '곽',
    꿲: '곽',
    꿳: '곽',
    꿴: '권',
    꿵: '권',
    꿶: '권',
    꿷: '곽',
    꿸: '궐',
    꿹: '궐',
    꿺: '궐',
    꿻: '궐',
    꿼: '궐',
    꿽: '궐',
    꿾: '궐',
    꿿: '궐',
    뀀: '검',
    뀁: '검',
    뀂: '검',
    뀃: '곽',
    뀄: '곽',
    뀅: '광',
    뀆: '곽',
    뀇: '곽',
    뀈: '곽',
    뀉: '곽',
    뀊: '검',
    뀋: '궤',
    뀌: '귀',
    뀍: '국',
    뀎: '국',
    뀏: '국',
    뀐: '군',
    뀑: '군',
    뀒: '군',
    뀓: '국',
    뀔: '굴',
    뀕: '굴',
    뀖: '굴',
    뀗: '굴',
    뀘: '굴',
    뀙: '굴',
    뀚: '굴',
    뀛: '굴',
    뀜: '궁',
    뀝: '궁',
    뀞: '궁',
    뀟: '국',
    뀠: '국',
    뀡: '궁',
    뀢: '국',
    뀣: '국',
    뀤: '국',
    뀥: '국',
    뀦: '국',
    뀧: '귀',
    뀨: '규',
    뀩: '국',
    뀪: '국',
    뀫: '국',
    뀬: '균',
    뀭: '균',
    뀮: '균',
    뀯: '국',
    뀰: '굴',
    뀱: '굴',
    뀲: '굴',
    뀳: '굴',
    뀴: '굴',
    뀵: '굴',
    뀶: '굴',
    뀷: '굴',
    뀸: '궁',
    뀹: '궁',
    뀺: '궁',
    뀻: '국',
    뀼: '국',
    뀽: '궁',
    뀾: '국',
    뀿: '국',
    끀: '국',
    끁: '국',
    끂: '국',
    끃: '규',
    끄: '구',
    끅: '극',
    끆: '극',
    끇: '극',
    끈: '근',
    끉: '근',
    끊: '근',
    끋: '극',
    끌: '굴',
    끍: '굴',
    끎: '굴',
    끏: '굴',
    끐: '굴',
    끑: '굴',
    끒: '굴',
    끓: '굴',
    끔: '금',
    끕: '급',
    끖: '급',
    끗: '극',
    끘: '극',
    끙: '긍',
    끚: '극',
    끛: '극',
    끜: '극',
    끝: '극',
    끞: '급',
    끟: '구',
    끠: '기',
    끡: '극',
    끢: '극',
    끣: '극',
    끤: '긴',
    끥: '근',
    끦: '근',
    끧: '극',
    끨: '길',
    끩: '길',
    끪: '길',
    끫: '길',
    끬: '길',
    끭: '길',
    끮: '길',
    끯: '길',
    끰: '금',
    끱: '급',
    끲: '급',
    끳: '급',
    끴: '극',
    끵: '긍',
    끶: '극',
    끷: '극',
    끸: '극',
    끹: '극',
    끺: '급',
    끻: '기',
    끼: '기',
    끽: '객',
    끾: '객',
    끿: '객',
    낀: '긴',
    낁: '긴',
    낂: '긴',
    낃: '객',
    낄: '길',
    낅: '길',
    낆: '길',
    낇: '길',
    낈: '길',
    낉: '길',
    낊: '길',
    낋: '길',
    낌: '긴',
    낍: '입',
    낎: '입',
    낏: '객',
    낐: '객',
    낑: '갱',
    낒: '객',
    낓: '객',
    낔: '객',
    낕: '객',
    낖: '입',
    낗: '기',
    나: '나',
    낙: '낙',
    낚: '낙',
    낛: '낙',
    난: '난',
    낝: '난',
    낞: '난',
    낟: '낙',
    날: '난',
    낡: '난',
    낢: '난',
    낣: '난',
    낤: '난',
    낥: '난',
    낦: '난',
    낧: '난',
    남: '남',
    납: '납',
    낪: '납',
    낫: '낙',
    났: '낙',
    낭: '낭',
    낮: '낙',
    낯: '낙',
    낰: '낙',
    낱: '낙',
    낲: '납',
    낳: '나',
    내: '내',
    낵: '낙',
    낶: '낙',
    낷: '낙',
    낸: '난',
    낹: '난',
    낺: '난',
    낻: '낙',
    낼: '난',
    낽: '난',
    낾: '난',
    낿: '난',
    냀: '난',
    냁: '난',
    냂: '난',
    냃: '난',
    냄: '남',
    냅: '납',
    냆: '납',
    냇: '낙',
    냈: '낙',
    냉: '낭',
    냊: '낙',
    냋: '낙',
    냌: '낙',
    냍: '낙',
    냎: '납',
    냏: '내',
    냐: '나',
    냑: '낙',
    냒: '낙',
    냓: '낙',
    냔: '난',
    냕: '난',
    냖: '난',
    냗: '낙',
    냘: '난',
    냙: '난',
    냚: '난',
    냛: '난',
    냜: '난',
    냝: '난',
    냞: '난',
    냟: '난',
    냠: '남',
    냡: '납',
    냢: '납',
    냣: '낙',
    냤: '낙',
    냥: '낭',
    냦: '낙',
    냧: '낙',
    냨: '낙',
    냩: '낙',
    냪: '납',
    냫: '나',
    냬: '내',
    냭: '략',
    냮: '략',
    냯: '략',
    냰: '랭',
    냱: '랭',
    냲: '랭',
    냳: '략',
    냴: '랭',
    냵: '랭',
    냶: '랭',
    냷: '랭',
    냸: '랭',
    냹: '랭',
    냺: '랭',
    냻: '랭',
    냼: '랭',
    냽: '랭',
    냾: '랭',
    냿: '략',
    넀: '략',
    넁: '랭',
    넂: '략',
    넃: '략',
    넄: '략',
    넅: '략',
    넆: '략',
    넇: '내',
    너: '녀',
    넉: '낙',
    넊: '낙',
    넋: '낙',
    넌: '년',
    넍: '년',
    넎: '년',
    넏: '낙',
    널: '렬',
    넑: '년',
    넒: '년',
    넓: '년',
    넔: '년',
    넕: '년',
    넖: '년',
    넗: '년',
    넘: '념',
    넙: '납',
    넚: '납',
    넛: '낙',
    넜: '낙',
    넝: '녕',
    넞: '낙',
    넟: '낙',
    넠: '낙',
    넡: '낙',
    넢: '납',
    넣: '녀',
    네: '내',
    넥: '력',
    넦: '력',
    넧: '력',
    넨: '년',
    넩: '년',
    넪: '년',
    넫: '력',
    넬: '렬',
    넭: '년',
    넮: '년',
    넯: '년',
    넰: '년',
    넱: '년',
    넲: '년',
    넳: '년',
    넴: '념',
    넵: '념',
    넶: '념',
    넷: '력',
    넸: '력',
    넹: '녕',
    넺: '력',
    넻: '력',
    넼: '력',
    넽: '력',
    넾: '념',
    넿: '내',
    녀: '녀',
    녁: '력',
    녂: '력',
    녃: '력',
    년: '년',
    녅: '년',
    녆: '년',
    녇: '력',
    녈: '렬',
    녉: '년',
    녊: '년',
    녋: '년',
    녌: '년',
    녍: '년',
    녎: '년',
    녏: '년',
    념: '념',
    녑: '념',
    녒: '념',
    녓: '력',
    녔: '력',
    녕: '녕',
    녖: '력',
    녗: '력',
    녘: '력',
    녙: '력',
    녚: '념',
    녛: '녀',
    녜: '녀',
    녝: '력',
    녞: '력',
    녟: '력',
    녠: '년',
    녡: '년',
    녢: '년',
    녣: '력',
    녤: '렬',
    녥: '년',
    녦: '년',
    녧: '년',
    녨: '년',
    녩: '년',
    녪: '년',
    녫: '년',
    녬: '념',
    녭: '념',
    녮: '념',
    녯: '력',
    녰: '력',
    녱: '녕',
    녲: '력',
    녳: '력',
    녴: '력',
    녵: '력',
    녶: '념',
    녷: '녀',
    노: '노',
    녹: '록',
    녺: '록',
    녻: '록',
    논: '론',
    녽: '론',
    녾: '론',
    녿: '록',
    놀: '돌',
    놁: '농',
    놂: '농',
    놃: '농',
    놄: '농',
    놅: '농',
    놆: '농',
    놇: '농',
    놈: '남',
    놉: '납',
    놊: '납',
    놋: '록',
    놌: '록',
    농: '농',
    놎: '록',
    놏: '록',
    놐: '록',
    놑: '록',
    높: '납',
    놓: '노',
    놔: '나',
    놕: '낙',
    놖: '낙',
    놗: '낙',
    놘: '난',
    놙: '난',
    놚: '난',
    놛: '낙',
    놜: '난',
    놝: '난',
    놞: '난',
    놟: '난',
    놠: '난',
    놡: '난',
    놢: '난',
    놣: '난',
    놤: '남',
    놥: '납',
    놦: '납',
    놧: '낙',
    놨: '낙',
    놩: '낭',
    놪: '낙',
    놫: '낙',
    놬: '낙',
    놭: '낙',
    놮: '납',
    놯: '나',
    놰: '뇌',
    놱: '록',
    놲: '록',
    놳: '록',
    놴: '론',
    놵: '론',
    놶: '론',
    놷: '록',
    놸: '돌',
    놹: '농',
    놺: '농',
    놻: '농',
    놼: '농',
    놽: '농',
    놾: '농',
    놿: '농',
    뇀: '농',
    뇁: '농',
    뇂: '농',
    뇃: '록',
    뇄: '록',
    뇅: '농',
    뇆: '록',
    뇇: '록',
    뇈: '록',
    뇉: '록',
    뇊: '록',
    뇋: '뇌',
    뇌: '뇌',
    뇍: '록',
    뇎: '록',
    뇏: '록',
    뇐: '론',
    뇑: '론',
    뇒: '론',
    뇓: '록',
    뇔: '돌',
    뇕: '농',
    뇖: '농',
    뇗: '농',
    뇘: '농',
    뇙: '농',
    뇚: '농',
    뇛: '농',
    뇜: '농',
    뇝: '농',
    뇞: '농',
    뇟: '록',
    뇠: '록',
    뇡: '농',
    뇢: '록',
    뇣: '록',
    뇤: '록',
    뇥: '록',
    뇦: '록',
    뇧: '뇌',
    뇨: '노',
    뇩: '록',
    뇪: '록',
    뇫: '록',
    뇬: '년',
    뇭: '년',
    뇮: '년',
    뇯: '록',
    뇰: '률',
    뇱: '년',
    뇲: '년',
    뇳: '년',
    뇴: '년',
    뇵: '년',
    뇶: '년',
    뇷: '년',
    뇸: '념',
    뇹: '념',
    뇺: '념',
    뇻: '록',
    뇼: '록',
    뇽: '농',
    뇾: '록',
    뇿: '록',
    눀: '록',
    눁: '록',
    눂: '념',
    눃: '노',
    누: '루',
    눅: '륙',
    눆: '륙',
    눇: '륙',
    눈: '륜',
    눉: '륜',
    눊: '륜',
    눋: '륙',
    눌: '률',
    눍: '률',
    눎: '률',
    눏: '률',
    눐: '률',
    눑: '률',
    눒: '률',
    눓: '률',
    눔: '능',
    눕: '능',
    눖: '능',
    눗: '륙',
    눘: '륙',
    눙: '능',
    눚: '륙',
    눛: '륙',
    눜: '륙',
    눝: '륙',
    눞: '륙',
    눟: '루',
    눠: '뇌',
    눡: '덕',
    눢: '덕',
    눣: '덕',
    눤: '둔',
    눥: '둔',
    눦: '둔',
    눧: '덕',
    눨: '둔',
    눩: '둔',
    눪: '둔',
    눫: '둔',
    눬: '둔',
    눭: '둔',
    눮: '둔',
    눯: '둔',
    눰: '둔',
    눱: '덕',
    눲: '덕',
    눳: '덕',
    눴: '덕',
    눵: '뇌',
    눶: '덕',
    눷: '덕',
    눸: '덕',
    눹: '덕',
    눺: '덕',
    눻: '뇌',
    눼: '뇌',
    눽: '덕',
    눾: '덕',
    눿: '덕',
    뉀: '랭',
    뉁: '랭',
    뉂: '랭',
    뉃: '덕',
    뉄: '랭',
    뉅: '랭',
    뉆: '랭',
    뉇: '랭',
    뉈: '랭',
    뉉: '랭',
    뉊: '랭',
    뉋: '랭',
    뉌: '랭',
    뉍: '랭',
    뉎: '랭',
    뉏: '덕',
    뉐: '덕',
    뉑: '뇌',
    뉒: '덕',
    뉓: '덕',
    뉔: '덕',
    뉕: '덕',
    뉖: '덕',
    뉗: '뇌',
    뉘: '니',
    뉙: '륙',
    뉚: '륙',
    뉛: '륙',
    뉜: '륜',
    뉝: '륜',
    뉞: '륜',
    뉟: '륙',
    뉠: '률',
    뉡: '률',
    뉢: '률',
    뉣: '률',
    뉤: '률',
    뉥: '률',
    뉦: '률',
    뉧: '률',
    뉨: '림',
    뉩: '립',
    뉪: '립',
    뉫: '륙',
    뉬: '륙',
    뉭: '륭',
    뉮: '륙',
    뉯: '륙',
    뉰: '륙',
    뉱: '륙',
    뉲: '립',
    뉳: '니',
    뉴: '류',
    뉵: '륙',
    뉶: '륙',
    뉷: '륙',
    뉸: '륜',
    뉹: '륜',
    뉺: '륜',
    뉻: '륙',
    뉼: '률',
    뉽: '률',
    뉾: '률',
    뉿: '률',
    늀: '률',
    늁: '률',
    늂: '률',
    늃: '률',
    늄: '륭',
    늅: '륭',
    늆: '륭',
    늇: '륙',
    늈: '륙',
    늉: '륭',
    늊: '륙',
    늋: '륙',
    늌: '륙',
    늍: '륙',
    늎: '륙',
    늏: '류',
    느: '능',
    늑: '득',
    늒: '득',
    늓: '득',
    는: '능',
    늕: '능',
    늖: '능',
    늗: '득',
    늘: '능',
    늙: '능',
    늚: '능',
    늛: '능',
    늜: '능',
    늝: '능',
    늞: '능',
    늟: '능',
    늠: '능',
    늡: '능',
    늢: '능',
    늣: '득',
    늤: '득',
    능: '능',
    늦: '득',
    늧: '득',
    늨: '득',
    늩: '득',
    늪: '득',
    늫: '능',
    늬: '니',
    늭: '륙',
    늮: '륙',
    늯: '륙',
    늰: '린',
    늱: '린',
    늲: '린',
    늳: '륙',
    늴: '률',
    늵: '률',
    늶: '률',
    늷: '률',
    늸: '률',
    늹: '률',
    늺: '률',
    늻: '률',
    늼: '림',
    늽: '립',
    늾: '립',
    늿: '립',
    닀: '륙',
    닁: '능',
    닂: '륙',
    닃: '륙',
    닄: '륙',
    닅: '륙',
    닆: '립',
    닇: '니',
    니: '니',
    닉: '립',
    닊: '택',
    닋: '택',
    닌: '린',
    닍: '린',
    닎: '린',
    닏: '택',
    닐: '린',
    닑: '린',
    닒: '린',
    닓: '린',
    닔: '린',
    닕: '린',
    닖: '린',
    닗: '린',
    님: '림',
    닙: '립',
    닚: '립',
    닛: '립',
    닜: '택',
    닝: '니',
    닞: '택',
    닟: '택',
    닠: '택',
    닡: '택',
    닢: '립',
    닣: '니',
    다: '다',
    닥: '탁',
    닦: '탁',
    닧: '탁',
    단: '단',
    닩: '단',
    닪: '단',
    닫: '탁',
    달: '달',
    닭: '달',
    닮: '달',
    닯: '달',
    닰: '달',
    닱: '달',
    닲: '달',
    닳: '달',
    담: '담',
    답: '답',
    닶: '답',
    닷: '답',
    닸: '탁',
    당: '당',
    닺: '탁',
    닻: '탁',
    닼: '탁',
    닽: '탁',
    닾: '답',
    닿: '다',
    대: '대',
    댁: '택',
    댂: '택',
    댃: '택',
    댄: '단',
    댅: '단',
    댆: '단',
    댇: '택',
    댈: '달',
    댉: '달',
    댊: '달',
    댋: '달',
    댌: '달',
    댍: '달',
    댎: '달',
    댏: '달',
    댐: '담',
    댑: '답',
    댒: '답',
    댓: '택',
    댔: '택',
    댕: '당',
    댖: '택',
    댗: '택',
    댘: '택',
    댙: '택',
    댚: '답',
    댛: '대',
    댜: '다',
    댝: '탁',
    댞: '탁',
    댟: '탁',
    댠: '단',
    댡: '단',
    댢: '단',
    댣: '탁',
    댤: '달',
    댥: '달',
    댦: '달',
    댧: '달',
    댨: '달',
    댩: '달',
    댪: '달',
    댫: '달',
    댬: '담',
    댭: '답',
    댮: '답',
    댯: '답',
    댰: '탁',
    댱: '당',
    댲: '탁',
    댳: '탁',
    댴: '탁',
    댵: '탁',
    댶: '답',
    댷: '다',
    댸: '대',
    댹: '택',
    댺: '택',
    댻: '택',
    댼: '쟁',
    댽: '쟁',
    댾: '쟁',
    댿: '택',
    덀: '쟁',
    덁: '쟁',
    덂: '쟁',
    덃: '쟁',
    덄: '쟁',
    덅: '쟁',
    덆: '쟁',
    덇: '쟁',
    덈: '쟁',
    덉: '택',
    덊: '택',
    덋: '택',
    덌: '택',
    덍: '쟁',
    덎: '택',
    덏: '택',
    덐: '택',
    덑: '택',
    덒: '택',
    덓: '대',
    더: '저',
    덕: '덕',
    덖: '덕',
    덗: '덕',
    던: '단',
    덙: '전',
    덚: '전',
    덛: '덕',
    덜: '달',
    덝: '달',
    덞: '달',
    덟: '달',
    덠: '달',
    덡: '달',
    덢: '달',
    덣: '달',
    덤: '점',
    덥: '답',
    덦: '답',
    덧: '덕',
    덨: '덕',
    덩: '정',
    덪: '덕',
    덫: '덕',
    덬: '덕',
    덭: '덕',
    덮: '답',
    덯: '다',
    데: '대',
    덱: '택',
    덲: '택',
    덳: '택',
    덴: '전',
    덵: '전',
    덶: '전',
    덷: '택',
    델: '절',
    덹: '절',
    덺: '절',
    덻: '절',
    덼: '절',
    덽: '절',
    덾: '절',
    덿: '절',
    뎀: '점',
    뎁: '접',
    뎂: '접',
    뎃: '택',
    뎄: '택',
    뎅: '쟁',
    뎆: '택',
    뎇: '택',
    뎈: '택',
    뎉: '택',
    뎊: '접',
    뎋: '대',
    뎌: '제',
    뎍: '덕',
    뎎: '덕',
    뎏: '덕',
    뎐: '년',
    뎑: '년',
    뎒: '년',
    뎓: '덕',
    뎔: '렬',
    뎕: '렬',
    뎖: '렬',
    뎗: '렬',
    뎘: '렬',
    뎙: '렬',
    뎚: '렬',
    뎛: '렬',
    뎜: '렴',
    뎝: '렵',
    뎞: '렵',
    뎟: '덕',
    뎠: '덕',
    뎡: '녕',
    뎢: '덕',
    뎣: '덕',
    뎤: '덕',
    뎥: '덕',
    뎦: '렵',
    뎧: '제',
    뎨: '대',
    뎩: '택',
    뎪: '택',
    뎫: '택',
    뎬: '년',
    뎭: '년',
    뎮: '년',
    뎯: '택',
    뎰: '렬',
    뎱: '렬',
    뎲: '렬',
    뎳: '렬',
    뎴: '렬',
    뎵: '렬',
    뎶: '렬',
    뎷: '렬',
    뎸: '념',
    뎹: '렵',
    뎺: '렵',
    뎻: '택',
    뎼: '택',
    뎽: '쟁',
    뎾: '택',
    뎿: '택',
    돀: '택',
    돁: '택',
    돂: '렵',
    돃: '대',
    도: '도',
    독: '독',
    돆: '독',
    돇: '독',
    돈: '돈',
    돉: '돈',
    돊: '돈',
    돋: '독',
    돌: '돌',
    돍: '돌',
    돎: '돌',
    돏: '돌',
    돐: '돌',
    돑: '돌',
    돒: '돌',
    돓: '돌',
    돔: '담',
    돕: '답',
    돖: '답',
    돗: '독',
    돘: '독',
    동: '동',
    돚: '독',
    돛: '독',
    돜: '독',
    돝: '독',
    돞: '답',
    돟: '도',
    돠: '다',
    돡: '탁',
    돢: '탁',
    돣: '탁',
    돤: '단',
    돥: '단',
    돦: '단',
    돧: '탁',
    돨: '달',
    돩: '달',
    돪: '달',
    돫: '달',
    돬: '달',
    돭: '달',
    돮: '달',
    돯: '달',
    돰: '담',
    돱: '답',
    돲: '답',
    돳: '답',
    돴: '탁',
    돵: '당',
    돶: '탁',
    돷: '탁',
    돸: '탁',
    돹: '탁',
    돺: '답',
    돻: '다',
    돼: '퇴',
    돽: '독',
    돾: '독',
    돿: '독',
    됀: '돈',
    됁: '돈',
    됂: '돈',
    됃: '독',
    됄: '돌',
    됅: '돌',
    됆: '돌',
    됇: '돌',
    됈: '돌',
    됉: '돌',
    됊: '돌',
    됋: '돌',
    됌: '동',
    됍: '동',
    됎: '동',
    됏: '독',
    됐: '독',
    됑: '동',
    됒: '독',
    됓: '독',
    됔: '독',
    됕: '독',
    됖: '독',
    됗: '퇴',
    되: '퇴',
    됙: '독',
    됚: '독',
    됛: '독',
    된: '돈',
    됝: '돈',
    됞: '돈',
    됟: '독',
    될: '돌',
    됡: '돌',
    됢: '돌',
    됣: '돌',
    됤: '돌',
    됥: '돌',
    됦: '돌',
    됧: '돌',
    됨: '동',
    됩: '동',
    됪: '동',
    됫: '독',
    됬: '독',
    됭: '동',
    됮: '독',
    됯: '독',
    됰: '독',
    됱: '독',
    됲: '독',
    됳: '퇴',
    됴: '도',
    됵: '독',
    됶: '독',
    됷: '독',
    됸: '돈',
    됹: '돈',
    됺: '돈',
    됻: '독',
    됼: '돌',
    됽: '돌',
    됾: '돌',
    됿: '돌',
    둀: '돌',
    둁: '돌',
    둂: '돌',
    둃: '돌',
    둄: '동',
    둅: '동',
    둆: '동',
    둇: '독',
    둈: '독',
    둉: '동',
    둊: '독',
    둋: '독',
    둌: '독',
    둍: '독',
    둎: '독',
    둏: '도',
    두: '두',
    둑: '득',
    둒: '득',
    둓: '득',
    둔: '둔',
    둕: '둔',
    둖: '둔',
    둗: '득',
    둘: '둔',
    둙: '둔',
    둚: '둔',
    둛: '둔',
    둜: '둔',
    둝: '둔',
    둞: '둔',
    둟: '둔',
    둠: '둔',
    둡: '등',
    둢: '등',
    둣: '득',
    둤: '득',
    둥: '등',
    둦: '득',
    둧: '득',
    둨: '득',
    둩: '득',
    둪: '득',
    둫: '두',
    둬: '두',
    둭: '덕',
    둮: '덕',
    둯: '덕',
    둰: '둔',
    둱: '둔',
    둲: '둔',
    둳: '덕',
    둴: '절',
    둵: '절',
    둶: '절',
    둷: '절',
    둸: '절',
    둹: '절',
    둺: '절',
    둻: '절',
    둼: '점',
    둽: '접',
    둾: '접',
    둿: '덕',
    뒀: '덕',
    뒁: '정',
    뒂: '덕',
    뒃: '덕',
    뒄: '덕',
    뒅: '덕',
    뒆: '접',
    뒇: '두',
    뒈: '퇴',
    뒉: '택',
    뒊: '택',
    뒋: '택',
    뒌: '전',
    뒍: '전',
    뒎: '전',
    뒏: '택',
    뒐: '절',
    뒑: '절',
    뒒: '절',
    뒓: '절',
    뒔: '절',
    뒕: '절',
    뒖: '절',
    뒗: '절',
    뒘: '점',
    뒙: '접',
    뒚: '접',
    뒛: '택',
    뒜: '택',
    뒝: '퇴',
    뒞: '택',
    뒟: '택',
    뒠: '택',
    뒡: '택',
    뒢: '접',
    뒣: '퇴',
    뒤: '두',
    뒥: '죽',
    뒦: '죽',
    뒧: '죽',
    뒨: '둔',
    뒩: '둔',
    뒪: '둔',
    뒫: '죽',
    뒬: '둔',
    뒭: '둔',
    뒮: '둔',
    뒯: '둔',
    뒰: '둔',
    뒱: '둔',
    뒲: '둔',
    뒳: '둔',
    뒴: '둔',
    뒵: '집',
    뒶: '집',
    뒷: '죽',
    뒸: '죽',
    뒹: '중',
    뒺: '죽',
    뒻: '죽',
    뒼: '죽',
    뒽: '죽',
    뒾: '집',
    뒿: '두',
    듀: '두',
    듁: '죽',
    듂: '죽',
    듃: '죽',
    듄: '둔',
    듅: '둔',
    듆: '둔',
    듇: '죽',
    듈: '둔',
    듉: '둔',
    듊: '둔',
    듋: '둔',
    듌: '둔',
    듍: '둔',
    듎: '둔',
    듏: '둔',
    듐: '둔',
    듑: '중',
    듒: '중',
    듓: '죽',
    듔: '죽',
    듕: '중',
    듖: '죽',
    듗: '죽',
    듘: '죽',
    듙: '죽',
    듚: '죽',
    듛: '두',
    드: '두',
    득: '득',
    듞: '득',
    듟: '득',
    든: '둔',
    듡: '둔',
    듢: '둔',
    듣: '득',
    들: '둔',
    듥: '둔',
    듦: '둔',
    듧: '둔',
    듨: '둔',
    듩: '둔',
    듪: '둔',
    듫: '둔',
    듬: '등',
    듭: '등',
    듮: '등',
    듯: '득',
    듰: '득',
    등: '등',
    듲: '득',
    듳: '득',
    듴: '득',
    듵: '득',
    듶: '득',
    듷: '두',
    듸: '두',
    듹: '득',
    듺: '득',
    듻: '득',
    듼: '둔',
    듽: '둔',
    듾: '둔',
    듿: '득',
    딀: '질',
    딁: '질',
    딂: '질',
    딃: '질',
    딄: '질',
    딅: '질',
    딆: '질',
    딇: '질',
    딈: '등',
    딉: '집',
    딊: '집',
    딋: '득',
    딌: '득',
    딍: '등',
    딎: '득',
    딏: '득',
    딐: '득',
    딑: '득',
    딒: '집',
    딓: '두',
    디: '지',
    딕: '직',
    딖: '직',
    딗: '직',
    딘: '진',
    딙: '진',
    딚: '진',
    딛: '직',
    딜: '질',
    딝: '질',
    딞: '질',
    딟: '질',
    딠: '질',
    딡: '질',
    딢: '질',
    딣: '질',
    딤: '침',
    딥: '집',
    딦: '집',
    딧: '직',
    딨: '직',
    딩: '징',
    딪: '직',
    딫: '직',
    딬: '직',
    딭: '직',
    딮: '집',
    딯: '지',
    따: '타',
    딱: '탁',
    딲: '탁',
    딳: '탁',
    딴: '탄',
    딵: '탄',
    딶: '탄',
    딷: '탁',
    딸: '탈',
    딹: '탈',
    딺: '탈',
    딻: '탈',
    딼: '탈',
    딽: '탈',
    딾: '탈',
    딿: '탈',
    땀: '탐',
    땁: '탑',
    땂: '탑',
    땃: '탑',
    땄: '탁',
    땅: '탕',
    땆: '탁',
    땇: '탁',
    땈: '탁',
    땉: '탁',
    땊: '탑',
    땋: '타',
    때: '태',
    땍: '택',
    땎: '택',
    땏: '택',
    땐: '탄',
    땑: '탄',
    땒: '탄',
    땓: '택',
    땔: '탈',
    땕: '탈',
    땖: '탈',
    땗: '탈',
    땘: '탈',
    땙: '탈',
    땚: '탈',
    땛: '탈',
    땜: '탐',
    땝: '탑',
    땞: '탑',
    땟: '택',
    땠: '택',
    땡: '탕',
    땢: '택',
    땣: '택',
    땤: '택',
    땥: '택',
    땦: '탑',
    땧: '태',
    땨: '타',
    땩: '탁',
    땪: '탁',
    땫: '탁',
    땬: '탄',
    땭: '탄',
    땮: '탄',
    땯: '탁',
    땰: '탈',
    땱: '탈',
    땲: '탈',
    땳: '탈',
    땴: '탈',
    땵: '탈',
    땶: '탈',
    땷: '탈',
    땸: '탐',
    땹: '탑',
    땺: '탑',
    땻: '탁',
    땼: '탁',
    땽: '탕',
    땾: '탁',
    땿: '탁',
    떀: '탁',
    떁: '탁',
    떂: '탑',
    떃: '타',
    떄: '태',
    떅: '택',
    떆: '택',
    떇: '택',
    떈: '랭',
    떉: '랭',
    떊: '랭',
    떋: '택',
    떌: '랭',
    떍: '랭',
    떎: '랭',
    떏: '랭',
    떐: '랭',
    떑: '랭',
    떒: '랭',
    떓: '랭',
    떔: '택',
    떕: '택',
    떖: '택',
    떗: '택',
    떘: '택',
    떙: '태',
    떚: '택',
    떛: '택',
    떜: '택',
    떝: '택',
    떞: '택',
    떟: '태',
    떠: '토',
    떡: '덕',
    떢: '덕',
    떣: '덕',
    떤: '탄',
    떥: '탄',
    떦: '탄',
    떧: '덕',
    떨: '탈',
    떩: '탈',
    떪: '탈',
    떫: '탈',
    떬: '탈',
    떭: '탈',
    떮: '탈',
    떯: '탈',
    떰: '탐',
    떱: '탑',
    떲: '탑',
    떳: '덕',
    떴: '덕',
    떵: '탕',
    떶: '덕',
    떷: '덕',
    떸: '덕',
    떹: '덕',
    떺: '탑',
    떻: '토',
    떼: '태',
    떽: '택',
    떾: '택',
    떿: '택',
    뗀: '련',
    뗁: '련',
    뗂: '련',
    뗃: '택',
    뗄: '렬',
    뗅: '렬',
    뗆: '렬',
    뗇: '렬',
    뗈: '렬',
    뗉: '렬',
    뗊: '렬',
    뗋: '렬',
    뗌: '렴',
    뗍: '렵',
    뗎: '렵',
    뗏: '택',
    뗐: '택',
    뗑: '태',
    뗒: '택',
    뗓: '택',
    뗔: '택',
    뗕: '택',
    뗖: '렵',
    뗗: '태',
    뗘: '려',
    뗙: '덕',
    뗚: '덕',
    뗛: '덕',
    뗜: '련',
    뗝: '련',
    뗞: '련',
    뗟: '덕',
    뗠: '렬',
    뗡: '렬',
    뗢: '렬',
    뗣: '렬',
    뗤: '렬',
    뗥: '렬',
    뗦: '렬',
    뗧: '렬',
    뗨: '렴',
    뗩: '렵',
    뗪: '렵',
    뗫: '덕',
    뗬: '덕',
    뗭: '령',
    뗮: '덕',
    뗯: '덕',
    뗰: '덕',
    뗱: '덕',
    뗲: '렵',
    뗳: '려',
    뗴: '태',
    뗵: '택',
    뗶: '택',
    뗷: '택',
    뗸: '련',
    뗹: '련',
    뗺: '련',
    뗻: '택',
    뗼: '렬',
    뗽: '렬',
    뗾: '렬',
    뗿: '렬',
    똀: '렬',
    똁: '렬',
    똂: '렬',
    똃: '렬',
    똄: '렴',
    똅: '렵',
    똆: '렵',
    똇: '택',
    똈: '택',
    똉: '령',
    똊: '택',
    똋: '택',
    똌: '택',
    똍: '택',
    똎: '렵',
    똏: '태',
    또: '토',
    똑: '독',
    똒: '독',
    똓: '독',
    똔: '돈',
    똕: '돈',
    똖: '돈',
    똗: '독',
    똘: '돌',
    똙: '돌',
    똚: '돌',
    똛: '돌',
    똜: '돌',
    똝: '돌',
    똞: '돌',
    똟: '돌',
    똠: '탐',
    똡: '탑',
    똢: '탑',
    똣: '독',
    똤: '독',
    똥: '통',
    똦: '독',
    똧: '독',
    똨: '독',
    똩: '독',
    똪: '탑',
    똫: '토',
    똬: '타',
    똭: '탁',
    똮: '탁',
    똯: '탁',
    똰: '탄',
    똱: '탄',
    똲: '탄',
    똳: '탁',
    똴: '탈',
    똵: '탈',
    똶: '탈',
    똷: '탈',
    똸: '탈',
    똹: '탈',
    똺: '탈',
    똻: '탈',
    똼: '탐',
    똽: '탑',
    똾: '탑',
    똿: '탁',
    뙀: '탁',
    뙁: '탕',
    뙂: '탁',
    뙃: '탁',
    뙄: '탁',
    뙅: '탁',
    뙆: '탑',
    뙇: '타',
    뙈: '퇴',
    뙉: '택',
    뙊: '택',
    뙋: '택',
    뙌: '돈',
    뙍: '돈',
    뙎: '돈',
    뙏: '택',
    뙐: '돌',
    뙑: '돌',
    뙒: '돌',
    뙓: '돌',
    뙔: '돌',
    뙕: '돌',
    뙖: '돌',
    뙗: '돌',
    뙘: '통',
    뙙: '통',
    뙚: '통',
    뙛: '택',
    뙜: '택',
    뙝: '통',
    뙞: '택',
    뙟: '택',
    뙠: '택',
    뙡: '택',
    뙢: '택',
    뙣: '퇴',
    뙤: '퇴',
    뙥: '독',
    뙦: '독',
    뙧: '독',
    뙨: '돈',
    뙩: '돈',
    뙪: '돈',
    뙫: '독',
    뙬: '돌',
    뙭: '돌',
    뙮: '돌',
    뙯: '돌',
    뙰: '돌',
    뙱: '돌',
    뙲: '돌',
    뙳: '돌',
    뙴: '통',
    뙵: '통',
    뙶: '통',
    뙷: '독',
    뙸: '독',
    뙹: '통',
    뙺: '독',
    뙻: '독',
    뙼: '독',
    뙽: '독',
    뙾: '독',
    뙿: '퇴',
    뚀: '토',
    뚁: '독',
    뚂: '독',
    뚃: '독',
    뚄: '돈',
    뚅: '돈',
    뚆: '돈',
    뚇: '독',
    뚈: '돌',
    뚉: '돌',
    뚊: '돌',
    뚋: '돌',
    뚌: '돌',
    뚍: '돌',
    뚎: '돌',
    뚏: '돌',
    뚐: '통',
    뚑: '통',
    뚒: '통',
    뚓: '독',
    뚔: '독',
    뚕: '통',
    뚖: '독',
    뚗: '독',
    뚘: '독',
    뚙: '독',
    뚚: '독',
    뚛: '토',
    뚜: '투',
    뚝: '특',
    뚞: '특',
    뚟: '특',
    뚠: '둔',
    뚡: '둔',
    뚢: '둔',
    뚣: '특',
    뚤: '둔',
    뚥: '둔',
    뚦: '둔',
    뚧: '둔',
    뚨: '둔',
    뚩: '둔',
    뚪: '둔',
    뚫: '둔',
    뚬: '둔',
    뚭: '특',
    뚮: '특',
    뚯: '특',
    뚰: '특',
    뚱: '등',
    뚲: '특',
    뚳: '특',
    뚴: '특',
    뚵: '특',
    뚶: '특',
    뚷: '투',
    뚸: '퇴',
    뚹: '덕',
    뚺: '덕',
    뚻: '덕',
    뚼: '둔',
    뚽: '둔',
    뚾: '둔',
    뚿: '덕',
    뛀: '둔',
    뛁: '둔',
    뛂: '둔',
    뛃: '둔',
    뛄: '둔',
    뛅: '둔',
    뛆: '둔',
    뛇: '둔',
    뛈: '점',
    뛉: '접',
    뛊: '접',
    뛋: '덕',
    뛌: '덕',
    뛍: '투',
    뛎: '덕',
    뛏: '덕',
    뛐: '덕',
    뛑: '덕',
    뛒: '접',
    뛓: '투',
    뛔: '퇴',
    뛕: '택',
    뛖: '택',
    뛗: '택',
    뛘: '전',
    뛙: '랭',
    뛚: '랭',
    뛛: '택',
    뛜: '절',
    뛝: '절',
    뛞: '절',
    뛟: '절',
    뛠: '절',
    뛡: '절',
    뛢: '절',
    뛣: '절',
    뛤: '택',
    뛥: '택',
    뛦: '택',
    뛧: '택',
    뛨: '택',
    뛩: '퇴',
    뛪: '택',
    뛫: '택',
    뛬: '택',
    뛭: '택',
    뛮: '택',
    뛯: '퇴',
    뛰: '투',
    뛱: '륙',
    뛲: '륙',
    뛳: '륙',
    뛴: '둔',
    뛵: '둔',
    뛶: '둔',
    뛷: '륙',
    뛸: '둔',
    뛹: '둔',
    뛺: '둔',
    뛻: '둔',
    뛼: '둔',
    뛽: '둔',
    뛾: '둔',
    뛿: '둔',
    뜀: '림',
    뜁: '립',
    뜂: '립',
    뜃: '륙',
    뜄: '륙',
    뜅: '투',
    뜆: '륙',
    뜇: '륙',
    뜈: '륙',
    뜉: '륙',
    뜊: '립',
    뜋: '투',
    뜌: '투',
    뜍: '륙',
    뜎: '륙',
    뜏: '륙',
    뜐: '둔',
    뜑: '둔',
    뜒: '둔',
    뜓: '륙',
    뜔: '률',
    뜕: '둔',
    뜖: '둔',
    뜗: '둔',
    뜘: '둔',
    뜙: '둔',
    뜚: '둔',
    뜛: '둔',
    뜜: '둔',
    뜝: '륭',
    뜞: '륭',
    뜟: '륙',
    뜠: '륙',
    뜡: '륭',
    뜢: '륙',
    뜣: '륙',
    뜤: '륙',
    뜥: '륙',
    뜦: '륙',
    뜧: '투',
    뜨: '투',
    뜩: '특',
    뜪: '특',
    뜫: '특',
    뜬: '둔',
    뜭: '둔',
    뜮: '둔',
    뜯: '특',
    뜰: '둔',
    뜱: '둔',
    뜲: '둔',
    뜳: '둔',
    뜴: '둔',
    뜵: '둔',
    뜶: '둔',
    뜷: '둔',
    뜸: '등',
    뜹: '특',
    뜺: '특',
    뜻: '특',
    뜼: '특',
    뜽: '등',
    뜾: '특',
    뜿: '특',
    띀: '특',
    띁: '특',
    띂: '특',
    띃: '투',
    띄: '투',
    띅: '특',
    띆: '특',
    띇: '특',
    띈: '둔',
    띉: '둔',
    띊: '둔',
    띋: '특',
    띌: '둔',
    띍: '둔',
    띎: '둔',
    띏: '둔',
    띐: '둔',
    띑: '둔',
    띒: '둔',
    띓: '둔',
    띔: '림',
    띕: '립',
    띖: '립',
    띗: '특',
    띘: '특',
    띙: '등',
    띚: '특',
    띛: '특',
    띜: '특',
    띝: '특',
    띞: '립',
    띟: '투',
    띠: '태',
    띡: '택',
    띢: '택',
    띣: '택',
    띤: '린',
    띥: '린',
    띦: '린',
    띧: '택',
    띨: '질',
    띩: '질',
    띪: '질',
    띫: '질',
    띬: '질',
    띭: '질',
    띮: '질',
    띯: '질',
    띰: '림',
    띱: '립',
    띲: '립',
    띳: '택',
    띴: '택',
    띵: '징',
    띶: '택',
    띷: '택',
    띸: '택',
    띹: '택',
    띺: '립',
    띻: '태',
    라: '라',
    락: '락',
    띾: '락',
    띿: '락',
    란: '란',
    랁: '란',
    랂: '란',
    랃: '락',
    랄: '란',
    랅: '란',
    랆: '란',
    랇: '란',
    랈: '란',
    랉: '란',
    랊: '란',
    랋: '란',
    람: '람',
    랍: '람',
    랎: '람',
    랏: '락',
    랐: '락',
    랑: '랑',
    랒: '락',
    랓: '락',
    랔: '락',
    랕: '락',
    랖: '람',
    랗: '라',
    래: '래',
    랙: '락',
    랚: '락',
    랛: '락',
    랜: '란',
    랝: '란',
    랞: '란',
    랟: '락',
    랠: '란',
    랡: '란',
    랢: '란',
    랣: '란',
    랤: '란',
    랥: '란',
    랦: '란',
    랧: '란',
    램: '람',
    랩: '립',
    랪: '립',
    랫: '락',
    랬: '락',
    랭: '랭',
    랮: '락',
    랯: '락',
    랰: '락',
    랱: '락',
    랲: '립',
    랳: '래',
    랴: '라',
    략: '략',
    랶: '략',
    랷: '략',
    랸: '란',
    랹: '란',
    랺: '란',
    랻: '략',
    랼: '란',
    랽: '란',
    랾: '란',
    랿: '란',
    럀: '란',
    럁: '란',
    럂: '란',
    럃: '란',
    럄: '람',
    럅: '람',
    럆: '람',
    럇: '략',
    럈: '략',
    량: '량',
    럊: '략',
    럋: '략',
    럌: '략',
    럍: '략',
    럎: '람',
    럏: '라',
    럐: '래',
    럑: '략',
    럒: '략',
    럓: '략',
    럔: '랭',
    럕: '랭',
    럖: '랭',
    럗: '략',
    럘: '랭',
    럙: '랭',
    럚: '랭',
    럛: '랭',
    럜: '랭',
    럝: '랭',
    럞: '랭',
    럟: '랭',
    럠: '랭',
    럡: '랭',
    럢: '랭',
    럣: '략',
    럤: '략',
    럥: '랭',
    럦: '략',
    럧: '략',
    럨: '략',
    럩: '략',
    럪: '략',
    럫: '래',
    러: '려',
    럭: '력',
    럮: '력',
    럯: '력',
    런: '련',
    럱: '련',
    럲: '련',
    럳: '력',
    럴: '렬',
    럵: '렬',
    럶: '렬',
    럷: '렬',
    럸: '렬',
    럹: '렬',
    럺: '렬',
    럻: '렬',
    럼: '렴',
    럽: '렵',
    럾: '렵',
    럿: '렵',
    렀: '력',
    렁: '령',
    렂: '력',
    렃: '력',
    렄: '력',
    렅: '력',
    렆: '렵',
    렇: '려',
    레: '례',
    렉: '력',
    렊: '력',
    렋: '력',
    렌: '련',
    렍: '련',
    렎: '련',
    렏: '력',
    렐: '렬',
    렑: '렬',
    렒: '렬',
    렓: '렬',
    렔: '렬',
    렕: '렬',
    렖: '렬',
    렗: '렬',
    렘: '렴',
    렙: '렵',
    렚: '렵',
    렛: '력',
    렜: '력',
    렝: '랭',
    렞: '력',
    렟: '력',
    렠: '력',
    렡: '력',
    렢: '렵',
    렣: '래',
    려: '려',
    력: '력',
    렦: '력',
    렧: '력',
    련: '련',
    렩: '련',
    렪: '련',
    렫: '력',
    렬: '렬',
    렭: '렬',
    렮: '렬',
    렯: '렬',
    렰: '렬',
    렱: '렬',
    렲: '렬',
    렳: '렬',
    렴: '렴',
    렵: '렵',
    렶: '렵',
    렷: '력',
    렸: '력',
    령: '령',
    렺: '력',
    렻: '력',
    렼: '력',
    렽: '력',
    렾: '렵',
    렿: '려',
    례: '례',
    롁: '력',
    롂: '력',
    롃: '력',
    롄: '련',
    롅: '련',
    롆: '련',
    롇: '력',
    롈: '렬',
    롉: '렬',
    롊: '렬',
    롋: '렬',
    롌: '렬',
    롍: '렬',
    롎: '렬',
    롏: '렬',
    롐: '렴',
    롑: '렵',
    롒: '렵',
    롓: '력',
    롔: '력',
    롕: '령',
    롖: '력',
    롗: '력',
    롘: '력',
    롙: '력',
    롚: '렵',
    롛: '례',
    로: '로',
    록: '록',
    롞: '록',
    롟: '록',
    론: '론',
    롡: '론',
    롢: '론',
    롣: '록',
    롤: '론',
    롥: '론',
    롦: '론',
    롧: '론',
    롨: '론',
    롩: '론',
    롪: '론',
    롫: '론',
    롬: '람',
    롭: '람',
    롮: '람',
    롯: '록',
    롰: '록',
    롱: '롱',
    롲: '록',
    롳: '록',
    롴: '록',
    롵: '록',
    롶: '람',
    롷: '로',
    롸: '라',
    롹: '락',
    롺: '락',
    롻: '락',
    롼: '란',
    롽: '란',
    롾: '란',
    롿: '락',
    뢀: '란',
    뢁: '란',
    뢂: '란',
    뢃: '란',
    뢄: '란',
    뢅: '란',
    뢆: '란',
    뢇: '란',
    뢈: '람',
    뢉: '람',
    뢊: '람',
    뢋: '락',
    뢌: '락',
    뢍: '랑',
    뢎: '락',
    뢏: '락',
    뢐: '락',
    뢑: '락',
    뢒: '람',
    뢓: '라',
    뢔: '뢰',
    뢕: '록',
    뢖: '록',
    뢗: '록',
    뢘: '론',
    뢙: '론',
    뢚: '론',
    뢛: '록',
    뢜: '론',
    뢝: '론',
    뢞: '론',
    뢟: '론',
    뢠: '론',
    뢡: '론',
    뢢: '론',
    뢣: '론',
    뢤: '롱',
    뢥: '롱',
    뢦: '롱',
    뢧: '록',
    뢨: '록',
    뢩: '랭',
    뢪: '록',
    뢫: '록',
    뢬: '록',
    뢭: '록',
    뢮: '록',
    뢯: '뢰',
    뢰: '뢰',
    뢱: '록',
    뢲: '록',
    뢳: '록',
    뢴: '론',
    뢵: '론',
    뢶: '론',
    뢷: '록',
    뢸: '론',
    뢹: '론',
    뢺: '론',
    뢻: '론',
    뢼: '론',
    뢽: '론',
    뢾: '론',
    뢿: '론',
    룀: '롱',
    룁: '롱',
    룂: '롱',
    룃: '록',
    룄: '록',
    룅: '롱',
    룆: '록',
    룇: '록',
    룈: '록',
    룉: '록',
    룊: '록',
    룋: '뢰',
    료: '료',
    룍: '록',
    룎: '록',
    룏: '록',
    룐: '론',
    룑: '론',
    룒: '론',
    룓: '록',
    룔: '렬',
    룕: '렬',
    룖: '렬',
    룗: '렬',
    룘: '렬',
    룙: '렬',
    룚: '렬',
    룛: '렬',
    룜: '렴',
    룝: '렵',
    룞: '렵',
    룟: '록',
    룠: '록',
    룡: '룡',
    룢: '록',
    룣: '록',
    룤: '록',
    룥: '록',
    룦: '렵',
    룧: '료',
    루: '루',
    룩: '륙',
    룪: '륙',
    룫: '륙',
    룬: '륜',
    룭: '륜',
    룮: '륜',
    룯: '륙',
    룰: '률',
    룱: '률',
    룲: '률',
    룳: '률',
    룴: '률',
    룵: '률',
    룶: '률',
    룷: '률',
    룸: '륭',
    룹: '륭',
    룺: '륭',
    룻: '륙',
    룼: '륙',
    룽: '륭',
    룾: '륙',
    룿: '륙',
    뤀: '륙',
    뤁: '륙',
    뤂: '륙',
    뤃: '루',
    뤄: '뢰',
    뤅: '덕',
    뤆: '덕',
    뤇: '덕',
    뤈: '문',
    뤉: '문',
    뤊: '문',
    뤋: '덕',
    뤌: '물',
    뤍: '물',
    뤎: '물',
    뤏: '물',
    뤐: '물',
    뤑: '물',
    뤒: '물',
    뤓: '물',
    뤔: '문',
    뤕: '덕',
    뤖: '덕',
    뤗: '덕',
    뤘: '덕',
    뤙: '뢰',
    뤚: '덕',
    뤛: '덕',
    뤜: '덕',
    뤝: '덕',
    뤞: '덕',
    뤟: '뢰',
    뤠: '뢰',
    뤡: '맥',
    뤢: '맥',
    뤣: '맥',
    뤤: '랭',
    뤥: '랭',
    뤦: '랭',
    뤧: '맥',
    뤨: '랭',
    뤩: '랭',
    뤪: '랭',
    뤫: '랭',
    뤬: '랭',
    뤭: '랭',
    뤮: '랭',
    뤯: '랭',
    뤰: '랭',
    뤱: '랭',
    뤲: '랭',
    뤳: '맥',
    뤴: '맥',
    뤵: '랭',
    뤶: '맥',
    뤷: '맥',
    뤸: '맥',
    뤹: '맥',
    뤺: '맥',
    뤻: '뢰',
    뤼: '루',
    뤽: '륙',
    뤾: '륙',
    뤿: '륙',
    륀: '륜',
    륁: '륜',
    륂: '륜',
    륃: '륙',
    륄: '률',
    륅: '률',
    륆: '률',
    륇: '률',
    륈: '률',
    륉: '률',
    륊: '률',
    륋: '률',
    륌: '림',
    륍: '립',
    륎: '립',
    륏: '륙',
    륐: '륙',
    륑: '륭',
    륒: '륙',
    륓: '륙',
    륔: '륙',
    륕: '륙',
    륖: '립',
    륗: '루',
    류: '류',
    륙: '륙',
    륚: '륙',
    륛: '륙',
    륜: '륜',
    륝: '륜',
    륞: '륜',
    륟: '륙',
    률: '률',
    륡: '률',
    륢: '률',
    륣: '률',
    륤: '률',
    륥: '률',
    륦: '률',
    륧: '률',
    륨: '륭',
    륩: '륭',
    륪: '륭',
    륫: '륙',
    륬: '륙',
    륭: '륭',
    륮: '륙',
    륯: '륙',
    륰: '륙',
    륱: '륙',
    륲: '륙',
    륳: '류',
    르: '루',
    륵: '득',
    륶: '득',
    륷: '득',
    른: '릉',
    륹: '릉',
    륺: '릉',
    륻: '득',
    를: '릉',
    륽: '릉',
    륾: '릉',
    륿: '릉',
    릀: '릉',
    릁: '릉',
    릂: '릉',
    릃: '릉',
    름: '릉',
    릅: '릉',
    릆: '릉',
    릇: '득',
    릈: '득',
    릉: '릉',
    릊: '득',
    릋: '득',
    릌: '득',
    릍: '득',
    릎: '득',
    릏: '루',
    릐: '리',
    릑: '륙',
    릒: '륙',
    릓: '륙',
    릔: '린',
    릕: '린',
    릖: '린',
    릗: '륙',
    릘: '률',
    릙: '률',
    릚: '률',
    릛: '률',
    릜: '률',
    릝: '률',
    릞: '률',
    릟: '률',
    릠: '림',
    릡: '립',
    릢: '립',
    릣: '립',
    릤: '륙',
    릥: '릉',
    릦: '륙',
    릧: '륙',
    릨: '륙',
    릩: '륙',
    릪: '립',
    릫: '리',
    리: '리',
    릭: '립',
    릮: '맥',
    릯: '맥',
    린: '린',
    릱: '린',
    릲: '린',
    릳: '맥',
    릴: '린',
    릵: '린',
    릶: '린',
    릷: '린',
    릸: '린',
    릹: '린',
    릺: '린',
    릻: '린',
    림: '림',
    립: '립',
    릾: '립',
    릿: '립',
    맀: '맥',
    링: '랭',
    맂: '맥',
    맃: '맥',
    맄: '맥',
    맅: '맥',
    맆: '립',
    맇: '리',
    마: '마',
    막: '막',
    맊: '막',
    맋: '막',
    만: '만',
    맍: '만',
    많: '만',
    맏: '막',
    말: '말',
    맑: '말',
    맒: '말',
    맓: '말',
    맔: '말',
    맕: '말',
    맖: '말',
    맗: '말',
    맘: '망',
    맙: '압',
    맚: '압',
    맛: '막',
    맜: '막',
    망: '망',
    맞: '막',
    맟: '막',
    맠: '막',
    맡: '막',
    맢: '압',
    맣: '마',
    매: '매',
    맥: '맥',
    맦: '맥',
    맧: '맥',
    맨: '만',
    맩: '만',
    맪: '만',
    맫: '맥',
    맬: '말',
    맭: '말',
    맮: '말',
    맯: '말',
    맰: '말',
    맱: '말',
    맲: '말',
    맳: '말',
    맴: '맹',
    맵: '맹',
    맶: '맹',
    맷: '맥',
    맸: '맥',
    맹: '맹',
    맺: '맥',
    맻: '맥',
    맼: '맥',
    맽: '맥',
    맾: '맥',
    맿: '매',
    먀: '마',
    먁: '막',
    먂: '막',
    먃: '막',
    먄: '만',
    먅: '만',
    먆: '만',
    먇: '막',
    먈: '말',
    먉: '말',
    먊: '말',
    먋: '말',
    먌: '말',
    먍: '말',
    먎: '말',
    먏: '말',
    먐: '망',
    먑: '압',
    먒: '압',
    먓: '막',
    먔: '막',
    먕: '망',
    먖: '막',
    먗: '막',
    먘: '막',
    먙: '막',
    먚: '압',
    먛: '마',
    먜: '매',
    먝: '맥',
    먞: '맥',
    먟: '맥',
    먠: '맹',
    먡: '맹',
    먢: '맹',
    먣: '맥',
    먤: '맹',
    먥: '맹',
    먦: '맹',
    먧: '맹',
    먨: '맹',
    먩: '맹',
    먪: '맹',
    먫: '맹',
    먬: '맹',
    먭: '맹',
    먮: '맹',
    먯: '맥',
    먰: '맥',
    먱: '맹',
    먲: '맥',
    먳: '맥',
    먴: '맥',
    먵: '맥',
    먶: '맥',
    먷: '매',
    머: '마',
    먹: '막',
    먺: '막',
    먻: '막',
    먼: '면',
    먽: '면',
    먾: '면',
    먿: '막',
    멀: '멸',
    멁: '멸',
    멂: '멸',
    멃: '멸',
    멄: '멸',
    멅: '멸',
    멆: '멸',
    멇: '멸',
    멈: '범',
    멉: '법',
    멊: '법',
    멋: '막',
    멌: '막',
    멍: '명',
    멎: '막',
    멏: '막',
    멐: '막',
    멑: '막',
    멒: '업',
    멓: '마',
    메: '매',
    멕: '맥',
    멖: '맥',
    멗: '맥',
    멘: '면',
    멙: '면',
    멚: '면',
    멛: '맥',
    멜: '멸',
    멝: '멸',
    멞: '멸',
    멟: '멸',
    멠: '멸',
    멡: '멸',
    멢: '멸',
    멣: '멸',
    멤: '맹',
    멥: '엽',
    멦: '맹',
    멧: '맥',
    멨: '맥',
    멩: '맹',
    멪: '맥',
    멫: '맥',
    멬: '맥',
    멭: '맥',
    멮: '엽',
    멯: '매',
    며: '묘',
    멱: '력',
    멲: '력',
    멳: '력',
    면: '면',
    멵: '면',
    멶: '면',
    멷: '력',
    멸: '멸',
    멹: '멸',
    멺: '멸',
    멻: '멸',
    멼: '멸',
    멽: '멸',
    멾: '멸',
    멿: '멸',
    몀: '명',
    몁: '렵',
    몂: '렵',
    몃: '력',
    몄: '력',
    명: '명',
    몆: '력',
    몇: '력',
    몈: '력',
    몉: '력',
    몊: '엽',
    몋: '명',
    몌: '매',
    몍: '맥',
    몎: '맥',
    몏: '맥',
    몐: '면',
    몑: '면',
    몒: '면',
    몓: '맥',
    몔: '멸',
    몕: '멸',
    몖: '멸',
    몗: '멸',
    몘: '멸',
    몙: '멸',
    몚: '멸',
    몛: '멸',
    몜: '명',
    몝: '렵',
    몞: '렵',
    몟: '맥',
    몠: '맥',
    몡: '명',
    몢: '맥',
    몣: '맥',
    몤: '맥',
    몥: '맥',
    몦: '엽',
    몧: '매',
    모: '모',
    목: '목',
    몪: '목',
    몫: '목',
    몬: '만',
    몭: '몰',
    몮: '몰',
    몯: '목',
    몰: '몰',
    몱: '몰',
    몲: '몰',
    몳: '몰',
    몴: '몰',
    몵: '몰',
    몶: '몰',
    몷: '몰',
    몸: '몽',
    몹: '몽',
    몺: '몽',
    못: '목',
    몼: '목',
    몽: '몽',
    몾: '목',
    몿: '목',
    뫀: '목',
    뫁: '목',
    뫂: '목',
    뫃: '모',
    뫄: '마',
    뫅: '막',
    뫆: '막',
    뫇: '막',
    뫈: '만',
    뫉: '만',
    뫊: '만',
    뫋: '막',
    뫌: '말',
    뫍: '말',
    뫎: '말',
    뫏: '말',
    뫐: '말',
    뫑: '말',
    뫒: '말',
    뫓: '말',
    뫔: '망',
    뫕: '압',
    뫖: '압',
    뫗: '막',
    뫘: '막',
    뫙: '망',
    뫚: '막',
    뫛: '막',
    뫜: '막',
    뫝: '막',
    뫞: '압',
    뫟: '마',
    뫠: '매',
    뫡: '목',
    뫢: '맥',
    뫣: '맥',
    뫤: '몰',
    뫥: '몰',
    뫦: '몰',
    뫧: '맥',
    뫨: '몰',
    뫩: '몰',
    뫪: '몰',
    뫫: '몰',
    뫬: '몰',
    뫭: '몰',
    뫮: '몰',
    뫯: '몰',
    뫰: '맹',
    뫱: '맹',
    뫲: '맹',
    뫳: '목',
    뫴: '맥',
    뫵: '맹',
    뫶: '맥',
    뫷: '맥',
    뫸: '맥',
    뫹: '맥',
    뫺: '목',
    뫻: '매',
    뫼: '모',
    뫽: '목',
    뫾: '목',
    뫿: '목',
    묀: '몰',
    묁: '몰',
    묂: '몰',
    묃: '목',
    묄: '몰',
    묅: '몰',
    묆: '몰',
    묇: '몰',
    묈: '몰',
    묉: '몰',
    묊: '몰',
    묋: '몰',
    묌: '몽',
    묍: '몽',
    묎: '몽',
    묏: '목',
    묐: '목',
    묑: '몽',
    묒: '목',
    묓: '목',
    묔: '목',
    묕: '목',
    묖: '목',
    묗: '모',
    묘: '묘',
    묙: '목',
    묚: '목',
    묛: '목',
    묜: '면',
    묝: '면',
    묞: '면',
    묟: '목',
    묠: '몰',
    묡: '몰',
    묢: '몰',
    묣: '몰',
    묤: '몰',
    묥: '몰',
    묦: '몰',
    묧: '몰',
    묨: '몽',
    묩: '몽',
    묪: '몽',
    묫: '목',
    묬: '목',
    묭: '몽',
    묮: '목',
    묯: '목',
    묰: '목',
    묱: '목',
    묲: '목',
    묳: '묘',
    무: '무',
    묵: '묵',
    묶: '묵',
    묷: '묵',
    문: '문',
    묹: '문',
    묺: '문',
    묻: '묵',
    물: '물',
    묽: '물',
    묾: '물',
    묿: '물',
    뭀: '물',
    뭁: '물',
    뭂: '물',
    뭃: '물',
    뭄: '문',
    뭅: '읍',
    뭆: '묵',
    뭇: '묵',
    뭈: '묵',
    뭉: '무',
    뭊: '묵',
    뭋: '묵',
    뭌: '묵',
    뭍: '묵',
    뭎: '묵',
    뭏: '무',
    뭐: '무',
    뭑: '묵',
    뭒: '묵',
    뭓: '묵',
    뭔: '문',
    뭕: '문',
    뭖: '문',
    뭗: '묵',
    뭘: '물',
    뭙: '물',
    뭚: '물',
    뭛: '물',
    뭜: '물',
    뭝: '물',
    뭞: '물',
    뭟: '물',
    뭠: '범',
    뭡: '법',
    뭢: '법',
    뭣: '묵',
    뭤: '묵',
    뭥: '무',
    뭦: '묵',
    뭧: '묵',
    뭨: '묵',
    뭩: '묵',
    뭪: '법',
    뭫: '무',
    뭬: '매',
    뭭: '맥',
    뭮: '맥',
    뭯: '맥',
    뭰: '원',
    뭱: '원',
    뭲: '원',
    뭳: '맥',
    뭴: '월',
    뭵: '월',
    뭶: '월',
    뭷: '월',
    뭸: '월',
    뭹: '월',
    뭺: '월',
    뭻: '월',
    뭼: '맹',
    뭽: '맹',
    뭾: '맹',
    뭿: '맥',
    뮀: '맥',
    뮁: '맹',
    뮂: '맥',
    뮃: '맥',
    뮄: '맥',
    뮅: '맥',
    뮆: '맥',
    뮇: '매',
    뮈: '무',
    뮉: '묵',
    뮊: '묵',
    뮋: '묵',
    뮌: '문',
    뮍: '문',
    뮎: '문',
    뮏: '묵',
    뮐: '물',
    뮑: '물',
    뮒: '물',
    뮓: '물',
    뮔: '물',
    뮕: '물',
    뮖: '물',
    뮗: '물',
    뮘: '문',
    뮙: '립',
    뮚: '립',
    뮛: '묵',
    뮜: '묵',
    뮝: '문',
    뮞: '묵',
    뮟: '묵',
    뮠: '묵',
    뮡: '묵',
    뮢: '립',
    뮣: '무',
    뮤: '무',
    뮥: '묵',
    뮦: '묵',
    뮧: '묵',
    뮨: '문',
    뮩: '문',
    뮪: '문',
    뮫: '묵',
    뮬: '물',
    뮭: '물',
    뮮: '물',
    뮯: '물',
    뮰: '물',
    뮱: '물',
    뮲: '물',
    뮳: '물',
    뮴: '문',
    뮵: '묵',
    뮶: '묵',
    뮷: '묵',
    뮸: '묵',
    뮹: '륭',
    뮺: '묵',
    뮻: '묵',
    뮼: '묵',
    뮽: '묵',
    뮾: '묵',
    뮿: '무',
    므: '무',
    믁: '묵',
    믂: '묵',
    믃: '묵',
    믄: '문',
    믅: '문',
    믆: '문',
    믇: '묵',
    믈: '물',
    믉: '물',
    믊: '물',
    믋: '물',
    믌: '물',
    믍: '물',
    믎: '물',
    믏: '물',
    믐: '음',
    믑: '읍',
    믒: '읍',
    믓: '묵',
    믔: '묵',
    믕: '응',
    믖: '묵',
    믗: '묵',
    믘: '묵',
    믙: '묵',
    믚: '읍',
    믛: '무',
    믜: '미',
    믝: '묵',
    믞: '묵',
    믟: '묵',
    믠: '민',
    믡: '민',
    믢: '민',
    믣: '묵',
    믤: '밀',
    믥: '밀',
    믦: '밀',
    믧: '밀',
    믨: '밀',
    믩: '밀',
    믪: '밀',
    믫: '밀',
    믬: '임',
    믭: '입',
    믮: '입',
    믯: '묵',
    믰: '묵',
    믱: '미',
    믲: '묵',
    믳: '묵',
    믴: '묵',
    믵: '묵',
    믶: '립',
    믷: '미',
    미: '미',
    믹: '맥',
    믺: '맥',
    믻: '맥',
    민: '민',
    믽: '민',
    믾: '민',
    믿: '맥',
    밀: '밀',
    밁: '밀',
    밂: '밀',
    밃: '밀',
    밄: '밀',
    밅: '밀',
    밆: '밀',
    밇: '밀',
    밈: '임',
    밉: '입',
    밊: '입',
    밋: '맥',
    밌: '맥',
    밍: '맹',
    밎: '맥',
    및: '맥',
    밐: '맥',
    밑: '맥',
    밒: '립',
    밓: '미',
    바: '파',
    박: '박',
    밖: '박',
    밗: '박',
    반: '반',
    밙: '반',
    밚: '반',
    받: '박',
    발: '발',
    밝: '발',
    밞: '발',
    밟: '발',
    밠: '발',
    밡: '발',
    밢: '발',
    밣: '발',
    밤: '범',
    밥: '법',
    밦: '법',
    밧: '박',
    밨: '박',
    방: '방',
    밪: '박',
    밫: '박',
    밬: '박',
    밭: '박',
    밮: '법',
    밯: '파',
    배: '배',
    백: '백',
    밲: '백',
    밳: '백',
    밴: '반',
    밵: '반',
    밶: '반',
    밷: '백',
    밸: '발',
    밹: '발',
    밺: '발',
    밻: '발',
    밼: '발',
    밽: '발',
    밾: '발',
    밿: '발',
    뱀: '방',
    뱁: '백',
    뱂: '백',
    뱃: '백',
    뱄: '백',
    뱅: '방',
    뱆: '백',
    뱇: '백',
    뱈: '백',
    뱉: '백',
    뱊: '백',
    뱋: '배',
    뱌: '파',
    뱍: '박',
    뱎: '박',
    뱏: '박',
    뱐: '반',
    뱑: '반',
    뱒: '반',
    뱓: '박',
    뱔: '발',
    뱕: '발',
    뱖: '발',
    뱗: '발',
    뱘: '발',
    뱙: '발',
    뱚: '발',
    뱛: '발',
    뱜: '방',
    뱝: '방',
    뱞: '방',
    뱟: '박',
    뱠: '박',
    뱡: '방',
    뱢: '박',
    뱣: '박',
    뱤: '박',
    뱥: '박',
    뱦: '박',
    뱧: '파',
    뱨: '배',
    뱩: '백',
    뱪: '백',
    뱫: '백',
    뱬: '맹',
    뱭: '맹',
    뱮: '맹',
    뱯: '백',
    뱰: '맹',
    뱱: '맹',
    뱲: '맹',
    뱳: '맹',
    뱴: '맹',
    뱵: '맹',
    뱶: '맹',
    뱷: '맹',
    뱸: '백',
    뱹: '백',
    뱺: '백',
    뱻: '백',
    뱼: '백',
    뱽: '배',
    뱾: '백',
    뱿: '백',
    벀: '백',
    벁: '백',
    벂: '백',
    벃: '배',
    버: '보',
    벅: '벽',
    벆: '벽',
    벇: '벽',
    번: '번',
    벉: '번',
    벊: '번',
    벋: '벽',
    벌: '벌',
    벍: '벌',
    벎: '벌',
    벏: '벌',
    벐: '벌',
    벑: '벌',
    벒: '벌',
    벓: '벌',
    범: '범',
    법: '법',
    벖: '법',
    벗: '법',
    벘: '벽',
    벙: '병',
    벚: '벽',
    벛: '벽',
    벜: '벽',
    벝: '벽',
    벞: '법',
    벟: '보',
    베: '배',
    벡: '백',
    벢: '백',
    벣: '백',
    벤: '변',
    벥: '변',
    벦: '변',
    벧: '백',
    벨: '별',
    벩: '별',
    벪: '별',
    벫: '별',
    벬: '별',
    벭: '별',
    벮: '별',
    벯: '별',
    벰: '범',
    벱: '법',
    벲: '법',
    벳: '백',
    벴: '백',
    벵: '병',
    벶: '백',
    벷: '백',
    벸: '백',
    벹: '백',
    벺: '법',
    벻: '배',
    벼: '폐',
    벽: '벽',
    벾: '벽',
    벿: '벽',
    변: '변',
    볁: '변',
    볂: '변',
    볃: '벽',
    별: '별',
    볅: '별',
    볆: '별',
    볇: '별',
    볈: '별',
    볉: '별',
    볊: '별',
    볋: '별',
    볌: '범',
    볍: '법',
    볎: '법',
    볏: '벽',
    볐: '벽',
    병: '병',
    볒: '벽',
    볓: '벽',
    볔: '벽',
    볕: '벽',
    볖: '법',
    볗: '병',
    볘: '폐',
    볙: '벽',
    볚: '벽',
    볛: '벽',
    볜: '변',
    볝: '변',
    볞: '변',
    볟: '벽',
    볠: '별',
    볡: '별',
    볢: '별',
    볣: '별',
    볤: '별',
    볥: '별',
    볦: '별',
    볧: '별',
    볨: '병',
    볩: '병',
    볪: '병',
    볫: '벽',
    볬: '벽',
    볭: '병',
    볮: '벽',
    볯: '벽',
    볰: '벽',
    볱: '벽',
    볲: '벽',
    볳: '폐',
    보: '보',
    복: '복',
    볶: '복',
    볷: '복',
    본: '본',
    볹: '본',
    볺: '본',
    볻: '복',
    볼: '발',
    볽: '본',
    볾: '본',
    볿: '본',
    봀: '본',
    봁: '본',
    봂: '본',
    봃: '본',
    봄: '범',
    봅: '법',
    봆: '법',
    봇: '복',
    봈: '복',
    봉: '봉',
    봊: '복',
    봋: '복',
    봌: '복',
    봍: '복',
    봎: '법',
    봏: '보',
    봐: '파',
    봑: '박',
    봒: '박',
    봓: '박',
    봔: '반',
    봕: '반',
    봖: '반',
    봗: '박',
    봘: '발',
    봙: '발',
    봚: '발',
    봛: '발',
    봜: '발',
    봝: '발',
    봞: '발',
    봟: '발',
    봠: '방',
    봡: '방',
    봢: '방',
    봣: '박',
    봤: '박',
    봥: '방',
    봦: '박',
    봧: '박',
    봨: '박',
    봩: '박',
    봪: '박',
    봫: '파',
    봬: '배',
    봭: '백',
    봮: '백',
    봯: '백',
    봰: '본',
    봱: '본',
    봲: '본',
    봳: '백',
    봴: '벌',
    봵: '벌',
    봶: '벌',
    봷: '벌',
    봸: '벌',
    봹: '벌',
    봺: '벌',
    봻: '벌',
    봼: '범',
    봽: '법',
    봾: '법',
    봿: '백',
    뵀: '백',
    뵁: '봉',
    뵂: '백',
    뵃: '백',
    뵄: '백',
    뵅: '백',
    뵆: '법',
    뵇: '배',
    뵈: '보',
    뵉: '복',
    뵊: '복',
    뵋: '복',
    뵌: '본',
    뵍: '본',
    뵎: '본',
    뵏: '복',
    뵐: '본',
    뵑: '본',
    뵒: '본',
    뵓: '본',
    뵔: '본',
    뵕: '본',
    뵖: '본',
    뵗: '본',
    뵘: '범',
    뵙: '법',
    뵚: '법',
    뵛: '복',
    뵜: '복',
    뵝: '봉',
    뵞: '복',
    뵟: '복',
    뵠: '복',
    뵡: '복',
    뵢: '법',
    뵣: '보',
    뵤: '보',
    뵥: '복',
    뵦: '복',
    뵧: '복',
    뵨: '본',
    뵩: '본',
    뵪: '본',
    뵫: '복',
    뵬: '별',
    뵭: '별',
    뵮: '별',
    뵯: '별',
    뵰: '별',
    뵱: '별',
    뵲: '별',
    뵳: '별',
    뵴: '봉',
    뵵: '봉',
    뵶: '봉',
    뵷: '복',
    뵸: '복',
    뵹: '봉',
    뵺: '복',
    뵻: '복',
    뵼: '복',
    뵽: '복',
    뵾: '복',
    뵿: '보',
    부: '부',
    북: '북',
    붂: '북',
    붃: '북',
    분: '분',
    붅: '분',
    붆: '분',
    붇: '북',
    불: '불',
    붉: '불',
    붊: '불',
    붋: '불',
    붌: '불',
    붍: '불',
    붎: '불',
    붏: '불',
    붐: '품',
    붑: '품',
    붒: '품',
    붓: '북',
    붔: '북',
    붕: '붕',
    붖: '북',
    붗: '북',
    붘: '북',
    붙: '북',
    붚: '품',
    붛: '부',
    붜: '부',
    붝: '북',
    붞: '북',
    붟: '북',
    붠: '번',
    붡: '번',
    붢: '번',
    붣: '북',
    붤: '벌',
    붥: '벌',
    붦: '벌',
    붧: '벌',
    붨: '벌',
    붩: '벌',
    붪: '벌',
    붫: '벌',
    붬: '범',
    붭: '법',
    붮: '법',
    붯: '법',
    붰: '북',
    붱: '붕',
    붲: '북',
    붳: '북',
    붴: '북',
    붵: '북',
    붶: '법',
    붷: '부',
    붸: '배',
    붹: '백',
    붺: '백',
    붻: '백',
    붼: '번',
    붽: '번',
    붾: '번',
    붿: '백',
    뷀: '벌',
    뷁: '벌',
    뷂: '벌',
    뷃: '벌',
    뷄: '벌',
    뷅: '벌',
    뷆: '벌',
    뷇: '벌',
    뷈: '범',
    뷉: '법',
    뷊: '법',
    뷋: '백',
    뷌: '백',
    뷍: '배',
    뷎: '백',
    뷏: '백',
    뷐: '백',
    뷑: '백',
    뷒: '법',
    뷓: '배',
    뷔: '부',
    뷕: '북',
    뷖: '북',
    뷗: '북',
    뷘: '분',
    뷙: '분',
    뷚: '분',
    뷛: '북',
    뷜: '불',
    뷝: '불',
    뷞: '불',
    뷟: '불',
    뷠: '불',
    뷡: '불',
    뷢: '불',
    뷣: '불',
    뷤: '품',
    뷥: '품',
    뷦: '품',
    뷧: '북',
    뷨: '북',
    뷩: '붕',
    뷪: '북',
    뷫: '북',
    뷬: '북',
    뷭: '북',
    뷮: '품',
    뷯: '부',
    뷰: '부',
    뷱: '북',
    뷲: '북',
    뷳: '북',
    뷴: '분',
    뷵: '분',
    뷶: '분',
    뷷: '북',
    뷸: '불',
    뷹: '불',
    뷺: '불',
    뷻: '불',
    뷼: '불',
    뷽: '불',
    뷾: '불',
    뷿: '불',
    븀: '품',
    븁: '품',
    븂: '품',
    븃: '북',
    븄: '북',
    븅: '붕',
    븆: '북',
    븇: '북',
    븈: '북',
    븉: '북',
    븊: '품',
    븋: '부',
    브: '부',
    븍: '북',
    븎: '북',
    븏: '북',
    븐: '분',
    븑: '분',
    븒: '분',
    븓: '북',
    블: '불',
    븕: '불',
    븖: '불',
    븗: '불',
    븘: '불',
    븙: '불',
    븚: '불',
    븛: '불',
    븜: '품',
    븝: '품',
    븞: '품',
    븟: '북',
    븠: '북',
    븡: '붕',
    븢: '북',
    븣: '북',
    븤: '북',
    븥: '북',
    븦: '품',
    븧: '부',
    븨: '비',
    븩: '북',
    븪: '북',
    븫: '북',
    븬: '빈',
    븭: '빈',
    븮: '빈',
    븯: '북',
    븰: '불',
    븱: '불',
    븲: '불',
    븳: '불',
    븴: '불',
    븵: '불',
    븶: '불',
    븷: '불',
    븸: '품',
    븹: '빙',
    븺: '빙',
    븻: '북',
    븼: '북',
    븽: '빙',
    븾: '북',
    븿: '북',
    빀: '북',
    빁: '북',
    빂: '품',
    빃: '비',
    비: '비',
    빅: '백',
    빆: '백',
    빇: '백',
    빈: '빈',
    빉: '빈',
    빊: '빈',
    빋: '백',
    빌: '필',
    빍: '필',
    빎: '필',
    빏: '필',
    빐: '필',
    빑: '필',
    빒: '필',
    빓: '필',
    빔: '빙',
    빕: '빙',
    빖: '빙',
    빗: '백',
    빘: '백',
    빙: '빙',
    빚: '백',
    빛: '백',
    빜: '백',
    빝: '백',
    빞: '백',
    빟: '비',
    빠: '파',
    빡: '박',
    빢: '박',
    빣: '박',
    빤: '판',
    빥: '판',
    빦: '판',
    빧: '박',
    빨: '팔',
    빩: '팔',
    빪: '팔',
    빫: '팔',
    빬: '팔',
    빭: '팔',
    빮: '팔',
    빯: '팔',
    빰: '범',
    빱: '법',
    빲: '법',
    빳: '박',
    빴: '박',
    빵: '방',
    빶: '박',
    빷: '박',
    빸: '박',
    빹: '박',
    빺: '법',
    빻: '파',
    빼: '패',
    빽: '백',
    빾: '백',
    빿: '백',
    뺀: '판',
    뺁: '판',
    뺂: '판',
    뺃: '백',
    뺄: '팔',
    뺅: '팔',
    뺆: '팔',
    뺇: '팔',
    뺈: '팔',
    뺉: '팔',
    뺊: '팔',
    뺋: '팔',
    뺌: '판',
    뺍: '백',
    뺎: '백',
    뺏: '백',
    뺐: '백',
    뺑: '방',
    뺒: '백',
    뺓: '백',
    뺔: '백',
    뺕: '백',
    뺖: '백',
    뺗: '패',
    뺘: '파',
    뺙: '박',
    뺚: '박',
    뺛: '박',
    뺜: '판',
    뺝: '판',
    뺞: '판',
    뺟: '박',
    뺠: '팔',
    뺡: '팔',
    뺢: '팔',
    뺣: '팔',
    뺤: '팔',
    뺥: '팔',
    뺦: '팔',
    뺧: '팔',
    뺨: '판',
    뺩: '방',
    뺪: '방',
    뺫: '박',
    뺬: '박',
    뺭: '방',
    뺮: '박',
    뺯: '박',
    뺰: '박',
    뺱: '박',
    뺲: '박',
    뺳: '파',
    뺴: '패',
    뺵: '백',
    뺶: '백',
    뺷: '백',
    뺸: '맹',
    뺹: '맹',
    뺺: '맹',
    뺻: '백',
    뺼: '맹',
    뺽: '맹',
    뺾: '맹',
    뺿: '맹',
    뻀: '맹',
    뻁: '맹',
    뻂: '맹',
    뻃: '맹',
    뻄: '백',
    뻅: '백',
    뻆: '백',
    뻇: '백',
    뻈: '백',
    뻉: '패',
    뻊: '백',
    뻋: '백',
    뻌: '백',
    뻍: '백',
    뻎: '백',
    뻏: '패',
    뻐: '포',
    뻑: '폭',
    뻒: '폭',
    뻓: '폭',
    뻔: '번',
    뻕: '번',
    뻖: '번',
    뻗: '폭',
    뻘: '벌',
    뻙: '벌',
    뻚: '벌',
    뻛: '벌',
    뻜: '벌',
    뻝: '벌',
    뻞: '벌',
    뻟: '벌',
    뻠: '범',
    뻡: '법',
    뻢: '법',
    뻣: '법',
    뻤: '폭',
    뻥: '평',
    뻦: '폭',
    뻧: '폭',
    뻨: '폭',
    뻩: '폭',
    뻪: '법',
    뻫: '포',
    뻬: '패',
    뻭: '백',
    뻮: '백',
    뻯: '백',
    뻰: '편',
    뻱: '편',
    뻲: '편',
    뻳: '백',
    뻴: '별',
    뻵: '별',
    뻶: '별',
    뻷: '별',
    뻸: '별',
    뻹: '별',
    뻺: '별',
    뻻: '별',
    뻼: '범',
    뻽: '법',
    뻾: '법',
    뻿: '백',
    뼀: '백',
    뼁: '평',
    뼂: '백',
    뼃: '백',
    뼄: '백',
    뼅: '백',
    뼆: '법',
    뼇: '폐',
    뼈: '폐',
    뼉: '벽',
    뼊: '벽',
    뼋: '벽',
    뼌: '편',
    뼍: '편',
    뼎: '편',
    뼏: '벽',
    뼐: '별',
    뼑: '별',
    뼒: '별',
    뼓: '별',
    뼔: '별',
    뼕: '별',
    뼖: '별',
    뼗: '별',
    뼘: '범',
    뼙: '법',
    뼚: '법',
    뼛: '벽',
    뼜: '벽',
    뼝: '평',
    뼞: '벽',
    뼟: '벽',
    뼠: '벽',
    뼡: '벽',
    뼢: '법',
    뼣: '폐',
    뼤: '폐',
    뼥: '벽',
    뼦: '벽',
    뼧: '벽',
    뼨: '편',
    뼩: '편',
    뼪: '편',
    뼫: '벽',
    뼬: '별',
    뼭: '별',
    뼮: '별',
    뼯: '별',
    뼰: '별',
    뼱: '별',
    뼲: '별',
    뼳: '별',
    뼴: '평',
    뼵: '평',
    뼶: '평',
    뼷: '벽',
    뼸: '벽',
    뼹: '평',
    뼺: '벽',
    뼻: '벽',
    뼼: '벽',
    뼽: '벽',
    뼾: '벽',
    뼿: '폐',
    뽀: '포',
    뽁: '폭',
    뽂: '폭',
    뽃: '폭',
    뽄: '본',
    뽅: '본',
    뽆: '본',
    뽇: '폭',
    뽈: '팔',
    뽉: '팔',
    뽊: '팔',
    뽋: '팔',
    뽌: '팔',
    뽍: '팔',
    뽎: '팔',
    뽏: '팔',
    뽐: '범',
    뽑: '법',
    뽒: '법',
    뽓: '폭',
    뽔: '폭',
    뽕: '봉',
    뽖: '폭',
    뽗: '폭',
    뽘: '폭',
    뽙: '폭',
    뽚: '법',
    뽛: '포',
    뽜: '파',
    뽝: '박',
    뽞: '박',
    뽟: '박',
    뽠: '판',
    뽡: '판',
    뽢: '판',
    뽣: '박',
    뽤: '팔',
    뽥: '팔',
    뽦: '팔',
    뽧: '팔',
    뽨: '팔',
    뽩: '팔',
    뽪: '팔',
    뽫: '팔',
    뽬: '판',
    뽭: '방',
    뽮: '방',
    뽯: '박',
    뽰: '박',
    뽱: '방',
    뽲: '박',
    뽳: '박',
    뽴: '박',
    뽵: '박',
    뽶: '박',
    뽷: '파',
    뽸: '패',
    뽹: '폭',
    뽺: '폭',
    뽻: '폭',
    뽼: '본',
    뽽: '본',
    뽾: '본',
    뽿: '폭',
    뾀: '벌',
    뾁: '벌',
    뾂: '벌',
    뾃: '벌',
    뾄: '벌',
    뾅: '벌',
    뾆: '벌',
    뾇: '벌',
    뾈: '범',
    뾉: '법',
    뾊: '법',
    뾋: '폭',
    뾌: '폭',
    뾍: '봉',
    뾎: '폭',
    뾏: '폭',
    뾐: '폭',
    뾑: '폭',
    뾒: '법',
    뾓: '포',
    뾔: '포',
    뾕: '폭',
    뾖: '폭',
    뾗: '폭',
    뾘: '본',
    뾙: '본',
    뾚: '본',
    뾛: '폭',
    뾜: '본',
    뾝: '본',
    뾞: '본',
    뾟: '본',
    뾠: '본',
    뾡: '본',
    뾢: '본',
    뾣: '본',
    뾤: '범',
    뾥: '법',
    뾦: '법',
    뾧: '폭',
    뾨: '폭',
    뾩: '봉',
    뾪: '폭',
    뾫: '폭',
    뾬: '폭',
    뾭: '폭',
    뾮: '법',
    뾯: '포',
    뾰: '표',
    뾱: '폭',
    뾲: '폭',
    뾳: '폭',
    뾴: '본',
    뾵: '본',
    뾶: '본',
    뾷: '폭',
    뾸: '별',
    뾹: '별',
    뾺: '별',
    뾻: '별',
    뾼: '별',
    뾽: '별',
    뾾: '별',
    뾿: '별',
    뿀: '봉',
    뿁: '폭',
    뿂: '폭',
    뿃: '폭',
    뿄: '폭',
    뿅: '봉',
    뿆: '폭',
    뿇: '폭',
    뿈: '폭',
    뿉: '폭',
    뿊: '폭',
    뿋: '표',
    뿌: '부',
    뿍: '북',
    뿎: '북',
    뿏: '북',
    뿐: '분',
    뿑: '분',
    뿒: '분',
    뿓: '북',
    뿔: '불',
    뿕: '불',
    뿖: '불',
    뿗: '불',
    뿘: '불',
    뿙: '불',
    뿚: '불',
    뿛: '불',
    뿜: '품',
    뿝: '품',
    뿞: '품',
    뿟: '북',
    뿠: '북',
    뿡: '풍',
    뿢: '북',
    뿣: '북',
    뿤: '북',
    뿥: '북',
    뿦: '품',
    뿧: '부',
    뿨: '부',
    뿩: '북',
    뿪: '북',
    뿫: '북',
    뿬: '번',
    뿭: '번',
    뿮: '번',
    뿯: '북',
    뿰: '벌',
    뿱: '벌',
    뿲: '벌',
    뿳: '벌',
    뿴: '벌',
    뿵: '벌',
    뿶: '벌',
    뿷: '벌',
    뿸: '범',
    뿹: '법',
    뿺: '법',
    뿻: '법',
    뿼: '북',
    뿽: '풍',
    뿾: '북',
    뿿: '북',
    쀀: '북',
    쀁: '북',
    쀂: '법',
    쀃: '부',
    쀄: '폐',
    쀅: '백',
    쀆: '백',
    쀇: '백',
    쀈: '번',
    쀉: '번',
    쀊: '번',
    쀋: '백',
    쀌: '벌',
    쀍: '벌',
    쀎: '벌',
    쀏: '벌',
    쀐: '벌',
    쀑: '벌',
    쀒: '벌',
    쀓: '벌',
    쀔: '범',
    쀕: '법',
    쀖: '법',
    쀗: '백',
    쀘: '백',
    쀙: '폐',
    쀚: '백',
    쀛: '백',
    쀜: '백',
    쀝: '백',
    쀞: '법',
    쀟: '폐',
    쀠: '피',
    쀡: '북',
    쀢: '북',
    쀣: '북',
    쀤: '분',
    쀥: '분',
    쀦: '분',
    쀧: '북',
    쀨: '필',
    쀩: '필',
    쀪: '필',
    쀫: '필',
    쀬: '필',
    쀭: '필',
    쀮: '필',
    쀯: '필',
    쀰: '품',
    쀱: '품',
    쀲: '품',
    쀳: '북',
    쀴: '북',
    쀵: '풍',
    쀶: '북',
    쀷: '북',
    쀸: '북',
    쀹: '북',
    쀺: '품',
    쀻: '피',
    쀼: '부',
    쀽: '북',
    쀾: '북',
    쀿: '북',
    쁀: '분',
    쁁: '분',
    쁂: '분',
    쁃: '북',
    쁄: '불',
    쁅: '불',
    쁆: '불',
    쁇: '불',
    쁈: '불',
    쁉: '불',
    쁊: '불',
    쁋: '불',
    쁌: '품',
    쁍: '품',
    쁎: '품',
    쁏: '북',
    쁐: '북',
    쁑: '풍',
    쁒: '북',
    쁓: '북',
    쁔: '북',
    쁕: '북',
    쁖: '품',
    쁗: '부',
    쁘: '부',
    쁙: '북',
    쁚: '북',
    쁛: '북',
    쁜: '분',
    쁝: '분',
    쁞: '분',
    쁟: '북',
    쁠: '불',
    쁡: '불',
    쁢: '불',
    쁣: '불',
    쁤: '불',
    쁥: '불',
    쁦: '불',
    쁧: '불',
    쁨: '품',
    쁩: '품',
    쁪: '품',
    쁫: '북',
    쁬: '북',
    쁭: '풍',
    쁮: '북',
    쁯: '북',
    쁰: '북',
    쁱: '북',
    쁲: '품',
    쁳: '부',
    쁴: '피',
    쁵: '북',
    쁶: '북',
    쁷: '북',
    쁸: '빈',
    쁹: '빈',
    쁺: '빈',
    쁻: '북',
    쁼: '필',
    쁽: '필',
    쁾: '필',
    쁿: '필',
    삀: '필',
    삁: '필',
    삂: '필',
    삃: '필',
    삄: '품',
    삅: '품',
    삆: '품',
    삇: '북',
    삈: '북',
    삉: '빙',
    삊: '북',
    삋: '북',
    삌: '북',
    삍: '북',
    삎: '품',
    삏: '피',
    삐: '피',
    삑: '백',
    삒: '백',
    삓: '백',
    삔: '빈',
    삕: '빈',
    삖: '빈',
    삗: '백',
    삘: '필',
    삙: '필',
    삚: '필',
    삛: '필',
    삜: '필',
    삝: '필',
    삞: '필',
    삟: '필',
    삠: '빙',
    삡: '빙',
    삢: '빙',
    삣: '백',
    삤: '백',
    삥: '빙',
    삦: '백',
    삧: '백',
    삨: '백',
    삩: '백',
    삪: '백',
    삫: '피',
    사: '사',
    삭: '삭',
    삮: '삭',
    삯: '삭',
    산: '산',
    삱: '산',
    삲: '산',
    삳: '삭',
    살: '살',
    삵: '살',
    삶: '살',
    삷: '살',
    삸: '살',
    삹: '살',
    삺: '살',
    삻: '살',
    삼: '삼',
    삽: '삼',
    삾: '삼',
    삿: '삭',
    샀: '삭',
    상: '상',
    샂: '삭',
    샃: '삭',
    샄: '삭',
    샅: '삭',
    샆: '삼',
    샇: '사',
    새: '새',
    색: '색',
    샊: '색',
    샋: '색',
    샌: '산',
    샍: '산',
    샎: '산',
    샏: '색',
    샐: '살',
    샑: '살',
    샒: '살',
    샓: '살',
    샔: '살',
    샕: '살',
    샖: '살',
    샗: '살',
    샘: '삼',
    샙: '십',
    샚: '십',
    샛: '색',
    샜: '색',
    생: '생',
    샞: '색',
    샟: '색',
    샠: '색',
    샡: '색',
    샢: '십',
    샣: '새',
    샤: '사',
    샥: '삭',
    샦: '삭',
    샧: '삭',
    샨: '산',
    샩: '산',
    샪: '산',
    샫: '삭',
    샬: '살',
    샭: '살',
    샮: '살',
    샯: '살',
    샰: '살',
    샱: '살',
    샲: '살',
    샳: '살',
    샴: '삼',
    샵: '삼',
    샶: '삼',
    샷: '삭',
    샸: '삭',
    샹: '상',
    샺: '삭',
    샻: '삭',
    샼: '삭',
    샽: '삭',
    샾: '삼',
    샿: '사',
    섀: '새',
    섁: '색',
    섂: '색',
    섃: '색',
    섄: '생',
    섅: '생',
    섆: '생',
    섇: '색',
    섈: '생',
    섉: '생',
    섊: '생',
    섋: '생',
    섌: '생',
    섍: '생',
    섎: '생',
    섏: '생',
    섐: '생',
    섑: '생',
    섒: '생',
    섓: '색',
    섔: '색',
    섕: '생',
    섖: '색',
    섗: '색',
    섘: '색',
    섙: '색',
    섚: '색',
    섛: '새',
    서: '서',
    석: '석',
    섞: '석',
    섟: '석',
    선: '선',
    섡: '선',
    섢: '선',
    섣: '석',
    설: '설',
    섥: '설',
    섦: '설',
    섧: '설',
    섨: '설',
    섩: '설',
    섪: '설',
    섫: '설',
    섬: '섭',
    섭: '섭',
    섮: '섭',
    섯: '섭',
    섰: '석',
    성: '성',
    섲: '석',
    섳: '석',
    섴: '석',
    섵: '석',
    섶: '섭',
    섷: '서',
    세: '세',
    섹: '색',
    섺: '색',
    섻: '색',
    센: '선',
    섽: '선',
    섾: '선',
    섿: '색',
    셀: '설',
    셁: '설',
    셂: '설',
    셃: '설',
    셄: '설',
    셅: '설',
    셆: '설',
    셇: '설',
    셈: '생',
    셉: '섭',
    셊: '섭',
    셋: '색',
    셌: '색',
    셍: '생',
    셎: '색',
    셏: '색',
    셐: '색',
    셑: '색',
    셒: '섭',
    셓: '세',
    셔: '세',
    셕: '석',
    셖: '석',
    셗: '석',
    션: '선',
    셙: '선',
    셚: '선',
    셛: '석',
    셜: '설',
    셝: '설',
    셞: '설',
    셟: '설',
    셠: '설',
    셡: '설',
    셢: '설',
    셣: '설',
    셤: '섭',
    셥: '섭',
    셦: '섭',
    셧: '석',
    셨: '석',
    셩: '성',
    셪: '석',
    셫: '석',
    셬: '석',
    셭: '석',
    셮: '섭',
    셯: '세',
    셰: '세',
    셱: '색',
    셲: '색',
    셳: '색',
    셴: '생',
    셵: '생',
    셶: '생',
    셷: '색',
    셸: '생',
    셹: '생',
    셺: '생',
    셻: '생',
    셼: '생',
    셽: '생',
    셾: '생',
    셿: '생',
    솀: '생',
    솁: '생',
    솂: '생',
    솃: '색',
    솄: '색',
    솅: '생',
    솆: '색',
    솇: '색',
    솈: '색',
    솉: '색',
    솊: '색',
    솋: '세',
    소: '소',
    속: '속',
    솎: '속',
    솏: '속',
    손: '손',
    솑: '손',
    솒: '손',
    솓: '속',
    솔: '살',
    솕: '손',
    솖: '손',
    솗: '손',
    솘: '손',
    솙: '손',
    솚: '손',
    솛: '손',
    솜: '삼',
    솝: '섭',
    솞: '섭',
    솟: '속',
    솠: '속',
    송: '송',
    솢: '속',
    솣: '속',
    솤: '속',
    솥: '속',
    솦: '섭',
    솧: '소',
    솨: '사',
    솩: '삭',
    솪: '삭',
    솫: '삭',
    솬: '산',
    솭: '산',
    솮: '산',
    솯: '삭',
    솰: '살',
    솱: '살',
    솲: '살',
    솳: '살',
    솴: '살',
    솵: '살',
    솶: '살',
    솷: '살',
    솸: '삼',
    솹: '삼',
    솺: '삼',
    솻: '삭',
    솼: '삭',
    솽: '상',
    솾: '삭',
    솿: '삭',
    쇀: '삭',
    쇁: '삭',
    쇂: '삼',
    쇃: '사',
    쇄: '쇄',
    쇅: '색',
    쇆: '색',
    쇇: '색',
    쇈: '손',
    쇉: '손',
    쇊: '손',
    쇋: '색',
    쇌: '설',
    쇍: '설',
    쇎: '설',
    쇏: '설',
    쇐: '설',
    쇑: '설',
    쇒: '설',
    쇓: '설',
    쇔: '송',
    쇕: '섭',
    쇖: '섭',
    쇗: '색',
    쇘: '색',
    쇙: '생',
    쇚: '색',
    쇛: '색',
    쇜: '색',
    쇝: '색',
    쇞: '섭',
    쇟: '쇄',
    쇠: '쇠',
    쇡: '속',
    쇢: '속',
    쇣: '속',
    쇤: '손',
    쇥: '손',
    쇦: '손',
    쇧: '속',
    쇨: '손',
    쇩: '손',
    쇪: '손',
    쇫: '손',
    쇬: '손',
    쇭: '손',
    쇮: '손',
    쇯: '손',
    쇰: '송',
    쇱: '섭',
    쇲: '섭',
    쇳: '속',
    쇴: '속',
    쇵: '송',
    쇶: '속',
    쇷: '속',
    쇸: '속',
    쇹: '속',
    쇺: '섭',
    쇻: '쇠',
    쇼: '소',
    쇽: '속',
    쇾: '속',
    쇿: '속',
    숀: '손',
    숁: '손',
    숂: '손',
    숃: '속',
    숄: '손',
    숅: '손',
    숆: '손',
    숇: '손',
    숈: '손',
    숉: '손',
    숊: '손',
    숋: '손',
    숌: '송',
    숍: '송',
    숎: '송',
    숏: '속',
    숐: '속',
    숑: '송',
    숒: '속',
    숓: '속',
    숔: '속',
    숕: '속',
    숖: '속',
    숗: '소',
    수: '수',
    숙: '숙',
    숚: '숙',
    숛: '숙',
    순: '순',
    숝: '순',
    숞: '순',
    숟: '숙',
    술: '술',
    숡: '술',
    숢: '술',
    숣: '술',
    숤: '술',
    숥: '술',
    숦: '술',
    숧: '술',
    숨: '습',
    숩: '습',
    숪: '습',
    숫: '숙',
    숬: '숙',
    숭: '숭',
    숮: '숙',
    숯: '숙',
    숰: '숙',
    숱: '숙',
    숲: '습',
    숳: '수',
    숴: '서',
    숵: '석',
    숶: '석',
    숷: '석',
    숸: '선',
    숹: '선',
    숺: '선',
    숻: '석',
    숼: '설',
    숽: '설',
    숾: '설',
    숿: '설',
    쉀: '설',
    쉁: '설',
    쉂: '설',
    쉃: '설',
    쉄: '섭',
    쉅: '섭',
    쉆: '섭',
    쉇: '석',
    쉈: '석',
    쉉: '성',
    쉊: '석',
    쉋: '석',
    쉌: '석',
    쉍: '석',
    쉎: '섭',
    쉏: '서',
    쉐: '쇠',
    쉑: '색',
    쉒: '색',
    쉓: '색',
    쉔: '선',
    쉕: '선',
    쉖: '선',
    쉗: '색',
    쉘: '설',
    쉙: '설',
    쉚: '설',
    쉛: '설',
    쉜: '설',
    쉝: '설',
    쉞: '설',
    쉟: '설',
    쉠: '생',
    쉡: '섭',
    쉢: '섭',
    쉣: '색',
    쉤: '색',
    쉥: '생',
    쉦: '색',
    쉧: '색',
    쉨: '색',
    쉩: '색',
    쉪: '섭',
    쉫: '쇠',
    쉬: '수',
    쉭: '숙',
    쉮: '숙',
    쉯: '숙',
    쉰: '순',
    쉱: '순',
    쉲: '순',
    쉳: '숙',
    쉴: '술',
    쉵: '술',
    쉶: '술',
    쉷: '술',
    쉸: '술',
    쉹: '술',
    쉺: '술',
    쉻: '술',
    쉼: '심',
    쉽: '십',
    쉾: '십',
    쉿: '숙',
    슀: '숙',
    슁: '숭',
    슂: '숙',
    슃: '숙',
    슄: '숙',
    슅: '숙',
    슆: '십',
    슇: '수',
    슈: '수',
    슉: '숙',
    슊: '숙',
    슋: '숙',
    슌: '순',
    슍: '순',
    슎: '순',
    슏: '숙',
    슐: '술',
    슑: '술',
    슒: '술',
    슓: '술',
    슔: '술',
    슕: '술',
    슖: '술',
    슗: '술',
    슘: '숭',
    슙: '숭',
    슚: '숭',
    슛: '숙',
    슜: '숙',
    슝: '숭',
    슞: '숙',
    슟: '숙',
    슠: '숙',
    슡: '숙',
    슢: '숙',
    슣: '수',
    스: '수',
    슥: '숙',
    슦: '숙',
    슧: '숙',
    슨: '순',
    슩: '순',
    슪: '순',
    슫: '숙',
    슬: '술',
    슭: '술',
    슮: '술',
    슯: '술',
    슰: '술',
    슱: '술',
    슲: '술',
    슳: '술',
    슴: '습',
    습: '습',
    슶: '습',
    슷: '습',
    슸: '숙',
    승: '승',
    슺: '숙',
    슻: '숙',
    슼: '숙',
    슽: '숙',
    슾: '습',
    슿: '수',
    싀: '시',
    싁: '식',
    싂: '식',
    싃: '식',
    싄: '신',
    싅: '신',
    싆: '신',
    싇: '식',
    싈: '실',
    싉: '실',
    싊: '실',
    싋: '실',
    싌: '실',
    싍: '실',
    싎: '실',
    싏: '실',
    싐: '심',
    싑: '십',
    싒: '십',
    싓: '습',
    싔: '식',
    싕: '승',
    싖: '식',
    싗: '식',
    싘: '식',
    싙: '식',
    싚: '십',
    싛: '시',
    시: '시',
    식: '식',
    싞: '식',
    싟: '식',
    신: '신',
    싡: '신',
    싢: '신',
    싣: '식',
    실: '실',
    싥: '실',
    싦: '실',
    싧: '실',
    싨: '실',
    싩: '실',
    싪: '실',
    싫: '실',
    심: '심',
    십: '십',
    싮: '십',
    싯: '식',
    싰: '식',
    싱: '생',
    싲: '식',
    싳: '식',
    싴: '식',
    싵: '식',
    싶: '십',
    싷: '시',
    싸: '사',
    싹: '삭',
    싺: '삭',
    싻: '삭',
    싼: '산',
    싽: '산',
    싾: '산',
    싿: '삭',
    쌀: '살',
    쌁: '살',
    쌂: '살',
    쌃: '살',
    쌄: '살',
    쌅: '살',
    쌆: '살',
    쌇: '살',
    쌈: '삼',
    쌉: '삼',
    쌊: '삼',
    쌋: '삭',
    쌌: '삭',
    쌍: '쌍',
    쌎: '삭',
    쌏: '삭',
    쌐: '삭',
    쌑: '삭',
    쌒: '삼',
    쌓: '사',
    쌔: '새',
    쌕: '색',
    쌖: '색',
    쌗: '색',
    쌘: '산',
    쌙: '산',
    쌚: '산',
    쌛: '색',
    쌜: '살',
    쌝: '살',
    쌞: '살',
    쌟: '살',
    쌠: '살',
    쌡: '살',
    쌢: '살',
    쌣: '살',
    쌤: '삼',
    쌥: '십',
    쌦: '십',
    쌧: '색',
    쌨: '색',
    쌩: '생',
    쌪: '색',
    쌫: '색',
    쌬: '색',
    쌭: '색',
    쌮: '십',
    쌯: '새',
    쌰: '사',
    쌱: '삭',
    쌲: '삭',
    쌳: '삭',
    쌴: '산',
    쌵: '산',
    쌶: '산',
    쌷: '삭',
    쌸: '살',
    쌹: '살',
    쌺: '살',
    쌻: '살',
    쌼: '살',
    쌽: '살',
    쌾: '살',
    쌿: '살',
    썀: '삼',
    썁: '삼',
    썂: '삼',
    썃: '삭',
    썄: '삭',
    썅: '쌍',
    썆: '삭',
    썇: '삭',
    썈: '삭',
    썉: '삭',
    썊: '삼',
    썋: '사',
    썌: '새',
    썍: '색',
    썎: '색',
    썏: '색',
    썐: '생',
    썑: '생',
    썒: '생',
    썓: '색',
    썔: '생',
    썕: '생',
    썖: '생',
    썗: '생',
    썘: '생',
    썙: '생',
    썚: '생',
    썛: '생',
    썜: '생',
    썝: '생',
    썞: '생',
    썟: '색',
    썠: '색',
    썡: '생',
    썢: '색',
    썣: '색',
    썤: '색',
    썥: '색',
    썦: '색',
    썧: '새',
    써: '서',
    썩: '석',
    썪: '석',
    썫: '석',
    썬: '선',
    썭: '선',
    썮: '선',
    썯: '석',
    썰: '설',
    썱: '설',
    썲: '설',
    썳: '설',
    썴: '설',
    썵: '설',
    썶: '설',
    썷: '설',
    썸: '첨',
    썹: '섭',
    썺: '섭',
    썻: '섭',
    썼: '석',
    썽: '성',
    썾: '석',
    썿: '석',
    쎀: '석',
    쎁: '석',
    쎂: '섭',
    쎃: '서',
    쎄: '세',
    쎅: '색',
    쎆: '색',
    쎇: '색',
    쎈: '선',
    쎉: '선',
    쎊: '선',
    쎋: '색',
    쎌: '설',
    쎍: '설',
    쎎: '설',
    쎏: '설',
    쎐: '설',
    쎑: '설',
    쎒: '설',
    쎓: '설',
    쎔: '첨',
    쎕: '섭',
    쎖: '섭',
    쎗: '색',
    쎘: '색',
    쎙: '생',
    쎚: '색',
    쎛: '색',
    쎜: '색',
    쎝: '색',
    쎞: '섭',
    쎟: '세',
    쎠: '세',
    쎡: '석',
    쎢: '석',
    쎣: '석',
    쎤: '선',
    쎥: '선',
    쎦: '선',
    쎧: '석',
    쎨: '설',
    쎩: '설',
    쎪: '설',
    쎫: '설',
    쎬: '설',
    쎭: '설',
    쎮: '설',
    쎯: '설',
    쎰: '첨',
    쎱: '섭',
    쎲: '섭',
    쎳: '석',
    쎴: '석',
    쎵: '성',
    쎶: '석',
    쎷: '석',
    쎸: '석',
    쎹: '석',
    쎺: '섭',
    쎻: '세',
    쎼: '세',
    쎽: '색',
    쎾: '색',
    쎿: '색',
    쏀: '생',
    쏁: '생',
    쏂: '생',
    쏃: '색',
    쏄: '생',
    쏅: '생',
    쏆: '생',
    쏇: '생',
    쏈: '생',
    쏉: '생',
    쏊: '생',
    쏋: '생',
    쏌: '생',
    쏍: '생',
    쏎: '생',
    쏏: '색',
    쏐: '색',
    쏑: '생',
    쏒: '색',
    쏓: '색',
    쏔: '색',
    쏕: '색',
    쏖: '색',
    쏗: '세',
    쏘: '소',
    쏙: '속',
    쏚: '속',
    쏛: '속',
    쏜: '손',
    쏝: '손',
    쏞: '손',
    쏟: '속',
    쏠: '살',
    쏡: '손',
    쏢: '손',
    쏣: '손',
    쏤: '손',
    쏥: '손',
    쏦: '손',
    쏧: '손',
    쏨: '삼',
    쏩: '섭',
    쏪: '섭',
    쏫: '속',
    쏬: '속',
    쏭: '송',
    쏮: '속',
    쏯: '속',
    쏰: '속',
    쏱: '속',
    쏲: '섭',
    쏳: '소',
    쏴: '사',
    쏵: '삭',
    쏶: '삭',
    쏷: '삭',
    쏸: '산',
    쏹: '산',
    쏺: '산',
    쏻: '삭',
    쏼: '살',
    쏽: '살',
    쏾: '살',
    쏿: '살',
    쐀: '살',
    쐁: '살',
    쐂: '살',
    쐃: '살',
    쐄: '삼',
    쐅: '삼',
    쐆: '삼',
    쐇: '삭',
    쐈: '삭',
    쐉: '쌍',
    쐊: '삭',
    쐋: '삭',
    쐌: '삭',
    쐍: '삭',
    쐎: '삼',
    쐏: '사',
    쐐: '쇄',
    쐑: '색',
    쐒: '색',
    쐓: '색',
    쐔: '손',
    쐕: '손',
    쐖: '손',
    쐗: '색',
    쐘: '설',
    쐙: '설',
    쐚: '설',
    쐛: '설',
    쐜: '설',
    쐝: '설',
    쐞: '설',
    쐟: '설',
    쐠: '첨',
    쐡: '섭',
    쐢: '섭',
    쐣: '색',
    쐤: '색',
    쐥: '송',
    쐦: '색',
    쐧: '색',
    쐨: '색',
    쐩: '색',
    쐪: '섭',
    쐫: '쇄',
    쐬: '쇠',
    쐭: '속',
    쐮: '속',
    쐯: '속',
    쐰: '손',
    쐱: '손',
    쐲: '손',
    쐳: '속',
    쐴: '손',
    쐵: '손',
    쐶: '손',
    쐷: '손',
    쐸: '손',
    쐹: '손',
    쐺: '손',
    쐻: '손',
    쐼: '송',
    쐽: '섭',
    쐾: '섭',
    쐿: '속',
    쑀: '속',
    쑁: '송',
    쑂: '속',
    쑃: '속',
    쑄: '속',
    쑅: '속',
    쑆: '섭',
    쑇: '쇠',
    쑈: '소',
    쑉: '속',
    쑊: '속',
    쑋: '속',
    쑌: '손',
    쑍: '손',
    쑎: '손',
    쑏: '속',
    쑐: '손',
    쑑: '손',
    쑒: '손',
    쑓: '손',
    쑔: '손',
    쑕: '손',
    쑖: '손',
    쑗: '손',
    쑘: '송',
    쑙: '송',
    쑚: '송',
    쑛: '속',
    쑜: '속',
    쑝: '송',
    쑞: '속',
    쑟: '속',
    쑠: '속',
    쑡: '속',
    쑢: '속',
    쑣: '소',
    쑤: '수',
    쑥: '숙',
    쑦: '숙',
    쑧: '숙',
    쑨: '순',
    쑩: '순',
    쑪: '순',
    쑫: '숙',
    쑬: '술',
    쑭: '술',
    쑮: '술',
    쑯: '술',
    쑰: '술',
    쑱: '술',
    쑲: '술',
    쑳: '술',
    쑴: '습',
    쑵: '습',
    쑶: '습',
    쑷: '숙',
    쑸: '숙',
    쑹: '숭',
    쑺: '숙',
    쑻: '숙',
    쑼: '숙',
    쑽: '숙',
    쑾: '습',
    쑿: '수',
    쒀: '서',
    쒁: '석',
    쒂: '석',
    쒃: '석',
    쒄: '선',
    쒅: '선',
    쒆: '선',
    쒇: '석',
    쒈: '설',
    쒉: '설',
    쒊: '설',
    쒋: '설',
    쒌: '설',
    쒍: '설',
    쒎: '설',
    쒏: '설',
    쒐: '첨',
    쒑: '섭',
    쒒: '섭',
    쒓: '석',
    쒔: '석',
    쒕: '성',
    쒖: '석',
    쒗: '석',
    쒘: '석',
    쒙: '석',
    쒚: '섭',
    쒛: '서',
    쒜: '쇠',
    쒝: '색',
    쒞: '색',
    쒟: '색',
    쒠: '선',
    쒡: '선',
    쒢: '선',
    쒣: '색',
    쒤: '설',
    쒥: '설',
    쒦: '설',
    쒧: '설',
    쒨: '설',
    쒩: '설',
    쒪: '설',
    쒫: '설',
    쒬: '첨',
    쒭: '섭',
    쒮: '섭',
    쒯: '색',
    쒰: '색',
    쒱: '생',
    쒲: '색',
    쒳: '색',
    쒴: '색',
    쒵: '색',
    쒶: '섭',
    쒷: '쇠',
    쒸: '씨',
    쒹: '숙',
    쒺: '숙',
    쒻: '숙',
    쒼: '순',
    쒽: '순',
    쒾: '순',
    쒿: '숙',
    쓀: '술',
    쓁: '술',
    쓂: '술',
    쓃: '술',
    쓄: '술',
    쓅: '술',
    쓆: '술',
    쓇: '술',
    쓈: '심',
    쓉: '십',
    쓊: '십',
    쓋: '숙',
    쓌: '숙',
    쓍: '숭',
    쓎: '숙',
    쓏: '숙',
    쓐: '숙',
    쓑: '숙',
    쓒: '십',
    쓓: '씨',
    쓔: '수',
    쓕: '숙',
    쓖: '숙',
    쓗: '숙',
    쓘: '순',
    쓙: '순',
    쓚: '순',
    쓛: '숙',
    쓜: '술',
    쓝: '술',
    쓞: '술',
    쓟: '술',
    쓠: '술',
    쓡: '술',
    쓢: '술',
    쓣: '술',
    쓤: '숭',
    쓥: '숭',
    쓦: '숭',
    쓧: '숙',
    쓨: '숙',
    쓩: '숭',
    쓪: '숙',
    쓫: '숙',
    쓬: '숙',
    쓭: '숙',
    쓮: '숙',
    쓯: '수',
    쓰: '수',
    쓱: '측',
    쓲: '측',
    쓳: '측',
    쓴: '순',
    쓵: '순',
    쓶: '순',
    쓷: '측',
    쓸: '술',
    쓹: '술',
    쓺: '술',
    쓻: '술',
    쓼: '술',
    쓽: '술',
    쓾: '술',
    쓿: '술',
    씀: '습',
    씁: '습',
    씂: '습',
    씃: '습',
    씄: '측',
    씅: '승',
    씆: '측',
    씇: '측',
    씈: '측',
    씉: '측',
    씊: '습',
    씋: '수',
    씌: '씨',
    씍: '식',
    씎: '식',
    씏: '식',
    씐: '신',
    씑: '신',
    씒: '신',
    씓: '식',
    씔: '실',
    씕: '실',
    씖: '실',
    씗: '실',
    씘: '실',
    씙: '실',
    씚: '실',
    씛: '실',
    씜: '심',
    씝: '습',
    씞: '습',
    씟: '식',
    씠: '식',
    씡: '승',
    씢: '식',
    씣: '식',
    씤: '식',
    씥: '식',
    씦: '십',
    씧: '씨',
    씨: '씨',
    씩: '식',
    씪: '식',
    씫: '식',
    씬: '신',
    씭: '신',
    씮: '신',
    씯: '식',
    씰: '실',
    씱: '실',
    씲: '실',
    씳: '실',
    씴: '실',
    씵: '실',
    씶: '실',
    씷: '실',
    씸: '심',
    씹: '십',
    씺: '십',
    씻: '식',
    씼: '식',
    씽: '칭',
    씾: '식',
    씿: '식',
    앀: '식',
    앁: '식',
    앂: '십',
    앃: '씨',
    아: '아',
    악: '악',
    앆: '악',
    앇: '악',
    안: '안',
    앉: '안',
    않: '안',
    앋: '악',
    알: '알',
    앍: '알',
    앎: '알',
    앏: '알',
    앐: '알',
    앑: '알',
    앒: '알',
    앓: '알',
    암: '암',
    압: '압',
    앖: '압',
    앗: '악',
    았: '악',
    앙: '앙',
    앚: '악',
    앛: '악',
    앜: '악',
    앝: '악',
    앞: '압',
    앟: '아',
    애: '애',
    액: '액',
    앢: '액',
    앣: '액',
    앤: '안',
    앥: '안',
    앦: '안',
    앧: '액',
    앨: '알',
    앩: '알',
    앪: '알',
    앫: '알',
    앬: '알',
    앭: '알',
    앮: '알',
    앯: '알',
    앰: '암',
    앱: '압',
    앲: '압',
    앳: '액',
    앴: '액',
    앵: '앙',
    앶: '액',
    앷: '액',
    앸: '액',
    앹: '액',
    앺: '압',
    앻: '애',
    야: '야',
    약: '약',
    앾: '약',
    앿: '약',
    얀: '안',
    얁: '안',
    얂: '안',
    얃: '약',
    얄: '알',
    얅: '알',
    얆: '알',
    얇: '알',
    얈: '알',
    얉: '알',
    얊: '알',
    얋: '알',
    얌: '암',
    얍: '압',
    얎: '압',
    얏: '약',
    얐: '약',
    양: '양',
    얒: '약',
    얓: '약',
    얔: '약',
    얕: '약',
    얖: '압',
    얗: '야',
    얘: '예',
    얙: '액',
    얚: '액',
    얛: '액',
    얜: '양',
    얝: '양',
    얞: '양',
    얟: '액',
    얠: '양',
    얡: '양',
    얢: '양',
    얣: '양',
    얤: '양',
    얥: '양',
    얦: '양',
    얧: '양',
    얨: '양',
    얩: '액',
    얪: '액',
    얫: '액',
    얬: '액',
    얭: '양',
    얮: '액',
    얯: '액',
    얰: '액',
    얱: '액',
    얲: '액',
    얳: '예',
    어: '어',
    억: '억',
    얶: '억',
    얷: '억',
    언: '언',
    얹: '언',
    얺: '언',
    얻: '억',
    얼: '월',
    얽: '월',
    얾: '월',
    얿: '월',
    엀: '월',
    엁: '월',
    엂: '월',
    엃: '월',
    엄: '엄',
    업: '업',
    없: '업',
    엇: '억',
    었: '억',
    엉: '영',
    엊: '억',
    엋: '억',
    엌: '억',
    엍: '억',
    엎: '업',
    엏: '어',
    에: '예',
    엑: '액',
    엒: '액',
    엓: '액',
    엔: '연',
    엕: '연',
    엖: '연',
    엗: '액',
    엘: '열',
    엙: '열',
    엚: '열',
    엛: '열',
    엜: '열',
    엝: '열',
    엞: '열',
    엟: '열',
    엠: '염',
    엡: '엽',
    엢: '엽',
    엣: '액',
    엤: '액',
    엥: '영',
    엦: '액',
    엧: '액',
    엨: '액',
    엩: '액',
    엪: '엽',
    엫: '예',
    여: '여',
    역: '역',
    엮: '역',
    엯: '역',
    연: '연',
    엱: '연',
    엲: '연',
    엳: '역',
    열: '열',
    엵: '열',
    엶: '열',
    엷: '열',
    엸: '열',
    엹: '열',
    엺: '열',
    엻: '열',
    염: '염',
    엽: '엽',
    엾: '엽',
    엿: '역',
    였: '역',
    영: '영',
    옂: '역',
    옃: '역',
    옄: '역',
    옅: '역',
    옆: '엽',
    옇: '여',
    예: '예',
    옉: '역',
    옊: '역',
    옋: '역',
    옌: '연',
    옍: '연',
    옎: '연',
    옏: '역',
    옐: '열',
    옑: '열',
    옒: '열',
    옓: '열',
    옔: '열',
    옕: '열',
    옖: '열',
    옗: '열',
    옘: '염',
    옙: '엽',
    옚: '엽',
    옛: '역',
    옜: '역',
    옝: '영',
    옞: '역',
    옟: '역',
    옠: '역',
    옡: '역',
    옢: '엽',
    옣: '예',
    오: '오',
    옥: '옥',
    옦: '옥',
    옧: '옥',
    온: '온',
    옩: '온',
    옪: '온',
    옫: '옥',
    올: '홀',
    옭: '온',
    옮: '온',
    옯: '온',
    옰: '온',
    옱: '온',
    옲: '온',
    옳: '온',
    옴: '암',
    옵: '압',
    옶: '압',
    옷: '옥',
    옸: '옥',
    옹: '옹',
    옺: '옥',
    옻: '옥',
    옼: '옥',
    옽: '옥',
    옾: '압',
    옿: '오',
    와: '와',
    왁: '악',
    왂: '악',
    왃: '악',
    완: '완',
    왅: '완',
    왆: '완',
    왇: '악',
    왈: '왈',
    왉: '왈',
    왊: '왈',
    왋: '왈',
    왌: '왈',
    왍: '왈',
    왎: '왈',
    왏: '왈',
    왐: '암',
    왑: '압',
    왒: '압',
    왓: '압',
    왔: '악',
    왕: '왕',
    왖: '악',
    왗: '악',
    왘: '악',
    왙: '악',
    왚: '압',
    왛: '와',
    왜: '외',
    왝: '액',
    왞: '옥',
    왟: '옥',
    왠: '원',
    왡: '원',
    왢: '원',
    왣: '옥',
    왤: '월',
    왥: '월',
    왦: '월',
    왧: '월',
    왨: '월',
    왩: '월',
    왪: '월',
    왫: '월',
    왬: '엄',
    왭: '업',
    왮: '업',
    왯: '옥',
    왰: '옥',
    왱: '왕',
    왲: '옥',
    왳: '옥',
    왴: '옥',
    왵: '옥',
    왶: '업',
    왷: '외',
    외: '외',
    왹: '옥',
    왺: '옥',
    왻: '옥',
    왼: '온',
    왽: '온',
    왾: '온',
    왿: '옥',
    욀: '월',
    욁: '월',
    욂: '월',
    욃: '월',
    욄: '월',
    욅: '월',
    욆: '월',
    욇: '월',
    욈: '엄',
    욉: '업',
    욊: '업',
    욋: '옥',
    욌: '옥',
    욍: '옹',
    욎: '옥',
    욏: '옥',
    욐: '옥',
    욑: '옥',
    욒: '업',
    욓: '외',
    요: '요',
    욕: '욕',
    욖: '욕',
    욗: '욕',
    욘: '온',
    욙: '온',
    욚: '온',
    욛: '욕',
    욜: '열',
    욝: '열',
    욞: '열',
    욟: '열',
    욠: '열',
    욡: '열',
    욢: '열',
    욣: '열',
    욤: '염',
    욥: '엽',
    욦: '엽',
    욧: '욕',
    욨: '욕',
    용: '용',
    욪: '욕',
    욫: '욕',
    욬: '욕',
    욭: '욕',
    욮: '엽',
    욯: '요',
    우: '우',
    욱: '육',
    욲: '육',
    욳: '육',
    운: '운',
    욵: '운',
    욶: '운',
    욷: '육',
    울: '을',
    욹: '을',
    욺: '을',
    욻: '을',
    욼: '을',
    욽: '을',
    욾: '을',
    욿: '을',
    움: '음',
    웁: '읍',
    웂: '읍',
    웃: '육',
    웄: '육',
    웅: '웅',
    웆: '육',
    웇: '육',
    웈: '육',
    웉: '육',
    웊: '읍',
    웋: '우',
    워: '어',
    웍: '억',
    웎: '억',
    웏: '억',
    원: '원',
    웑: '원',
    웒: '원',
    웓: '억',
    월: '월',
    웕: '월',
    웖: '월',
    웗: '월',
    웘: '월',
    웙: '월',
    웚: '월',
    웛: '월',
    웜: '엄',
    웝: '업',
    웞: '업',
    웟: '억',
    웠: '억',
    웡: '웅',
    웢: '억',
    웣: '억',
    웤: '억',
    웥: '억',
    웦: '업',
    웧: '어',
    웨: '외',
    웩: '획',
    웪: '획',
    웫: '획',
    웬: '원',
    웭: '원',
    웮: '원',
    웯: '획',
    웰: '월',
    웱: '월',
    웲: '월',
    웳: '월',
    웴: '월',
    웵: '월',
    웶: '월',
    웷: '월',
    웸: '엄',
    웹: '업',
    웺: '업',
    웻: '획',
    웼: '획',
    웽: '횡',
    웾: '획',
    웿: '획',
    윀: '획',
    윁: '획',
    윂: '업',
    윃: '외',
    위: '위',
    윅: '육',
    윆: '육',
    윇: '육',
    윈: '운',
    윉: '운',
    윊: '운',
    윋: '육',
    윌: '일',
    윍: '일',
    윎: '일',
    윏: '일',
    윐: '일',
    윑: '일',
    윒: '일',
    윓: '일',
    윔: '임',
    윕: '입',
    윖: '입',
    윗: '육',
    윘: '육',
    윙: '웅',
    윚: '육',
    윛: '육',
    윜: '육',
    윝: '육',
    윞: '입',
    윟: '위',
    유: '유',
    육: '육',
    윢: '육',
    윣: '육',
    윤: '윤',
    윥: '윤',
    윦: '윤',
    윧: '육',
    율: '윤',
    윩: '윤',
    윪: '윤',
    윫: '윤',
    윬: '윤',
    윭: '윤',
    윮: '윤',
    윯: '윤',
    윰: '웅',
    윱: '웅',
    윲: '웅',
    윳: '육',
    윴: '육',
    융: '웅',
    윶: '육',
    윷: '육',
    윸: '육',
    윹: '육',
    윺: '육',
    윻: '유',
    으: '의',
    윽: '흑',
    윾: '흑',
    윿: '흑',
    은: '은',
    읁: '은',
    읂: '은',
    읃: '흑',
    을: '을',
    읅: '을',
    읆: '을',
    읇: '을',
    읈: '을',
    읉: '을',
    읊: '을',
    읋: '을',
    음: '음',
    읍: '읍',
    읎: '읍',
    읏: '읍',
    읐: '흑',
    응: '응',
    읒: '흑',
    읓: '흑',
    읔: '흑',
    읕: '흑',
    읖: '읍',
    읗: '의',
    의: '의',
    읙: '익',
    읚: '익',
    읛: '익',
    읜: '은',
    읝: '은',
    읞: '은',
    읟: '익',
    읠: '을',
    읡: '을',
    읢: '을',
    읣: '을',
    읤: '을',
    읥: '을',
    읦: '을',
    읧: '을',
    읨: '임',
    읩: '입',
    읪: '입',
    읫: '입',
    읬: '익',
    읭: '응',
    읮: '익',
    읯: '익',
    읰: '익',
    읱: '익',
    읲: '입',
    읳: '의',
    이: '이',
    익: '익',
    읶: '익',
    읷: '익',
    인: '인',
    읹: '인',
    읺: '인',
    읻: '익',
    일: '일',
    읽: '일',
    읾: '일',
    읿: '일',
    잀: '일',
    잁: '일',
    잂: '일',
    잃: '일',
    임: '임',
    입: '입',
    잆: '입',
    잇: '익',
    있: '익',
    잉: '임',
    잊: '익',
    잋: '익',
    잌: '익',
    잍: '익',
    잎: '입',
    잏: '이',
    자: '자',
    작: '작',
    잒: '작',
    잓: '작',
    잔: '잔',
    잕: '잔',
    잖: '잔',
    잗: '작',
    잘: '찰',
    잙: '찰',
    잚: '찰',
    잛: '찰',
    잜: '찰',
    잝: '찰',
    잞: '찰',
    잟: '찰',
    잠: '잠',
    잡: '잡',
    잢: '잡',
    잣: '잡',
    잤: '작',
    장: '장',
    잦: '작',
    잧: '작',
    잨: '작',
    잩: '작',
    잪: '잡',
    잫: '자',
    재: '재',
    잭: '책',
    잮: '책',
    잯: '책',
    잰: '잔',
    잱: '잔',
    잲: '잔',
    잳: '책',
    잴: '질',
    잵: '질',
    잶: '질',
    잷: '질',
    잸: '질',
    잹: '질',
    잺: '질',
    잻: '질',
    잼: '잠',
    잽: '잡',
    잾: '잡',
    잿: '책',
    쟀: '책',
    쟁: '쟁',
    쟂: '책',
    쟃: '책',
    쟄: '책',
    쟅: '책',
    쟆: '잡',
    쟇: '재',
    쟈: '자',
    쟉: '작',
    쟊: '작',
    쟋: '작',
    쟌: '잔',
    쟍: '잔',
    쟎: '잔',
    쟏: '작',
    쟐: '찰',
    쟑: '찰',
    쟒: '찰',
    쟓: '찰',
    쟔: '찰',
    쟕: '찰',
    쟖: '찰',
    쟗: '찰',
    쟘: '잠',
    쟙: '잡',
    쟚: '잡',
    쟛: '작',
    쟜: '작',
    쟝: '장',
    쟞: '작',
    쟟: '작',
    쟠: '작',
    쟡: '작',
    쟢: '잡',
    쟣: '자',
    쟤: '재',
    쟥: '책',
    쟦: '책',
    쟧: '책',
    쟨: '쟁',
    쟩: '쟁',
    쟪: '쟁',
    쟫: '책',
    쟬: '쟁',
    쟭: '쟁',
    쟮: '쟁',
    쟯: '쟁',
    쟰: '쟁',
    쟱: '쟁',
    쟲: '쟁',
    쟳: '쟁',
    쟴: '쟁',
    쟵: '쟁',
    쟶: '쟁',
    쟷: '책',
    쟸: '책',
    쟹: '쟁',
    쟺: '책',
    쟻: '책',
    쟼: '책',
    쟽: '책',
    쟾: '책',
    쟿: '재',
    저: '저',
    적: '적',
    젂: '적',
    젃: '적',
    전: '전',
    젅: '전',
    젆: '전',
    젇: '적',
    절: '절',
    젉: '절',
    젊: '절',
    젋: '절',
    젌: '절',
    젍: '절',
    젎: '절',
    젏: '절',
    점: '점',
    접: '접',
    젒: '접',
    젓: '적',
    젔: '적',
    정: '정',
    젖: '적',
    젗: '적',
    젘: '적',
    젙: '적',
    젚: '접',
    젛: '저',
    제: '제',
    젝: '책',
    젞: '책',
    젟: '책',
    젠: '전',
    젡: '전',
    젢: '전',
    젣: '책',
    젤: '절',
    젥: '절',
    젦: '절',
    젧: '절',
    젨: '절',
    젩: '절',
    젪: '절',
    젫: '절',
    젬: '점',
    젭: '접',
    젮: '접',
    젯: '책',
    젰: '책',
    젱: '쟁',
    젲: '책',
    젳: '책',
    젴: '책',
    젵: '책',
    젶: '접',
    젷: '제',
    져: '제',
    젹: '적',
    젺: '적',
    젻: '적',
    젼: '전',
    젽: '전',
    젾: '전',
    젿: '적',
    졀: '절',
    졁: '절',
    졂: '절',
    졃: '절',
    졄: '절',
    졅: '절',
    졆: '절',
    졇: '절',
    졈: '점',
    졉: '접',
    졊: '접',
    졋: '적',
    졌: '적',
    졍: '정',
    졎: '적',
    졏: '적',
    졐: '적',
    졑: '적',
    졒: '접',
    졓: '제',
    졔: '제',
    졕: '책',
    졖: '책',
    졗: '책',
    졘: '쟁',
    졙: '쟁',
    졚: '쟁',
    졛: '책',
    졜: '쟁',
    졝: '쟁',
    졞: '쟁',
    졟: '쟁',
    졠: '쟁',
    졡: '쟁',
    졢: '쟁',
    졣: '쟁',
    졤: '쟁',
    졥: '쟁',
    졦: '쟁',
    졧: '책',
    졨: '책',
    졩: '쟁',
    졪: '책',
    졫: '책',
    졬: '책',
    졭: '책',
    졮: '책',
    졯: '제',
    조: '조',
    족: '족',
    졲: '족',
    졳: '족',
    존: '존',
    졵: '존',
    졶: '존',
    졷: '족',
    졸: '졸',
    졹: '졸',
    졺: '졸',
    졻: '졸',
    졼: '졸',
    졽: '졸',
    졾: '졸',
    졿: '졸',
    좀: '잠',
    좁: '잡',
    좂: '잡',
    좃: '족',
    좄: '족',
    종: '종',
    좆: '족',
    좇: '족',
    좈: '족',
    좉: '족',
    좊: '잡',
    좋: '조',
    좌: '좌',
    좍: '작',
    좎: '작',
    좏: '작',
    좐: '잔',
    좑: '잔',
    좒: '잔',
    좓: '작',
    좔: '찰',
    좕: '찰',
    좖: '찰',
    좗: '찰',
    좘: '찰',
    좙: '찰',
    좚: '찰',
    좛: '찰',
    좜: '잠',
    좝: '잡',
    좞: '잡',
    좟: '작',
    좠: '작',
    좡: '장',
    좢: '작',
    좣: '작',
    좤: '작',
    좥: '작',
    좦: '잡',
    좧: '좌',
    좨: '죄',
    좩: '족',
    좪: '족',
    좫: '족',
    좬: '존',
    좭: '존',
    좮: '존',
    좯: '족',
    좰: '졸',
    좱: '졸',
    좲: '졸',
    좳: '졸',
    좴: '졸',
    좵: '졸',
    좶: '졸',
    좷: '졸',
    좸: '점',
    좹: '접',
    좺: '접',
    좻: '족',
    좼: '족',
    좽: '종',
    좾: '족',
    좿: '족',
    죀: '족',
    죁: '족',
    죂: '접',
    죃: '죄',
    죄: '죄',
    죅: '족',
    죆: '족',
    죇: '족',
    죈: '존',
    죉: '존',
    죊: '존',
    죋: '족',
    죌: '졸',
    죍: '졸',
    죎: '졸',
    죏: '졸',
    죐: '졸',
    죑: '졸',
    죒: '졸',
    죓: '졸',
    죔: '점',
    죕: '접',
    죖: '접',
    죗: '족',
    죘: '족',
    죙: '종',
    죚: '족',
    죛: '족',
    죜: '족',
    죝: '족',
    죞: '접',
    죟: '죄',
    죠: '조',
    죡: '족',
    죢: '족',
    죣: '족',
    죤: '존',
    죥: '존',
    죦: '존',
    죧: '족',
    죨: '졸',
    죩: '졸',
    죪: '졸',
    죫: '졸',
    죬: '졸',
    죭: '졸',
    죮: '졸',
    죯: '졸',
    죰: '종',
    죱: '종',
    죲: '종',
    죳: '족',
    죴: '족',
    죵: '종',
    죶: '족',
    죷: '족',
    죸: '족',
    죹: '족',
    죺: '족',
    죻: '조',
    주: '주',
    죽: '죽',
    죾: '죽',
    죿: '죽',
    준: '준',
    줁: '준',
    줂: '준',
    줃: '죽',
    줄: '출',
    줅: '출',
    줆: '출',
    줇: '출',
    줈: '출',
    줉: '출',
    줊: '출',
    줋: '출',
    줌: '중',
    줍: '중',
    줎: '중',
    줏: '죽',
    줐: '죽',
    중: '중',
    줒: '죽',
    줓: '죽',
    줔: '죽',
    줕: '죽',
    줖: '죽',
    줗: '주',
    줘: '저',
    줙: '적',
    줚: '적',
    줛: '적',
    줜: '전',
    줝: '전',
    줞: '전',
    줟: '적',
    줠: '절',
    줡: '절',
    줢: '절',
    줣: '절',
    줤: '절',
    줥: '절',
    줦: '절',
    줧: '절',
    줨: '점',
    줩: '접',
    줪: '접',
    줫: '적',
    줬: '적',
    줭: '정',
    줮: '적',
    줯: '적',
    줰: '적',
    줱: '적',
    줲: '접',
    줳: '저',
    줴: '죄',
    줵: '책',
    줶: '책',
    줷: '책',
    줸: '전',
    줹: '전',
    줺: '전',
    줻: '책',
    줼: '절',
    줽: '절',
    줾: '절',
    줿: '절',
    쥀: '절',
    쥁: '절',
    쥂: '절',
    쥃: '절',
    쥄: '점',
    쥅: '접',
    쥆: '접',
    쥇: '책',
    쥈: '책',
    쥉: '쟁',
    쥊: '책',
    쥋: '책',
    쥌: '책',
    쥍: '책',
    쥎: '접',
    쥏: '죄',
    쥐: '주',
    쥑: '죽',
    쥒: '죽',
    쥓: '죽',
    쥔: '준',
    쥕: '준',
    쥖: '준',
    쥗: '죽',
    쥘: '질',
    쥙: '질',
    쥚: '질',
    쥛: '질',
    쥜: '질',
    쥝: '질',
    쥞: '질',
    쥟: '질',
    쥠: '침',
    쥡: '집',
    쥢: '집',
    쥣: '죽',
    쥤: '죽',
    쥥: '중',
    쥦: '죽',
    쥧: '죽',
    쥨: '죽',
    쥩: '죽',
    쥪: '집',
    쥫: '주',
    쥬: '주',
    쥭: '죽',
    쥮: '죽',
    쥯: '죽',
    쥰: '준',
    쥱: '준',
    쥲: '준',
    쥳: '죽',
    쥴: '출',
    쥵: '출',
    쥶: '출',
    쥷: '출',
    쥸: '출',
    쥹: '출',
    쥺: '출',
    쥻: '출',
    쥼: '중',
    쥽: '중',
    쥾: '중',
    쥿: '죽',
    즀: '죽',
    즁: '중',
    즂: '죽',
    즃: '죽',
    즄: '죽',
    즅: '죽',
    즆: '죽',
    즇: '주',
    즈: '주',
    즉: '즉',
    즊: '즉',
    즋: '즉',
    즌: '준',
    즍: '준',
    즎: '준',
    즏: '즉',
    즐: '출',
    즑: '출',
    즒: '출',
    즓: '출',
    즔: '출',
    즕: '출',
    즖: '출',
    즗: '출',
    즘: '증',
    즙: '습',
    즚: '습',
    즛: '즉',
    즜: '즉',
    증: '증',
    즞: '즉',
    즟: '즉',
    즠: '즉',
    즡: '즉',
    즢: '습',
    즣: '주',
    즤: '지',
    즥: '즉',
    즦: '즉',
    즧: '즉',
    즨: '진',
    즩: '진',
    즪: '진',
    즫: '즉',
    즬: '질',
    즭: '질',
    즮: '질',
    즯: '질',
    즰: '질',
    즱: '질',
    즲: '질',
    즳: '질',
    즴: '침',
    즵: '집',
    즶: '집',
    즷: '즉',
    즸: '즉',
    즹: '징',
    즺: '즉',
    즻: '즉',
    즼: '즉',
    즽: '즉',
    즾: '집',
    즿: '지',
    지: '지',
    직: '직',
    짂: '직',
    짃: '직',
    진: '진',
    짅: '진',
    짆: '진',
    짇: '직',
    질: '질',
    짉: '질',
    짊: '질',
    짋: '질',
    짌: '질',
    짍: '질',
    짎: '질',
    짏: '질',
    짐: '침',
    집: '집',
    짒: '집',
    짓: '직',
    짔: '직',
    징: '징',
    짖: '직',
    짗: '직',
    짘: '직',
    짙: '직',
    짚: '집',
    짛: '지',
    짜: '차',
    짝: '착',
    짞: '착',
    짟: '착',
    짠: '찬',
    짡: '찬',
    짢: '찬',
    짣: '착',
    짤: '찰',
    짥: '찰',
    짦: '찰',
    짧: '찰',
    짨: '찰',
    짩: '찰',
    짪: '찰',
    짫: '찰',
    짬: '참',
    짭: '잡',
    짮: '잡',
    짯: '착',
    짰: '착',
    짱: '창',
    짲: '착',
    짳: '착',
    짴: '착',
    짵: '착',
    짶: '잡',
    짷: '차',
    째: '채',
    짹: '책',
    짺: '책',
    짻: '책',
    짼: '찬',
    짽: '찬',
    짾: '찬',
    짿: '책',
    쨀: '찰',
    쨁: '찰',
    쨂: '찰',
    쨃: '찰',
    쨄: '찰',
    쨅: '찰',
    쨆: '찰',
    쨇: '찰',
    쨈: '참',
    쨉: '잡',
    쨊: '잡',
    쨋: '책',
    쨌: '책',
    쨍: '쟁',
    쨎: '책',
    쨏: '책',
    쨐: '책',
    쨑: '책',
    쨒: '잡',
    쨓: '채',
    쨔: '차',
    쨕: '착',
    쨖: '착',
    쨗: '착',
    쨘: '찬',
    쨙: '찬',
    쨚: '찬',
    쨛: '착',
    쨜: '찰',
    쨝: '찰',
    쨞: '찰',
    쨟: '찰',
    쨠: '찰',
    쨡: '찰',
    쨢: '찰',
    쨣: '찰',
    쨤: '참',
    쨥: '잡',
    쨦: '잡',
    쨧: '착',
    쨨: '착',
    쨩: '창',
    쨪: '착',
    쨫: '착',
    쨬: '착',
    쨭: '착',
    쨮: '잡',
    쨯: '차',
    쨰: '채',
    쨱: '책',
    쨲: '책',
    쨳: '책',
    쨴: '쟁',
    쨵: '쟁',
    쨶: '쟁',
    쨷: '책',
    쨸: '쟁',
    쨹: '쟁',
    쨺: '쟁',
    쨻: '쟁',
    쨼: '쟁',
    쨽: '쟁',
    쨾: '쟁',
    쨿: '쟁',
    쩀: '쟁',
    쩁: '책',
    쩂: '책',
    쩃: '책',
    쩄: '책',
    쩅: '쟁',
    쩆: '책',
    쩇: '책',
    쩈: '책',
    쩉: '책',
    쩊: '책',
    쩋: '채',
    쩌: '처',
    쩍: '척',
    쩎: '척',
    쩏: '척',
    쩐: '천',
    쩑: '천',
    쩒: '천',
    쩓: '척',
    쩔: '철',
    쩕: '철',
    쩖: '철',
    쩗: '철',
    쩘: '철',
    쩙: '철',
    쩚: '철',
    쩛: '철',
    쩜: '첨',
    쩝: '첩',
    쩞: '첩',
    쩟: '첩',
    쩠: '척',
    쩡: '청',
    쩢: '척',
    쩣: '척',
    쩤: '척',
    쩥: '척',
    쩦: '첩',
    쩧: '처',
    쩨: '체',
    쩩: '책',
    쩪: '책',
    쩫: '책',
    쩬: '천',
    쩭: '천',
    쩮: '천',
    쩯: '책',
    쩰: '철',
    쩱: '철',
    쩲: '철',
    쩳: '철',
    쩴: '철',
    쩵: '철',
    쩶: '철',
    쩷: '철',
    쩸: '첨',
    쩹: '첩',
    쩺: '첩',
    쩻: '책',
    쩼: '책',
    쩽: '쟁',
    쩾: '책',
    쩿: '책',
    쪀: '책',
    쪁: '책',
    쪂: '첩',
    쪃: '체',
    쪄: '체',
    쪅: '척',
    쪆: '척',
    쪇: '척',
    쪈: '천',
    쪉: '천',
    쪊: '천',
    쪋: '척',
    쪌: '철',
    쪍: '철',
    쪎: '철',
    쪏: '철',
    쪐: '철',
    쪑: '철',
    쪒: '철',
    쪓: '철',
    쪔: '첨',
    쪕: '첩',
    쪖: '첩',
    쪗: '척',
    쪘: '척',
    쪙: '청',
    쪚: '척',
    쪛: '척',
    쪜: '척',
    쪝: '척',
    쪞: '첩',
    쪟: '체',
    쪠: '체',
    쪡: '책',
    쪢: '책',
    쪣: '책',
    쪤: '쟁',
    쪥: '쟁',
    쪦: '쟁',
    쪧: '책',
    쪨: '쟁',
    쪩: '쟁',
    쪪: '쟁',
    쪫: '쟁',
    쪬: '쟁',
    쪭: '쟁',
    쪮: '쟁',
    쪯: '쟁',
    쪰: '쟁',
    쪱: '책',
    쪲: '책',
    쪳: '책',
    쪴: '책',
    쪵: '쟁',
    쪶: '책',
    쪷: '책',
    쪸: '책',
    쪹: '책',
    쪺: '책',
    쪻: '체',
    쪼: '초',
    쪽: '촉',
    쪾: '촉',
    쪿: '촉',
    쫀: '촌',
    쫁: '촌',
    쫂: '촌',
    쫃: '촉',
    쫄: '졸',
    쫅: '졸',
    쫆: '졸',
    쫇: '졸',
    쫈: '졸',
    쫉: '졸',
    쫊: '졸',
    쫋: '졸',
    쫌: '참',
    쫍: '첩',
    쫎: '첩',
    쫏: '촉',
    쫐: '촉',
    쫑: '총',
    쫒: '촉',
    쫓: '촉',
    쫔: '촉',
    쫕: '촉',
    쫖: '첩',
    쫗: '초',
    쫘: '좌',
    쫙: '착',
    쫚: '착',
    쫛: '착',
    쫜: '찬',
    쫝: '찬',
    쫞: '찬',
    쫟: '착',
    쫠: '찰',
    쫡: '찰',
    쫢: '찰',
    쫣: '찰',
    쫤: '찰',
    쫥: '찰',
    쫦: '찰',
    쫧: '찰',
    쫨: '참',
    쫩: '잡',
    쫪: '잡',
    쫫: '착',
    쫬: '착',
    쫭: '창',
    쫮: '착',
    쫯: '착',
    쫰: '착',
    쫱: '착',
    쫲: '잡',
    쫳: '좌',
    쫴: '최',
    쫵: '촉',
    쫶: '책',
    쫷: '책',
    쫸: '촌',
    쫹: '촌',
    쫺: '촌',
    쫻: '책',
    쫼: '졸',
    쫽: '졸',
    쫾: '졸',
    쫿: '졸',
    쬀: '졸',
    쬁: '졸',
    쬂: '졸',
    쬃: '졸',
    쬄: '첨',
    쬅: '첩',
    쬆: '첩',
    쬇: '촉',
    쬈: '책',
    쬉: '총',
    쬊: '책',
    쬋: '책',
    쬌: '책',
    쬍: '책',
    쬎: '첩',
    쬏: '최',
    쬐: '최',
    쬑: '촉',
    쬒: '촉',
    쬓: '촉',
    쬔: '촌',
    쬕: '촌',
    쬖: '촌',
    쬗: '촉',
    쬘: '졸',
    쬙: '졸',
    쬚: '졸',
    쬛: '졸',
    쬜: '졸',
    쬝: '졸',
    쬞: '졸',
    쬟: '졸',
    쬠: '첨',
    쬡: '첩',
    쬢: '첩',
    쬣: '촉',
    쬤: '촉',
    쬥: '총',
    쬦: '촉',
    쬧: '촉',
    쬨: '촉',
    쬩: '촉',
    쬪: '첩',
    쬫: '최',
    쬬: '초',
    쬭: '촉',
    쬮: '촉',
    쬯: '촉',
    쬰: '촌',
    쬱: '촌',
    쬲: '촌',
    쬳: '촉',
    쬴: '졸',
    쬵: '졸',
    쬶: '졸',
    쬷: '졸',
    쬸: '졸',
    쬹: '졸',
    쬺: '졸',
    쬻: '졸',
    쬼: '총',
    쬽: '총',
    쬾: '총',
    쬿: '촉',
    쭀: '촉',
    쭁: '총',
    쭂: '촉',
    쭃: '촉',
    쭄: '촉',
    쭅: '촉',
    쭆: '촉',
    쭇: '초',
    쭈: '추',
    쭉: '축',
    쭊: '축',
    쭋: '축',
    쭌: '춘',
    쭍: '춘',
    쭎: '춘',
    쭏: '축',
    쭐: '출',
    쭑: '출',
    쭒: '출',
    쭓: '출',
    쭔: '출',
    쭕: '출',
    쭖: '출',
    쭗: '출',
    쭘: '충',
    쭙: '충',
    쭚: '충',
    쭛: '축',
    쭜: '축',
    쭝: '충',
    쭞: '축',
    쭟: '축',
    쭠: '축',
    쭡: '축',
    쭢: '축',
    쭣: '추',
    쭤: '처',
    쭥: '척',
    쭦: '척',
    쭧: '척',
    쭨: '천',
    쭩: '천',
    쭪: '천',
    쭫: '척',
    쭬: '철',
    쭭: '철',
    쭮: '철',
    쭯: '철',
    쭰: '철',
    쭱: '철',
    쭲: '철',
    쭳: '철',
    쭴: '첨',
    쭵: '첩',
    쭶: '첩',
    쭷: '척',
    쭸: '척',
    쭹: '청',
    쭺: '척',
    쭻: '척',
    쭼: '척',
    쭽: '척',
    쭾: '첩',
    쭿: '처',
    쮀: '최',
    쮁: '책',
    쮂: '책',
    쮃: '책',
    쮄: '천',
    쮅: '천',
    쮆: '천',
    쮇: '책',
    쮈: '철',
    쮉: '철',
    쮊: '철',
    쮋: '철',
    쮌: '철',
    쮍: '철',
    쮎: '철',
    쮏: '철',
    쮐: '첨',
    쮑: '첩',
    쮒: '첩',
    쮓: '책',
    쮔: '책',
    쮕: '최',
    쮖: '책',
    쮗: '책',
    쮘: '책',
    쮙: '책',
    쮚: '첩',
    쮛: '최',
    쮜: '취',
    쮝: '축',
    쮞: '축',
    쮟: '축',
    쮠: '춘',
    쮡: '춘',
    쮢: '춘',
    쮣: '축',
    쮤: '출',
    쮥: '출',
    쮦: '출',
    쮧: '출',
    쮨: '출',
    쮩: '출',
    쮪: '출',
    쮫: '출',
    쮬: '침',
    쮭: '집',
    쮮: '집',
    쮯: '축',
    쮰: '축',
    쮱: '충',
    쮲: '축',
    쮳: '축',
    쮴: '축',
    쮵: '축',
    쮶: '집',
    쮷: '취',
    쮸: '추',
    쮹: '축',
    쮺: '축',
    쮻: '축',
    쮼: '춘',
    쮽: '춘',
    쮾: '춘',
    쮿: '축',
    쯀: '출',
    쯁: '출',
    쯂: '출',
    쯃: '출',
    쯄: '출',
    쯅: '출',
    쯆: '출',
    쯇: '출',
    쯈: '충',
    쯉: '충',
    쯊: '충',
    쯋: '축',
    쯌: '축',
    쯍: '충',
    쯎: '축',
    쯏: '축',
    쯐: '축',
    쯑: '축',
    쯒: '축',
    쯓: '추',
    쯔: '추',
    쯕: '측',
    쯖: '측',
    쯗: '측',
    쯘: '춘',
    쯙: '춘',
    쯚: '춘',
    쯛: '측',
    쯜: '출',
    쯝: '출',
    쯞: '출',
    쯟: '출',
    쯠: '출',
    쯡: '출',
    쯢: '출',
    쯣: '출',
    쯤: '층',
    쯥: '습',
    쯦: '습',
    쯧: '측',
    쯨: '측',
    쯩: '층',
    쯪: '측',
    쯫: '측',
    쯬: '측',
    쯭: '측',
    쯮: '습',
    쯯: '추',
    쯰: '치',
    쯱: '칙',
    쯲: '측',
    쯳: '측',
    쯴: '친',
    쯵: '친',
    쯶: '친',
    쯷: '측',
    쯸: '칠',
    쯹: '칠',
    쯺: '칠',
    쯻: '칠',
    쯼: '칠',
    쯽: '칠',
    쯾: '칠',
    쯿: '칠',
    찀: '침',
    찁: '집',
    찂: '집',
    찃: '칙',
    찄: '측',
    찅: '칭',
    찆: '측',
    찇: '측',
    찈: '측',
    찉: '측',
    찊: '집',
    찋: '치',
    찌: '치',
    찍: '칙',
    찎: '칙',
    찏: '칙',
    찐: '친',
    찑: '친',
    찒: '친',
    찓: '칙',
    찔: '칠',
    찕: '칠',
    찖: '칠',
    찗: '칠',
    찘: '칠',
    찙: '칠',
    찚: '칠',
    찛: '칠',
    찜: '침',
    찝: '집',
    찞: '집',
    찟: '칙',
    찠: '칙',
    찡: '칭',
    찢: '칙',
    찣: '칙',
    찤: '칙',
    찥: '칙',
    찦: '집',
    찧: '치',
    차: '차',
    착: '착',
    찪: '착',
    찫: '착',
    찬: '찬',
    찭: '찬',
    찮: '찬',
    찯: '착',
    찰: '찰',
    찱: '찰',
    찲: '찰',
    찳: '찰',
    찴: '찰',
    찵: '찰',
    찶: '찰',
    찷: '찰',
    참: '참',
    찹: '잡',
    찺: '참',
    찻: '착',
    찼: '착',
    창: '창',
    찾: '착',
    찿: '착',
    챀: '착',
    챁: '착',
    챂: '참',
    챃: '차',
    채: '채',
    책: '책',
    챆: '책',
    챇: '책',
    챈: '찬',
    챉: '찬',
    챊: '찬',
    챋: '책',
    챌: '찰',
    챍: '찰',
    챎: '찰',
    챏: '찰',
    챐: '찰',
    챑: '찰',
    챒: '찰',
    챓: '찰',
    챔: '참',
    챕: '잡',
    챖: '참',
    챗: '책',
    챘: '책',
    챙: '쟁',
    챚: '책',
    챛: '책',
    챜: '책',
    챝: '책',
    챞: '참',
    챟: '채',
    챠: '차',
    챡: '착',
    챢: '착',
    챣: '착',
    챤: '찬',
    챥: '찬',
    챦: '찬',
    챧: '착',
    챨: '찰',
    챩: '찰',
    챪: '찰',
    챫: '찰',
    챬: '찰',
    챭: '찰',
    챮: '찰',
    챯: '찰',
    챰: '참',
    챱: '잡',
    챲: '참',
    챳: '착',
    챴: '착',
    챵: '창',
    챶: '착',
    챷: '착',
    챸: '착',
    챹: '착',
    챺: '참',
    챻: '차',
    챼: '채',
    챽: '책',
    챾: '책',
    챿: '책',
    첀: '쟁',
    첁: '쟁',
    첂: '쟁',
    첃: '책',
    첄: '쟁',
    첅: '쟁',
    첆: '쟁',
    첇: '쟁',
    첈: '쟁',
    첉: '쟁',
    첊: '쟁',
    첋: '쟁',
    첌: '쟁',
    첍: '책',
    첎: '책',
    첏: '책',
    첐: '책',
    첑: '쟁',
    첒: '책',
    첓: '책',
    첔: '책',
    첕: '책',
    첖: '책',
    첗: '채',
    처: '처',
    척: '척',
    첚: '척',
    첛: '척',
    천: '천',
    첝: '천',
    첞: '천',
    첟: '척',
    철: '철',
    첡: '철',
    첢: '철',
    첣: '철',
    첤: '철',
    첥: '철',
    첦: '철',
    첧: '철',
    첨: '첨',
    첩: '첩',
    첪: '첩',
    첫: '척',
    첬: '척',
    청: '청',
    첮: '척',
    첯: '척',
    첰: '척',
    첱: '척',
    첲: '첩',
    첳: '처',
    체: '체',
    첵: '책',
    첶: '책',
    첷: '책',
    첸: '천',
    첹: '천',
    첺: '천',
    첻: '책',
    첼: '철',
    첽: '철',
    첾: '철',
    첿: '철',
    쳀: '철',
    쳁: '철',
    쳂: '철',
    쳃: '철',
    쳄: '첨',
    쳅: '첩',
    쳆: '첩',
    쳇: '책',
    쳈: '책',
    쳉: '쟁',
    쳊: '책',
    쳋: '책',
    쳌: '책',
    쳍: '책',
    쳎: '첩',
    쳏: '체',
    쳐: '체',
    쳑: '척',
    쳒: '척',
    쳓: '척',
    쳔: '천',
    쳕: '천',
    쳖: '천',
    쳗: '척',
    쳘: '철',
    쳙: '철',
    쳚: '철',
    쳛: '철',
    쳜: '철',
    쳝: '철',
    쳞: '철',
    쳟: '철',
    쳠: '첨',
    쳡: '첩',
    쳢: '첩',
    쳣: '척',
    쳤: '척',
    쳥: '청',
    쳦: '척',
    쳧: '척',
    쳨: '척',
    쳩: '척',
    쳪: '첩',
    쳫: '체',
    쳬: '체',
    쳭: '책',
    쳮: '책',
    쳯: '책',
    쳰: '현',
    쳱: '현',
    쳲: '현',
    쳳: '책',
    쳴: '혈',
    쳵: '혈',
    쳶: '혈',
    쳷: '혈',
    쳸: '혈',
    쳹: '혈',
    쳺: '혈',
    쳻: '혈',
    쳼: '쟁',
    쳽: '협',
    쳾: '협',
    쳿: '책',
    촀: '책',
    촁: '쟁',
    촂: '책',
    촃: '책',
    촄: '책',
    촅: '책',
    촆: '협',
    촇: '체',
    초: '초',
    촉: '촉',
    촊: '촉',
    촋: '촉',
    촌: '촌',
    촍: '촌',
    촎: '촌',
    촏: '촉',
    촐: '졸',
    촑: '졸',
    촒: '졸',
    촓: '졸',
    촔: '졸',
    촕: '졸',
    촖: '졸',
    촗: '졸',
    촘: '참',
    촙: '첩',
    촚: '첩',
    촛: '촉',
    촜: '촉',
    총: '총',
    촞: '촉',
    촟: '촉',
    촠: '촉',
    촡: '촉',
    촢: '첩',
    촣: '초',
    촤: '차',
    촥: '착',
    촦: '착',
    촧: '착',
    촨: '찬',
    촩: '찬',
    촪: '찬',
    촫: '착',
    촬: '찰',
    촭: '찰',
    촮: '찰',
    촯: '찰',
    촰: '찰',
    촱: '찰',
    촲: '찰',
    촳: '찰',
    촴: '참',
    촵: '잡',
    촶: '참',
    촷: '착',
    촸: '착',
    촹: '창',
    촺: '착',
    촻: '착',
    촼: '착',
    촽: '착',
    촾: '참',
    촿: '차',
    쵀: '최',
    쵁: '책',
    쵂: '촉',
    쵃: '촉',
    쵄: '촌',
    쵅: '촌',
    쵆: '촌',
    쵇: '촉',
    쵈: '졸',
    쵉: '졸',
    쵊: '졸',
    쵋: '졸',
    쵌: '졸',
    쵍: '졸',
    쵎: '졸',
    쵏: '졸',
    쵐: '첨',
    쵑: '첩',
    쵒: '첩',
    쵓: '촉',
    쵔: '촉',
    쵕: '총',
    쵖: '촉',
    쵗: '촉',
    쵘: '촉',
    쵙: '촉',
    쵚: '첩',
    쵛: '최',
    최: '최',
    쵝: '촉',
    쵞: '촉',
    쵟: '촉',
    쵠: '촌',
    쵡: '촌',
    쵢: '촌',
    쵣: '촉',
    쵤: '졸',
    쵥: '졸',
    쵦: '졸',
    쵧: '졸',
    쵨: '졸',
    쵩: '졸',
    쵪: '졸',
    쵫: '졸',
    쵬: '첨',
    쵭: '첩',
    쵮: '첩',
    쵯: '촉',
    쵰: '촉',
    쵱: '총',
    쵲: '촉',
    쵳: '촉',
    쵴: '촉',
    쵵: '촉',
    쵶: '첩',
    쵷: '최',
    쵸: '초',
    쵹: '촉',
    쵺: '촉',
    쵻: '촉',
    쵼: '촌',
    쵽: '촌',
    쵾: '촌',
    쵿: '촉',
    춀: '졸',
    춁: '졸',
    춂: '졸',
    춃: '졸',
    춄: '졸',
    춅: '졸',
    춆: '졸',
    춇: '졸',
    춈: '총',
    춉: '총',
    춊: '총',
    춋: '촉',
    춌: '촉',
    춍: '총',
    춎: '촉',
    춏: '촉',
    춐: '촉',
    춑: '촉',
    춒: '촉',
    춓: '초',
    추: '추',
    축: '축',
    춖: '축',
    춗: '축',
    춘: '춘',
    춙: '춘',
    춚: '춘',
    춛: '축',
    출: '출',
    춝: '출',
    춞: '출',
    춟: '출',
    춠: '출',
    춡: '출',
    춢: '출',
    춣: '출',
    춤: '충',
    춥: '충',
    춦: '충',
    춧: '축',
    춨: '축',
    충: '충',
    춪: '축',
    춫: '축',
    춬: '축',
    춭: '축',
    춮: '축',
    춯: '추',
    춰: '처',
    춱: '척',
    춲: '척',
    춳: '척',
    춴: '천',
    춵: '천',
    춶: '천',
    춷: '척',
    춸: '철',
    춹: '철',
    춺: '철',
    춻: '철',
    춼: '철',
    춽: '철',
    춾: '철',
    춿: '철',
    췀: '첨',
    췁: '첩',
    췂: '첩',
    췃: '척',
    췄: '척',
    췅: '청',
    췆: '척',
    췇: '척',
    췈: '척',
    췉: '척',
    췊: '첩',
    췋: '처',
    췌: '최',
    췍: '책',
    췎: '책',
    췏: '책',
    췐: '천',
    췑: '천',
    췒: '천',
    췓: '책',
    췔: '철',
    췕: '철',
    췖: '철',
    췗: '철',
    췘: '철',
    췙: '철',
    췚: '철',
    췛: '철',
    췜: '첨',
    췝: '첩',
    췞: '첩',
    췟: '책',
    췠: '책',
    췡: '최',
    췢: '책',
    췣: '책',
    췤: '책',
    췥: '책',
    췦: '첩',
    췧: '최',
    취: '취',
    췩: '축',
    췪: '축',
    췫: '축',
    췬: '춘',
    췭: '춘',
    췮: '춘',
    췯: '축',
    췰: '출',
    췱: '출',
    췲: '출',
    췳: '출',
    췴: '출',
    췵: '출',
    췶: '출',
    췷: '출',
    췸: '침',
    췹: '집',
    췺: '침',
    췻: '축',
    췼: '축',
    췽: '충',
    췾: '축',
    췿: '축',
    츀: '축',
    츁: '축',
    츂: '침',
    츃: '취',
    츄: '추',
    츅: '축',
    츆: '축',
    츇: '축',
    츈: '춘',
    츉: '춘',
    츊: '춘',
    츋: '축',
    츌: '출',
    츍: '출',
    츎: '출',
    츏: '출',
    츐: '출',
    츑: '출',
    츒: '출',
    츓: '출',
    츔: '충',
    츕: '충',
    츖: '충',
    츗: '축',
    츘: '축',
    츙: '충',
    츚: '축',
    츛: '축',
    츜: '축',
    츝: '축',
    츞: '축',
    츟: '추',
    츠: '추',
    측: '측',
    츢: '측',
    츣: '측',
    츤: '춘',
    츥: '춘',
    츦: '춘',
    츧: '측',
    츨: '출',
    츩: '출',
    츪: '출',
    츫: '출',
    츬: '출',
    츭: '출',
    츮: '출',
    츯: '출',
    츰: '층',
    츱: '흡',
    츲: '층',
    츳: '측',
    츴: '측',
    층: '층',
    츶: '측',
    츷: '측',
    츸: '측',
    츹: '측',
    츺: '흡',
    츻: '추',
    츼: '치',
    츽: '칙',
    츾: '측',
    츿: '측',
    칀: '친',
    칁: '친',
    칂: '친',
    칃: '측',
    칄: '칠',
    칅: '칠',
    칆: '칠',
    칇: '칠',
    칈: '칠',
    칉: '칠',
    칊: '칠',
    칋: '칠',
    칌: '침',
    칍: '집',
    칎: '침',
    칏: '측',
    칐: '측',
    칑: '칭',
    칒: '측',
    칓: '측',
    칔: '측',
    칕: '측',
    칖: '침',
    칗: '치',
    치: '치',
    칙: '칙',
    칚: '칙',
    칛: '칙',
    친: '친',
    칝: '친',
    칞: '친',
    칟: '칙',
    칠: '칠',
    칡: '칠',
    칢: '칠',
    칣: '칠',
    칤: '칠',
    칥: '칠',
    칦: '칠',
    칧: '칠',
    침: '침',
    칩: '집',
    칪: '침',
    칫: '칙',
    칬: '칙',
    칭: '칭',
    칮: '칙',
    칯: '칙',
    칰: '칙',
    칱: '칙',
    칲: '침',
    칳: '치',
    카: '가',
    칵: '각',
    칶: '각',
    칷: '각',
    칸: '간',
    칹: '간',
    칺: '간',
    칻: '각',
    칼: '갈',
    칽: '갈',
    칾: '갈',
    칿: '갈',
    캀: '갈',
    캁: '갈',
    캂: '갈',
    캃: '갈',
    캄: '감',
    캅: '갑',
    캆: '갑',
    캇: '각',
    캈: '각',
    캉: '강',
    캊: '각',
    캋: '각',
    캌: '각',
    캍: '각',
    캎: '갑',
    캏: '가',
    캐: '개',
    캑: '객',
    캒: '객',
    캓: '객',
    캔: '간',
    캕: '간',
    캖: '간',
    캗: '객',
    캘: '갈',
    캙: '갈',
    캚: '갈',
    캛: '갈',
    캜: '갈',
    캝: '갈',
    캞: '갈',
    캟: '갈',
    캠: '감',
    캡: '갑',
    캢: '갑',
    캣: '객',
    캤: '객',
    캥: '갱',
    캦: '객',
    캧: '객',
    캨: '객',
    캩: '객',
    캪: '갑',
    캫: '개',
    캬: '가',
    캭: '각',
    캮: '각',
    캯: '각',
    캰: '간',
    캱: '간',
    캲: '간',
    캳: '각',
    캴: '갈',
    캵: '갈',
    캶: '갈',
    캷: '갈',
    캸: '갈',
    캹: '갈',
    캺: '갈',
    캻: '갈',
    캼: '감',
    캽: '갑',
    캾: '갑',
    캿: '각',
    컀: '각',
    컁: '강',
    컂: '각',
    컃: '각',
    컄: '각',
    컅: '각',
    컆: '갑',
    컇: '가',
    컈: '개',
    컉: '객',
    컊: '객',
    컋: '객',
    컌: '갱',
    컍: '갱',
    컎: '갱',
    컏: '객',
    컐: '갱',
    컑: '갱',
    컒: '갱',
    컓: '갱',
    컔: '갱',
    컕: '갱',
    컖: '갱',
    컗: '갱',
    컘: '갱',
    컙: '갱',
    컚: '갱',
    컛: '객',
    컜: '객',
    컝: '갱',
    컞: '객',
    컟: '객',
    컠: '객',
    컡: '객',
    컢: '객',
    컣: '개',
    커: '거',
    컥: '격',
    컦: '격',
    컧: '격',
    컨: '건',
    컩: '건',
    컪: '건',
    컫: '격',
    컬: '걸',
    컭: '걸',
    컮: '걸',
    컯: '걸',
    컰: '걸',
    컱: '걸',
    컲: '걸',
    컳: '걸',
    컴: '검',
    컵: '검',
    컶: '검',
    컷: '격',
    컸: '격',
    컹: '경',
    컺: '격',
    컻: '격',
    컼: '격',
    컽: '격',
    컾: '검',
    컿: '거',
    케: '쾌',
    켁: '객',
    켂: '객',
    켃: '객',
    켄: '견',
    켅: '견',
    켆: '견',
    켇: '객',
    켈: '결',
    켉: '결',
    켊: '결',
    켋: '결',
    켌: '결',
    켍: '결',
    켎: '결',
    켏: '결',
    켐: '겸',
    켑: '겸',
    켒: '겸',
    켓: '객',
    켔: '객',
    켕: '갱',
    켖: '객',
    켗: '객',
    켘: '객',
    켙: '객',
    켚: '겸',
    켛: '쾌',
    켜: '계',
    켝: '격',
    켞: '격',
    켟: '격',
    켠: '견',
    켡: '견',
    켢: '견',
    켣: '격',
    켤: '결',
    켥: '결',
    켦: '결',
    켧: '결',
    켨: '결',
    켩: '결',
    켪: '결',
    켫: '결',
    켬: '겸',
    켭: '겸',
    켮: '겸',
    켯: '격',
    켰: '격',
    켱: '경',
    켲: '격',
    켳: '격',
    켴: '격',
    켵: '격',
    켶: '겸',
    켷: '계',
    켸: '계',
    켹: '격',
    켺: '격',
    켻: '격',
    켼: '견',
    켽: '견',
    켾: '견',
    켿: '격',
    콀: '결',
    콁: '결',
    콂: '결',
    콃: '결',
    콄: '결',
    콅: '결',
    콆: '결',
    콇: '결',
    콈: '겸',
    콉: '겸',
    콊: '겸',
    콋: '격',
    콌: '격',
    콍: '경',
    콎: '격',
    콏: '격',
    콐: '격',
    콑: '격',
    콒: '겸',
    콓: '계',
    코: '고',
    콕: '곡',
    콖: '곡',
    콗: '곡',
    콘: '곤',
    콙: '곤',
    콚: '곤',
    콛: '곡',
    콜: '골',
    콝: '골',
    콞: '골',
    콟: '골',
    콠: '골',
    콡: '골',
    콢: '골',
    콣: '골',
    콤: '감',
    콥: '갑',
    콦: '갑',
    콧: '곡',
    콨: '곡',
    콩: '공',
    콪: '곡',
    콫: '곡',
    콬: '곡',
    콭: '곡',
    콮: '갑',
    콯: '고',
    콰: '과',
    콱: '곽',
    콲: '곽',
    콳: '곽',
    콴: '관',
    콵: '관',
    콶: '관',
    콷: '곽',
    콸: '갈',
    콹: '갈',
    콺: '갈',
    콻: '갈',
    콼: '갈',
    콽: '갈',
    콾: '갈',
    콿: '갈',
    쾀: '감',
    쾁: '갑',
    쾂: '갑',
    쾃: '곽',
    쾄: '곽',
    쾅: '광',
    쾆: '곽',
    쾇: '곽',
    쾈: '곽',
    쾉: '곽',
    쾊: '갑',
    쾋: '과',
    쾌: '쾌',
    쾍: '객',
    쾎: '객',
    쾏: '객',
    쾐: '권',
    쾑: '권',
    쾒: '권',
    쾓: '객',
    쾔: '궐',
    쾕: '궐',
    쾖: '궐',
    쾗: '궐',
    쾘: '궐',
    쾙: '궐',
    쾚: '궐',
    쾛: '궐',
    쾜: '검',
    쾝: '검',
    쾞: '검',
    쾟: '객',
    쾠: '객',
    쾡: '쾌',
    쾢: '객',
    쾣: '객',
    쾤: '객',
    쾥: '객',
    쾦: '검',
    쾧: '쾌',
    쾨: '쾌',
    쾩: '곡',
    쾪: '곡',
    쾫: '곡',
    쾬: '곤',
    쾭: '곤',
    쾮: '곤',
    쾯: '곡',
    쾰: '골',
    쾱: '골',
    쾲: '골',
    쾳: '골',
    쾴: '골',
    쾵: '골',
    쾶: '골',
    쾷: '골',
    쾸: '검',
    쾹: '공',
    쾺: '공',
    쾻: '곡',
    쾼: '곡',
    쾽: '공',
    쾾: '곡',
    쾿: '곡',
    쿀: '곡',
    쿁: '곡',
    쿂: '검',
    쿃: '쾌',
    쿄: '교',
    쿅: '곡',
    쿆: '곡',
    쿇: '곡',
    쿈: '곤',
    쿉: '곤',
    쿊: '곤',
    쿋: '곡',
    쿌: '골',
    쿍: '골',
    쿎: '골',
    쿏: '골',
    쿐: '골',
    쿑: '골',
    쿒: '골',
    쿓: '골',
    쿔: '겸',
    쿕: '겸',
    쿖: '겸',
    쿗: '곡',
    쿘: '곡',
    쿙: '공',
    쿚: '곡',
    쿛: '곡',
    쿜: '곡',
    쿝: '곡',
    쿞: '겸',
    쿟: '교',
    쿠: '구',
    쿡: '국',
    쿢: '국',
    쿣: '국',
    쿤: '군',
    쿥: '군',
    쿦: '군',
    쿧: '국',
    쿨: '굴',
    쿩: '굴',
    쿪: '굴',
    쿫: '굴',
    쿬: '굴',
    쿭: '굴',
    쿮: '굴',
    쿯: '굴',
    쿰: '금',
    쿱: '급',
    쿲: '급',
    쿳: '국',
    쿴: '국',
    쿵: '궁',
    쿶: '국',
    쿷: '국',
    쿸: '국',
    쿹: '국',
    쿺: '급',
    쿻: '구',
    쿼: '쾌',
    쿽: '국',
    쿾: '국',
    쿿: '국',
    퀀: '권',
    퀁: '권',
    퀂: '권',
    퀃: '국',
    퀄: '궐',
    퀅: '궐',
    퀆: '궐',
    퀇: '궐',
    퀈: '궐',
    퀉: '궐',
    퀊: '궐',
    퀋: '궐',
    퀌: '검',
    퀍: '검',
    퀎: '검',
    퀏: '국',
    퀐: '국',
    퀑: '궁',
    퀒: '국',
    퀓: '국',
    퀔: '국',
    퀕: '국',
    퀖: '검',
    퀗: '쾌',
    퀘: '쾌',
    퀙: '곽',
    퀚: '곽',
    퀛: '곽',
    퀜: '권',
    퀝: '권',
    퀞: '권',
    퀟: '곽',
    퀠: '궐',
    퀡: '궐',
    퀢: '궐',
    퀣: '궐',
    퀤: '궐',
    퀥: '궐',
    퀦: '궐',
    퀧: '궐',
    퀨: '검',
    퀩: '검',
    퀪: '검',
    퀫: '곽',
    퀬: '곽',
    퀭: '광',
    퀮: '곽',
    퀯: '곽',
    퀰: '곽',
    퀱: '곽',
    퀲: '검',
    퀳: '쾌',
    퀴: '귀',
    퀵: '국',
    퀶: '국',
    퀷: '국',
    퀸: '군',
    퀹: '군',
    퀺: '군',
    퀻: '국',
    퀼: '굴',
    퀽: '굴',
    퀾: '굴',
    퀿: '굴',
    큀: '굴',
    큁: '굴',
    큂: '굴',
    큃: '굴',
    큄: '궁',
    큅: '궁',
    큆: '궁',
    큇: '국',
    큈: '국',
    큉: '궁',
    큊: '국',
    큋: '국',
    큌: '국',
    큍: '국',
    큎: '국',
    큏: '귀',
    큐: '규',
    큑: '국',
    큒: '국',
    큓: '국',
    큔: '균',
    큕: '균',
    큖: '균',
    큗: '국',
    큘: '굴',
    큙: '굴',
    큚: '굴',
    큛: '굴',
    큜: '굴',
    큝: '굴',
    큞: '굴',
    큟: '굴',
    큠: '궁',
    큡: '궁',
    큢: '궁',
    큣: '국',
    큤: '국',
    큥: '궁',
    큦: '국',
    큧: '국',
    큨: '국',
    큩: '국',
    큪: '국',
    큫: '규',
    크: '구',
    큭: '극',
    큮: '극',
    큯: '극',
    큰: '근',
    큱: '근',
    큲: '근',
    큳: '극',
    클: '굴',
    큵: '굴',
    큶: '굴',
    큷: '굴',
    큸: '굴',
    큹: '굴',
    큺: '굴',
    큻: '굴',
    큼: '금',
    큽: '급',
    큾: '급',
    큿: '극',
    킀: '극',
    킁: '긍',
    킂: '극',
    킃: '극',
    킄: '극',
    킅: '극',
    킆: '급',
    킇: '구',
    킈: '기',
    킉: '극',
    킊: '극',
    킋: '극',
    킌: '긴',
    킍: '긴',
    킎: '긴',
    킏: '극',
    킐: '길',
    킑: '길',
    킒: '길',
    킓: '길',
    킔: '길',
    킕: '길',
    킖: '길',
    킗: '길',
    킘: '금',
    킙: '급',
    킚: '급',
    킛: '극',
    킜: '극',
    킝: '긍',
    킞: '극',
    킟: '극',
    킠: '극',
    킡: '극',
    킢: '급',
    킣: '기',
    키: '기',
    킥: '객',
    킦: '객',
    킧: '객',
    킨: '긴',
    킩: '긴',
    킪: '긴',
    킫: '객',
    킬: '길',
    킭: '길',
    킮: '길',
    킯: '길',
    킰: '길',
    킱: '길',
    킲: '길',
    킳: '길',
    킴: '긴',
    킵: '갱',
    킶: '갱',
    킷: '객',
    킸: '객',
    킹: '갱',
    킺: '객',
    킻: '객',
    킼: '객',
    킽: '객',
    킾: '객',
    킿: '기',
    타: '타',
    탁: '탁',
    탂: '탁',
    탃: '탁',
    탄: '탄',
    탅: '탄',
    탆: '탄',
    탇: '탁',
    탈: '탈',
    탉: '탈',
    탊: '탈',
    탋: '탈',
    탌: '탈',
    탍: '탈',
    탎: '탈',
    탏: '탈',
    탐: '탐',
    탑: '탑',
    탒: '탑',
    탓: '탑',
    탔: '탁',
    탕: '탕',
    탖: '탁',
    탗: '탁',
    탘: '탁',
    탙: '탁',
    탚: '탑',
    탛: '타',
    태: '태',
    택: '택',
    탞: '택',
    탟: '택',
    탠: '탄',
    탡: '탄',
    탢: '탄',
    탣: '택',
    탤: '탈',
    탥: '탈',
    탦: '탈',
    탧: '탈',
    탨: '탈',
    탩: '탈',
    탪: '탈',
    탫: '탈',
    탬: '탐',
    탭: '탑',
    탮: '탑',
    탯: '택',
    탰: '택',
    탱: '탕',
    탲: '택',
    탳: '택',
    탴: '택',
    탵: '택',
    탶: '탑',
    탷: '태',
    탸: '타',
    탹: '탁',
    탺: '탁',
    탻: '탁',
    탼: '탄',
    탽: '탄',
    탾: '탄',
    탿: '탁',
    턀: '탈',
    턁: '탈',
    턂: '탈',
    턃: '탈',
    턄: '탈',
    턅: '탈',
    턆: '탈',
    턇: '탈',
    턈: '탐',
    턉: '탑',
    턊: '탑',
    턋: '탁',
    턌: '탁',
    턍: '탕',
    턎: '탁',
    턏: '탁',
    턐: '탁',
    턑: '탁',
    턒: '탑',
    턓: '타',
    턔: '태',
    턕: '택',
    턖: '택',
    턗: '택',
    턘: '갱',
    턙: '갱',
    턚: '갱',
    턛: '택',
    턜: '갱',
    턝: '갱',
    턞: '갱',
    턟: '갱',
    턠: '갱',
    턡: '갱',
    턢: '갱',
    턣: '갱',
    턤: '택',
    턥: '택',
    턦: '택',
    턧: '택',
    턨: '택',
    턩: '태',
    턪: '택',
    턫: '택',
    턬: '택',
    턭: '택',
    턮: '택',
    턯: '태',
    터: '토',
    턱: '덕',
    턲: '덕',
    턳: '덕',
    턴: '탄',
    턵: '탄',
    턶: '탄',
    턷: '덕',
    털: '탈',
    턹: '탈',
    턺: '탈',
    턻: '탈',
    턼: '탈',
    턽: '탈',
    턾: '탈',
    턿: '탈',
    텀: '탐',
    텁: '탑',
    텂: '탑',
    텃: '덕',
    텄: '덕',
    텅: '탕',
    텆: '덕',
    텇: '덕',
    텈: '덕',
    텉: '덕',
    텊: '탑',
    텋: '타',
    테: '태',
    텍: '택',
    텎: '택',
    텏: '택',
    텐: '견',
    텑: '견',
    텒: '견',
    텓: '택',
    텔: '결',
    텕: '결',
    텖: '결',
    텗: '결',
    텘: '결',
    텙: '결',
    텚: '결',
    텛: '결',
    템: '택',
    텝: '택',
    텞: '택',
    텟: '택',
    텠: '택',
    텡: '태',
    텢: '택',
    텣: '택',
    텤: '택',
    텥: '택',
    텦: '택',
    텧: '태',
    텨: '녀',
    텩: '덕',
    텪: '덕',
    텫: '덕',
    텬: '견',
    텭: '견',
    텮: '견',
    텯: '덕',
    텰: '결',
    텱: '결',
    텲: '결',
    텳: '결',
    텴: '결',
    텵: '결',
    텶: '결',
    텷: '결',
    텸: '겸',
    텹: '겸',
    텺: '겸',
    텻: '덕',
    텼: '덕',
    텽: '경',
    텾: '덕',
    텿: '덕',
    톀: '덕',
    톁: '덕',
    톂: '겸',
    톃: '녀',
    톄: '태',
    톅: '택',
    톆: '택',
    톇: '택',
    톈: '견',
    톉: '견',
    톊: '견',
    톋: '택',
    톌: '결',
    톍: '결',
    톎: '결',
    톏: '결',
    톐: '결',
    톑: '결',
    톒: '결',
    톓: '결',
    톔: '겸',
    톕: '택',
    톖: '택',
    톗: '택',
    톘: '택',
    톙: '태',
    톚: '택',
    톛: '택',
    톜: '택',
    톝: '택',
    톞: '택',
    톟: '태',
    토: '토',
    톡: '독',
    톢: '독',
    톣: '독',
    톤: '돈',
    톥: '돈',
    톦: '돈',
    톧: '독',
    톨: '돌',
    톩: '돌',
    톪: '돌',
    톫: '돌',
    톬: '돌',
    톭: '돌',
    톮: '돌',
    톯: '돌',
    톰: '탐',
    톱: '탑',
    톲: '탑',
    톳: '독',
    톴: '독',
    통: '통',
    톶: '독',
    톷: '독',
    톸: '독',
    톹: '독',
    톺: '탑',
    톻: '토',
    톼: '타',
    톽: '탁',
    톾: '탁',
    톿: '탁',
    퇀: '탄',
    퇁: '탄',
    퇂: '탄',
    퇃: '탁',
    퇄: '탈',
    퇅: '탈',
    퇆: '탈',
    퇇: '탈',
    퇈: '탈',
    퇉: '탈',
    퇊: '탈',
    퇋: '탈',
    퇌: '탐',
    퇍: '탑',
    퇎: '탑',
    퇏: '탁',
    퇐: '탁',
    퇑: '탕',
    퇒: '탁',
    퇓: '탁',
    퇔: '탁',
    퇕: '탁',
    퇖: '탑',
    퇗: '타',
    퇘: '퇴',
    퇙: '택',
    퇚: '택',
    퇛: '택',
    퇜: '돈',
    퇝: '돈',
    퇞: '돈',
    퇟: '택',
    퇠: '돌',
    퇡: '돌',
    퇢: '돌',
    퇣: '돌',
    퇤: '돌',
    퇥: '돌',
    퇦: '돌',
    퇧: '돌',
    퇨: '통',
    퇩: '통',
    퇪: '통',
    퇫: '택',
    퇬: '택',
    퇭: '통',
    퇮: '택',
    퇯: '택',
    퇰: '택',
    퇱: '택',
    퇲: '택',
    퇳: '퇴',
    퇴: '퇴',
    퇵: '택',
    퇶: '택',
    퇷: '택',
    퇸: '돈',
    퇹: '돈',
    퇺: '돈',
    퇻: '택',
    퇼: '돌',
    퇽: '돌',
    퇾: '돌',
    퇿: '돌',
    툀: '돌',
    툁: '돌',
    툂: '돌',
    툃: '돌',
    툄: '통',
    툅: '통',
    툆: '통',
    툇: '택',
    툈: '택',
    툉: '통',
    툊: '택',
    툋: '택',
    툌: '택',
    툍: '택',
    툎: '택',
    툏: '퇴',
    툐: '토',
    툑: '독',
    툒: '독',
    툓: '독',
    툔: '돈',
    툕: '돈',
    툖: '돈',
    툗: '독',
    툘: '돌',
    툙: '돌',
    툚: '돌',
    툛: '돌',
    툜: '돌',
    툝: '돌',
    툞: '돌',
    툟: '돌',
    툠: '통',
    툡: '통',
    툢: '통',
    툣: '독',
    툤: '독',
    툥: '통',
    툦: '독',
    툧: '독',
    툨: '독',
    툩: '독',
    툪: '독',
    툫: '토',
    투: '투',
    툭: '특',
    툮: '특',
    툯: '특',
    툰: '둔',
    툱: '둔',
    툲: '둔',
    툳: '특',
    툴: '둔',
    툵: '둔',
    툶: '둔',
    툷: '둔',
    툸: '둔',
    툹: '둔',
    툺: '둔',
    툻: '둔',
    툼: '둔',
    툽: '특',
    툾: '특',
    툿: '특',
    퉀: '특',
    퉁: '등',
    퉂: '특',
    퉃: '특',
    퉄: '특',
    퉅: '특',
    퉆: '특',
    퉇: '투',
    퉈: '투',
    퉉: '덕',
    퉊: '덕',
    퉋: '덕',
    퉌: '둔',
    퉍: '둔',
    퉎: '둔',
    퉏: '덕',
    퉐: '궐',
    퉑: '궐',
    퉒: '궐',
    퉓: '궐',
    퉔: '궐',
    퉕: '궐',
    퉖: '궐',
    퉗: '궐',
    퉘: '검',
    퉙: '덕',
    퉚: '덕',
    퉛: '덕',
    퉜: '덕',
    퉝: '투',
    퉞: '덕',
    퉟: '덕',
    퉠: '덕',
    퉡: '덕',
    퉢: '덕',
    퉣: '투',
    퉤: '퇴',
    퉥: '택',
    퉦: '택',
    퉧: '택',
    퉨: '권',
    퉩: '권',
    퉪: '권',
    퉫: '택',
    퉬: '궐',
    퉭: '궐',
    퉮: '궐',
    퉯: '궐',
    퉰: '궐',
    퉱: '궐',
    퉲: '궐',
    퉳: '궐',
    퉴: '택',
    퉵: '택',
    퉶: '택',
    퉷: '택',
    퉸: '택',
    퉹: '퇴',
    퉺: '택',
    퉻: '택',
    퉼: '택',
    퉽: '택',
    퉾: '택',
    퉿: '퇴',
    튀: '투',
    튁: '국',
    튂: '국',
    튃: '국',
    튄: '둔',
    튅: '둔',
    튆: '둔',
    튇: '국',
    튈: '둔',
    튉: '둔',
    튊: '둔',
    튋: '둔',
    튌: '둔',
    튍: '둔',
    튎: '둔',
    튏: '둔',
    튐: '둔',
    튑: '궁',
    튒: '궁',
    튓: '국',
    튔: '국',
    튕: '투',
    튖: '국',
    튗: '국',
    튘: '국',
    튙: '국',
    튚: '국',
    튛: '투',
    튜: '투',
    튝: '국',
    튞: '국',
    튟: '국',
    튠: '둔',
    튡: '둔',
    튢: '둔',
    튣: '국',
    튤: '둔',
    튥: '둔',
    튦: '둔',
    튧: '둔',
    튨: '둔',
    튩: '둔',
    튪: '둔',
    튫: '둔',
    튬: '둔',
    튭: '궁',
    튮: '궁',
    튯: '국',
    튰: '국',
    튱: '투',
    튲: '국',
    튳: '국',
    튴: '국',
    튵: '국',
    튶: '국',
    튷: '투',
    트: '투',
    특: '특',
    튺: '특',
    튻: '특',
    튼: '둔',
    튽: '둔',
    튾: '둔',
    튿: '특',
    틀: '둔',
    틁: '둔',
    틂: '둔',
    틃: '둔',
    틄: '둔',
    틅: '둔',
    틆: '둔',
    틇: '둔',
    틈: '등',
    틉: '특',
    틊: '특',
    틋: '특',
    틌: '특',
    틍: '등',
    틎: '특',
    틏: '특',
    틐: '특',
    틑: '특',
    틒: '특',
    틓: '투',
    틔: '투',
    틕: '특',
    틖: '특',
    틗: '특',
    틘: '둔',
    틙: '둔',
    틚: '둔',
    틛: '특',
    틜: '둔',
    틝: '둔',
    틞: '둔',
    틟: '둔',
    틠: '둔',
    틡: '둔',
    틢: '둔',
    틣: '둔',
    틤: '등',
    틥: '특',
    틦: '특',
    틧: '특',
    틨: '특',
    틩: '등',
    틪: '특',
    틫: '특',
    틬: '특',
    틭: '특',
    틮: '특',
    틯: '투',
    티: '태',
    틱: '택',
    틲: '택',
    틳: '택',
    틴: '긴',
    틵: '긴',
    틶: '긴',
    틷: '택',
    틸: '길',
    틹: '길',
    틺: '길',
    틻: '길',
    틼: '길',
    틽: '길',
    틾: '길',
    틿: '길',
    팀: '택',
    팁: '택',
    팂: '택',
    팃: '택',
    팄: '택',
    팅: '태',
    팆: '택',
    팇: '택',
    팈: '택',
    팉: '택',
    팊: '택',
    팋: '태',
    파: '파',
    팍: '박',
    팎: '박',
    팏: '박',
    판: '판',
    팑: '판',
    팒: '판',
    팓: '박',
    팔: '팔',
    팕: '팔',
    팖: '팔',
    팗: '팔',
    팘: '팔',
    팙: '팔',
    팚: '팔',
    팛: '팔',
    팜: '판',
    팝: '법',
    팞: '법',
    팟: '박',
    팠: '박',
    팡: '방',
    팢: '박',
    팣: '박',
    팤: '박',
    팥: '박',
    팦: '법',
    팧: '파',
    패: '패',
    팩: '백',
    팪: '백',
    팫: '백',
    팬: '판',
    팭: '판',
    팮: '판',
    팯: '백',
    팰: '팔',
    팱: '팔',
    팲: '팔',
    팳: '팔',
    팴: '팔',
    팵: '팔',
    팶: '팔',
    팷: '팔',
    팸: '판',
    팹: '합',
    팺: '백',
    팻: '백',
    팼: '백',
    팽: '방',
    팾: '백',
    팿: '백',
    퍀: '백',
    퍁: '백',
    퍂: '백',
    퍃: '패',
    퍄: '파',
    퍅: '박',
    퍆: '박',
    퍇: '박',
    퍈: '판',
    퍉: '판',
    퍊: '판',
    퍋: '박',
    퍌: '팔',
    퍍: '팔',
    퍎: '팔',
    퍏: '팔',
    퍐: '팔',
    퍑: '팔',
    퍒: '팔',
    퍓: '팔',
    퍔: '판',
    퍕: '합',
    퍖: '합',
    퍗: '박',
    퍘: '박',
    퍙: '방',
    퍚: '박',
    퍛: '박',
    퍜: '박',
    퍝: '박',
    퍞: '합',
    퍟: '파',
    퍠: '패',
    퍡: '백',
    퍢: '백',
    퍣: '백',
    퍤: '행',
    퍥: '행',
    퍦: '행',
    퍧: '백',
    퍨: '행',
    퍩: '행',
    퍪: '행',
    퍫: '행',
    퍬: '행',
    퍭: '행',
    퍮: '행',
    퍯: '행',
    퍰: '백',
    퍱: '백',
    퍲: '백',
    퍳: '백',
    퍴: '백',
    퍵: '패',
    퍶: '백',
    퍷: '백',
    퍸: '백',
    퍹: '백',
    퍺: '백',
    퍻: '패',
    퍼: '파',
    퍽: '폭',
    퍾: '폭',
    퍿: '폭',
    펀: '번',
    펁: '번',
    펂: '번',
    펃: '폭',
    펄: '벌',
    펅: '벌',
    펆: '벌',
    펇: '벌',
    펈: '벌',
    펉: '벌',
    펊: '벌',
    펋: '벌',
    펌: '범',
    펍: '법',
    펎: '법',
    펏: '법',
    펐: '폭',
    펑: '평',
    펒: '폭',
    펓: '폭',
    펔: '폭',
    펕: '폭',
    펖: '법',
    펗: '파',
    페: '폐',
    펙: '백',
    펚: '백',
    펛: '백',
    펜: '편',
    펝: '편',
    펞: '편',
    펟: '백',
    펠: '별',
    펡: '별',
    펢: '별',
    펣: '별',
    펤: '별',
    펥: '별',
    펦: '별',
    펧: '별',
    펨: '범',
    펩: '법',
    펪: '법',
    펫: '백',
    펬: '백',
    펭: '평',
    펮: '백',
    펯: '백',
    펰: '백',
    펱: '백',
    펲: '법',
    펳: '폐',
    펴: '폐',
    펵: '벽',
    펶: '벽',
    펷: '벽',
    편: '편',
    펹: '편',
    펺: '편',
    펻: '벽',
    펼: '별',
    펽: '별',
    펾: '별',
    펿: '별',
    폀: '별',
    폁: '별',
    폂: '별',
    폃: '별',
    폄: '평',
    폅: '법',
    폆: '법',
    폇: '벽',
    폈: '벽',
    평: '평',
    폊: '벽',
    폋: '벽',
    폌: '벽',
    폍: '벽',
    폎: '법',
    폏: '폐',
    폐: '폐',
    폑: '벽',
    폒: '벽',
    폓: '벽',
    폔: '편',
    폕: '편',
    폖: '편',
    폗: '벽',
    폘: '별',
    폙: '별',
    폚: '별',
    폛: '별',
    폜: '별',
    폝: '별',
    폞: '별',
    폟: '별',
    폠: '평',
    폡: '협',
    폢: '평',
    폣: '벽',
    폤: '벽',
    폥: '평',
    폦: '벽',
    폧: '벽',
    폨: '벽',
    폩: '벽',
    폪: '협',
    폫: '폐',
    포: '포',
    폭: '폭',
    폮: '폭',
    폯: '폭',
    폰: '본',
    폱: '본',
    폲: '본',
    폳: '폭',
    폴: '팔',
    폵: '팔',
    폶: '팔',
    폷: '팔',
    폸: '팔',
    폹: '팔',
    폺: '팔',
    폻: '팔',
    폼: '범',
    폽: '법',
    폾: '법',
    폿: '폭',
    퐀: '폭',
    퐁: '봉',
    퐂: '폭',
    퐃: '폭',
    퐄: '폭',
    퐅: '폭',
    퐆: '법',
    퐇: '포',
    퐈: '파',
    퐉: '박',
    퐊: '박',
    퐋: '박',
    퐌: '판',
    퐍: '판',
    퐎: '판',
    퐏: '박',
    퐐: '팔',
    퐑: '팔',
    퐒: '팔',
    퐓: '팔',
    퐔: '팔',
    퐕: '팔',
    퐖: '팔',
    퐗: '팔',
    퐘: '판',
    퐙: '합',
    퐚: '합',
    퐛: '박',
    퐜: '박',
    퐝: '방',
    퐞: '박',
    퐟: '박',
    퐠: '박',
    퐡: '박',
    퐢: '합',
    퐣: '파',
    퐤: '패',
    퐥: '폭',
    퐦: '폭',
    퐧: '폭',
    퐨: '본',
    퐩: '본',
    퐪: '본',
    퐫: '폭',
    퐬: '벌',
    퐭: '벌',
    퐮: '벌',
    퐯: '벌',
    퐰: '벌',
    퐱: '벌',
    퐲: '벌',
    퐳: '벌',
    퐴: '범',
    퐵: '법',
    퐶: '법',
    퐷: '폭',
    퐸: '폭',
    퐹: '봉',
    퐺: '폭',
    퐻: '폭',
    퐼: '폭',
    퐽: '폭',
    퐾: '법',
    퐿: '패',
    푀: '포',
    푁: '폭',
    푂: '폭',
    푃: '폭',
    푄: '본',
    푅: '본',
    푆: '본',
    푇: '폭',
    푈: '본',
    푉: '본',
    푊: '본',
    푋: '본',
    푌: '본',
    푍: '본',
    푎: '본',
    푏: '본',
    푐: '범',
    푑: '법',
    푒: '법',
    푓: '폭',
    푔: '폭',
    푕: '봉',
    푖: '폭',
    푗: '폭',
    푘: '폭',
    푙: '폭',
    푚: '법',
    푛: '포',
    표: '표',
    푝: '폭',
    푞: '폭',
    푟: '폭',
    푠: '본',
    푡: '본',
    푢: '본',
    푣: '폭',
    푤: '별',
    푥: '별',
    푦: '별',
    푧: '별',
    푨: '별',
    푩: '별',
    푪: '별',
    푫: '별',
    푬: '봉',
    푭: '폭',
    푮: '폭',
    푯: '폭',
    푰: '폭',
    푱: '봉',
    푲: '폭',
    푳: '폭',
    푴: '폭',
    푵: '폭',
    푶: '폭',
    푷: '표',
    푸: '부',
    푹: '북',
    푺: '북',
    푻: '북',
    푼: '분',
    푽: '분',
    푾: '분',
    푿: '북',
    풀: '불',
    풁: '불',
    풂: '불',
    풃: '불',
    풄: '불',
    풅: '불',
    풆: '불',
    풇: '불',
    품: '품',
    풉: '품',
    풊: '품',
    풋: '북',
    풌: '북',
    풍: '풍',
    풎: '북',
    풏: '북',
    풐: '북',
    풑: '북',
    풒: '품',
    풓: '부',
    풔: '부',
    풕: '북',
    풖: '북',
    풗: '북',
    풘: '번',
    풙: '번',
    풚: '번',
    풛: '북',
    풜: '벌',
    풝: '벌',
    풞: '벌',
    풟: '벌',
    풠: '벌',
    풡: '벌',
    풢: '벌',
    풣: '벌',
    풤: '품',
    풥: '법',
    풦: '법',
    풧: '법',
    풨: '북',
    풩: '풍',
    풪: '북',
    풫: '북',
    풬: '북',
    풭: '북',
    풮: '법',
    풯: '부',
    풰: '폐',
    풱: '백',
    풲: '백',
    풳: '백',
    풴: '번',
    풵: '번',
    풶: '번',
    풷: '백',
    풸: '벌',
    풹: '벌',
    풺: '벌',
    풻: '벌',
    풼: '벌',
    풽: '벌',
    풾: '벌',
    풿: '벌',
    퓀: '범',
    퓁: '법',
    퓂: '법',
    퓃: '백',
    퓄: '백',
    퓅: '횡',
    퓆: '백',
    퓇: '백',
    퓈: '백',
    퓉: '백',
    퓊: '법',
    퓋: '폐',
    퓌: '피',
    퓍: '북',
    퓎: '북',
    퓏: '북',
    퓐: '분',
    퓑: '분',
    퓒: '분',
    퓓: '북',
    퓔: '필',
    퓕: '필',
    퓖: '필',
    퓗: '필',
    퓘: '필',
    퓙: '필',
    퓚: '필',
    퓛: '필',
    퓜: '품',
    퓝: '품',
    퓞: '품',
    퓟: '북',
    퓠: '북',
    퓡: '풍',
    퓢: '북',
    퓣: '북',
    퓤: '북',
    퓥: '북',
    퓦: '품',
    퓧: '피',
    퓨: '부',
    퓩: '북',
    퓪: '북',
    퓫: '북',
    퓬: '분',
    퓭: '분',
    퓮: '분',
    퓯: '북',
    퓰: '불',
    퓱: '불',
    퓲: '불',
    퓳: '불',
    퓴: '불',
    퓵: '불',
    퓶: '불',
    퓷: '불',
    퓸: '품',
    퓹: '품',
    퓺: '품',
    퓻: '북',
    퓼: '북',
    퓽: '풍',
    퓾: '북',
    퓿: '북',
    픀: '북',
    픁: '북',
    픂: '품',
    픃: '부',
    프: '부',
    픅: '북',
    픆: '북',
    픇: '북',
    픈: '분',
    픉: '분',
    픊: '분',
    픋: '북',
    플: '불',
    픍: '불',
    픎: '불',
    픏: '불',
    픐: '불',
    픑: '불',
    픒: '불',
    픓: '불',
    픔: '품',
    픕: '품',
    픖: '품',
    픗: '북',
    픘: '북',
    픙: '풍',
    픚: '북',
    픛: '북',
    픜: '북',
    픝: '북',
    픞: '품',
    픟: '부',
    픠: '피',
    픡: '북',
    픢: '북',
    픣: '북',
    픤: '빈',
    픥: '빈',
    픦: '빈',
    픧: '북',
    픨: '필',
    픩: '필',
    픪: '필',
    픫: '필',
    픬: '필',
    픭: '필',
    픮: '필',
    픯: '필',
    픰: '품',
    픱: '품',
    픲: '품',
    픳: '북',
    픴: '북',
    픵: '풍',
    픶: '북',
    픷: '북',
    픸: '북',
    픹: '북',
    픺: '품',
    픻: '피',
    피: '피',
    픽: '백',
    픾: '백',
    픿: '백',
    핀: '빈',
    핁: '빈',
    핂: '빈',
    핃: '백',
    필: '필',
    핅: '필',
    핆: '필',
    핇: '필',
    핈: '필',
    핉: '필',
    핊: '필',
    핋: '필',
    핌: '빙',
    핍: '빙',
    핎: '빙',
    핏: '백',
    핐: '백',
    핑: '빙',
    핒: '백',
    핓: '백',
    핔: '백',
    핕: '백',
    핖: '백',
    핗: '피',
    하: '하',
    학: '학',
    핚: '학',
    핛: '학',
    한: '한',
    핝: '한',
    핞: '한',
    핟: '학',
    할: '할',
    핡: '할',
    핢: '할',
    핣: '할',
    핤: '할',
    핥: '할',
    핦: '할',
    핧: '할',
    함: '함',
    합: '합',
    핪: '합',
    핫: '합',
    핬: '학',
    항: '항',
    핮: '학',
    핯: '학',
    핰: '학',
    핱: '학',
    핲: '합',
    핳: '하',
    해: '해',
    핵: '핵',
    핶: '핵',
    핷: '핵',
    핸: '한',
    핹: '한',
    핺: '한',
    핻: '핵',
    핼: '할',
    핽: '할',
    핾: '할',
    핿: '할',
    햀: '할',
    햁: '할',
    햂: '할',
    햃: '할',
    햄: '함',
    햅: '합',
    햆: '합',
    햇: '핵',
    했: '핵',
    행: '행',
    햊: '핵',
    햋: '핵',
    햌: '핵',
    햍: '핵',
    햎: '합',
    햏: '해',
    햐: '하',
    햑: '학',
    햒: '학',
    햓: '학',
    햔: '한',
    햕: '한',
    햖: '한',
    햗: '학',
    햘: '할',
    햙: '할',
    햚: '할',
    햛: '할',
    햜: '할',
    햝: '할',
    햞: '할',
    햟: '할',
    햠: '함',
    햡: '합',
    햢: '합',
    햣: '학',
    햤: '학',
    향: '향',
    햦: '학',
    햧: '학',
    햨: '학',
    햩: '학',
    햪: '합',
    햫: '하',
    햬: '혜',
    햭: '핵',
    햮: '핵',
    햯: '핵',
    햰: '행',
    햱: '행',
    햲: '행',
    햳: '핵',
    햴: '행',
    햵: '행',
    햶: '행',
    햷: '행',
    햸: '행',
    햹: '행',
    햺: '행',
    햻: '행',
    햼: '행',
    햽: '행',
    햾: '행',
    햿: '핵',
    헀: '핵',
    헁: '행',
    헂: '핵',
    헃: '핵',
    헄: '핵',
    헅: '핵',
    헆: '핵',
    헇: '혜',
    허: '허',
    헉: '억',
    헊: '억',
    헋: '억',
    헌: '헌',
    헍: '헌',
    헎: '헌',
    헏: '억',
    헐: '혈',
    헑: '헌',
    헒: '헌',
    헓: '헌',
    헔: '헌',
    헕: '헌',
    헖: '헌',
    헗: '헌',
    험: '험',
    헙: '험',
    헚: '험',
    헛: '업',
    헜: '억',
    헝: '형',
    헞: '억',
    헟: '억',
    헠: '억',
    헡: '억',
    헢: '험',
    헣: '허',
    헤: '혜',
    헥: '핵',
    헦: '핵',
    헧: '핵',
    헨: '현',
    헩: '현',
    헪: '현',
    헫: '핵',
    헬: '혈',
    헭: '혈',
    헮: '혈',
    헯: '혈',
    헰: '혈',
    헱: '혈',
    헲: '혈',
    헳: '혈',
    헴: '험',
    헵: '협',
    헶: '협',
    헷: '핵',
    헸: '핵',
    헹: '행',
    헺: '핵',
    헻: '핵',
    헼: '핵',
    헽: '핵',
    헾: '협',
    헿: '혜',
    혀: '혜',
    혁: '혁',
    혂: '혁',
    혃: '혁',
    현: '현',
    혅: '현',
    혆: '현',
    혇: '혁',
    혈: '혈',
    혉: '혈',
    혊: '혈',
    혋: '혈',
    혌: '혈',
    혍: '혈',
    혎: '혈',
    혏: '혈',
    혐: '협',
    협: '협',
    혒: '협',
    혓: '협',
    혔: '혁',
    형: '형',
    혖: '혁',
    혗: '혁',
    혘: '혁',
    혙: '혁',
    혚: '협',
    혛: '혜',
    혜: '혜',
    혝: '혁',
    혞: '혁',
    혟: '혁',
    혠: '현',
    혡: '현',
    혢: '현',
    혣: '혁',
    혤: '혈',
    혥: '혈',
    혦: '혈',
    혧: '혈',
    혨: '혈',
    혩: '혈',
    혪: '혈',
    혫: '혈',
    혬: '협',
    혭: '협',
    혮: '협',
    혯: '협',
    혰: '혁',
    혱: '형',
    혲: '혁',
    혳: '혁',
    혴: '혁',
    혵: '혁',
    혶: '협',
    혷: '혜',
    호: '호',
    혹: '혹',
    혺: '혹',
    혻: '혹',
    혼: '혼',
    혽: '혼',
    혾: '혼',
    혿: '혹',
    홀: '홀',
    홁: '홀',
    홂: '홀',
    홃: '홀',
    홄: '홀',
    홅: '홀',
    홆: '홀',
    홇: '홀',
    홈: '함',
    홉: '합',
    홊: '합',
    홋: '혹',
    홌: '혹',
    홍: '홍',
    홎: '혹',
    홏: '혹',
    홐: '혹',
    홑: '혹',
    홒: '합',
    홓: '호',
    화: '화',
    확: '확',
    홖: '확',
    홗: '확',
    환: '환',
    홙: '환',
    홚: '환',
    홛: '확',
    활: '활',
    홝: '활',
    홞: '활',
    홟: '활',
    홠: '활',
    홡: '활',
    홢: '활',
    홣: '활',
    홤: '함',
    홥: '합',
    홦: '합',
    홧: '확',
    홨: '확',
    황: '황',
    홪: '확',
    홫: '확',
    홬: '확',
    홭: '확',
    홮: '합',
    홯: '화',
    홰: '회',
    홱: '획',
    홲: '획',
    홳: '획',
    홴: '환',
    홵: '환',
    홶: '환',
    홷: '획',
    홸: '활',
    홹: '활',
    홺: '활',
    홻: '활',
    홼: '활',
    홽: '활',
    홾: '활',
    홿: '활',
    횀: '횡',
    횁: '횡',
    횂: '횡',
    횃: '획',
    횄: '획',
    횅: '횡',
    횆: '획',
    횇: '획',
    횈: '획',
    횉: '획',
    횊: '획',
    횋: '회',
    회: '회',
    획: '획',
    횎: '획',
    횏: '획',
    횐: '혼',
    횑: '혼',
    횒: '혼',
    횓: '획',
    횔: '홀',
    횕: '홀',
    횖: '홀',
    횗: '홀',
    횘: '홀',
    횙: '홀',
    횚: '홀',
    횛: '홀',
    횜: '횡',
    횝: '횡',
    횞: '횡',
    횟: '획',
    횠: '획',
    횡: '횡',
    횢: '획',
    횣: '획',
    횤: '획',
    횥: '획',
    횦: '획',
    횧: '회',
    효: '효',
    횩: '혹',
    횪: '혹',
    횫: '혹',
    횬: '혼',
    횭: '혼',
    횮: '혼',
    횯: '혹',
    횰: '홀',
    횱: '홀',
    횲: '홀',
    횳: '홀',
    횴: '홀',
    횵: '홀',
    횶: '홀',
    횷: '홀',
    횸: '홍',
    횹: '협',
    횺: '협',
    횻: '혹',
    횼: '혹',
    횽: '홍',
    횾: '혹',
    횿: '혹',
    훀: '혹',
    훁: '혹',
    훂: '협',
    훃: '효',
    후: '후',
    훅: '흑',
    훆: '흑',
    훇: '흑',
    훈: '훈',
    훉: '훈',
    훊: '훈',
    훋: '흑',
    훌: '훈',
    훍: '훈',
    훎: '훈',
    훏: '훈',
    훐: '훈',
    훑: '훈',
    훒: '훈',
    훓: '훈',
    훔: '흡',
    훕: '흡',
    훖: '흡',
    훗: '흡',
    훘: '흑',
    훙: '흉',
    훚: '흑',
    훛: '흑',
    훜: '흑',
    훝: '흑',
    훞: '흡',
    훟: '후',
    훠: '허',
    훡: '획',
    훢: '획',
    훣: '획',
    훤: '헌',
    훥: '헌',
    훦: '헌',
    훧: '획',
    훨: '월',
    훩: '월',
    훪: '월',
    훫: '월',
    훬: '월',
    훭: '월',
    훮: '월',
    훯: '월',
    훰: '험',
    훱: '험',
    훲: '험',
    훳: '획',
    훴: '획',
    훵: '횡',
    훶: '획',
    훷: '획',
    훸: '획',
    훹: '획',
    훺: '험',
    훻: '허',
    훼: '훼',
    훽: '획',
    훾: '획',
    훿: '획',
    휀: '환',
    휁: '환',
    휂: '환',
    휃: '획',
    휄: '활',
    휅: '활',
    휆: '활',
    휇: '활',
    휈: '활',
    휉: '활',
    휊: '활',
    휋: '활',
    휌: '횡',
    휍: '횡',
    휎: '횡',
    휏: '획',
    휐: '획',
    휑: '횡',
    휒: '획',
    휓: '획',
    휔: '획',
    휕: '획',
    휖: '획',
    휗: '훼',
    휘: '휘',
    휙: '익',
    휚: '육',
    휛: '육',
    휜: '훈',
    휝: '훈',
    휞: '훈',
    휟: '육',
    휠: '훈',
    휡: '훈',
    휢: '훈',
    휣: '훈',
    휤: '훈',
    휥: '훈',
    휦: '훈',
    휧: '훈',
    휨: '임',
    휩: '입',
    휪: '입',
    휫: '육',
    휬: '육',
    휭: '흉',
    휮: '육',
    휯: '육',
    휰: '육',
    휱: '육',
    휲: '입',
    휳: '휘',
    휴: '휴',
    휵: '육',
    휶: '육',
    휷: '육',
    휸: '훈',
    휹: '훈',
    휺: '훈',
    휻: '육',
    휼: '훈',
    휽: '훈',
    휾: '훈',
    휿: '훈',
    흀: '훈',
    흁: '훈',
    흂: '훈',
    흃: '훈',
    흄: '흉',
    흅: '흉',
    흆: '흉',
    흇: '육',
    흈: '육',
    흉: '흉',
    흊: '육',
    흋: '육',
    흌: '육',
    흍: '육',
    흎: '품',
    흏: '휴',
    흐: '희',
    흑: '흑',
    흒: '흑',
    흓: '흑',
    흔: '훈',
    흕: '훈',
    흖: '훈',
    흗: '흑',
    흘: '을',
    흙: '을',
    흚: '을',
    흛: '을',
    흜: '을',
    흝: '을',
    흞: '을',
    흟: '을',
    흠: '흡',
    흡: '흡',
    흢: '흡',
    흣: '흑',
    흤: '흑',
    흥: '흥',
    흦: '흑',
    흧: '흑',
    흨: '흑',
    흩: '흑',
    흪: '흡',
    흫: '희',
    희: '희',
    흭: '흑',
    흮: '흑',
    흯: '흑',
    흰: '훈',
    흱: '훈',
    흲: '훈',
    흳: '흑',
    흴: '을',
    흵: '을',
    흶: '을',
    흷: '을',
    흸: '을',
    흹: '을',
    흺: '을',
    흻: '을',
    흼: '흡',
    흽: '흡',
    흾: '흡',
    흿: '흡',
    힀: '흑',
    힁: '흥',
    힂: '흑',
    힃: '흑',
    힄: '흑',
    힅: '흑',
    힆: '흡',
    힇: '희',
    히: '희',
    힉: '익',
    힊: '익',
    힋: '익',
    힌: '인',
    힍: '인',
    힎: '인',
    힏: '익',
    힐: '일',
    힑: '일',
    힒: '일',
    힓: '일',
    힔: '일',
    힕: '일',
    힖: '일',
    힗: '일',
    힘: '임',
    힙: '입',
    힚: '입',
    힛: '익',
    힜: '익',
    힝: '행',
    힞: '익',
    힟: '익',
    힠: '익',
    힡: '익',
    힢: '입',
    힣: '희',
};

const toHanjaChars = {
    가: '家佳街可歌加價假架暇',
    각: '各角脚閣却覺刻',
    간: '干間看刊肝幹簡姦懇',
    갈: '渴',
    감: '甘減感敢監鑑鑒',
    갑: '甲',
    강: '江降講强康剛鋼綱',
    개: '改皆個箇開介慨槪蓋盖',
    객: '客',
    갱: '更',
    거: '去巨居車擧距拒據',
    건: '建乾件健',
    걸: '傑乞',
    검: '儉劍劒檢',
    격: '格擊激隔',
    견: '犬見堅肩絹遣牽',
    결: '決結潔缺',
    겸: '兼謙',
    경: '京景輕經庚耕敬驚慶競竟境鏡頃傾硬警徑卿',
    계: '癸季界計溪鷄系係戒械繼契桂啓階繫',
    고: '古故固苦考攷高告枯姑庫孤鼓稿顧',
    곡: '谷曲穀哭',
    곤: '困坤',
    골: '骨',
    공: '工功空共公孔供恭攻恐貢',
    과: '果課科過誇寡',
    곽: '郭',
    관: '官觀關館管貫慣冠寬',
    광: '光廣鑛狂',
    괘: '掛',
    괴: '塊愧怪壞',
    교: '交校橋敎郊較巧矯',
    구: '九口求救究久句舊具俱區驅苟拘狗丘懼龜構球',
    국: '國菊局',
    군: '君郡軍群',
    굴: '屈',
    궁: '弓宮窮',
    권: '卷權勸拳券',
    궐: '厥',
    궤: '軌',
    귀: '貴歸鬼',
    규: '叫規糾',
    균: '均菌',
    극: '極克劇',
    근: '近勤根斤僅謹',
    금: '金今禁錦禽琴',
    급: '及給急級',
    긍: '肯',
    기: '己記起其期基氣技幾旣紀忌旗欺奇騎寄豈棄祈企畿飢器機',
    긴: '緊',
    길: '吉',
    나: '那',
    낙: '諾',
    난: '暖難',
    남: '南男',
    납: '納',
    낭: '娘',
    내: '內乃奈耐',
    녀: '女',
    년: '年',
    념: '念',
    녕: '寧',
    노: '怒奴努',
    농: '農',
    뇌: '腦惱',
    능: '能',
    니: '泥',
    다: '多茶',
    단: '丹但單短端旦段壇檀斷團',
    달: '達',
    담: '談淡擔',
    답: '答畓踏',
    당: '堂當唐糖黨',
    대: '大代待對帶臺貸隊',
    덕: '德',
    도: '刀到度道島徒都圖倒挑桃跳逃渡陶途稻導盜塗',
    독: '讀獨毒督篤',
    돈: '豚敦',
    돌: '突',
    동: '同洞童冬東動銅凍',
    두: '斗豆頭',
    둔: '鈍屯',
    득: '得',
    등: '等登燈騰',
    라: '羅',
    락: '落樂絡',
    란: '卵亂蘭欄',
    람: '覽濫',
    랑: '浪郞廊',
    래: '來',
    랭: '冷',
    략: '略掠',
    량: '良兩量凉梁糧諒',
    려: '旅麗慮勵',
    력: '力歷曆',
    련: '連練鍊憐聯戀蓮',
    렬: '列烈裂劣',
    렴: '廉',
    렵: '獵',
    령: '令領嶺零靈',
    례: '例禮隷',
    로: '路露老勞爐',
    록: '綠祿錄鹿',
    론: '論',
    롱: '弄',
    뢰: '雷賴',
    료: '料了僚',
    룡: '龍',
    루: '屢樓累淚漏',
    류: '柳留流類',
    륙: '六陸',
    륜: '倫輪',
    률: '律栗率',
    륭: '隆',
    릉: '陵',
    리: '里理利李梨吏離裏裡履',
    린: '隣',
    림: '林臨',
    립: '立',
    마: '馬麻磨',
    막: '莫幕漠',
    만: '萬晩滿漫慢',
    말: '末',
    망: '亡忙忘望茫妄罔',
    매: '每買賣妹梅埋媒',
    맥: '麥脈',
    맹: '孟猛盟盲',
    면: '免勉面眠綿',
    멸: '滅',
    명: '名命明鳴銘冥',
    모: '母毛暮某謀模貌募慕侮冒',
    목: '木目牧睦',
    몰: '沒',
    몽: '夢蒙',
    묘: '卯妙苗廟墓',
    무: '戊茂武務無舞貿霧',
    묵: '墨默',
    문: '門問聞文',
    물: '勿物',
    미: '米未味美尾迷微眉',
    민: '民敏憫',
    밀: '密蜜',
    박: '朴泊拍迫博薄',
    반: '反飯半般盤班返叛伴',
    발: '發拔髮',
    방: '方房防放訪芳傍妨倣邦',
    배: '拜杯盃倍培配排輩背',
    백: '白百伯',
    번: '番煩繁飜',
    벌: '伐罰',
    범: '凡犯範',
    법: '法',
    벽: '壁碧',
    변: '變辯辨邊',
    별: '別',
    병: '丙病兵竝屛',
    보: '保步報普譜補寶',
    복: '福伏服復腹複卜覆',
    본: '本',
    봉: '奉逢峯峰蜂封鳳',
    부: '夫扶父富部婦否浮付符附府腐負副簿赴賦',
    북: '北',
    분: '分紛粉奔墳憤奮',
    불: '不佛拂',
    붕: '朋崩',
    비: '比非悲飛鼻備批卑婢碑妃肥秘費',
    빈: '貧賓頻',
    빙: '氷聘',
    사: '四巳士仕寺史使舍射謝師死私絲思事司詞蛇捨邪賜斜詐社沙似査寫辭斯祀',
    삭: '削朔',
    산: '山産散算',
    살: '殺',
    삼: '三',
    상: '上尙常賞商相霜想傷喪嘗裳詳祥床象像桑償狀',
    새: '塞',
    색: '色索',
    생: '生',
    서: '西序書暑敍徐庶恕緖署誓逝',
    석: '石夕昔惜席析釋',
    선: '先仙線鮮善船選宣旋禪',
    설: '雪說設舌',
    섭: '涉攝',
    성: '姓性成城誠盛省星聖聲',
    세: '世洗稅細勢歲',
    소: '小少所消素笑召昭蘇騷燒訴掃疎蔬',
    속: '俗速續束粟屬',
    손: '孫損',
    송: '松送訟頌誦',
    쇄: '刷鎖',
    쇠: '衰',
    수: '水手受授首守收誰須雖愁樹壽數修秀囚需帥殊隨輸獸睡遂垂搜',
    숙: '叔淑宿孰熟肅',
    순: '順純旬殉循脣瞬巡',
    술: '戌述術',
    숭: '崇',
    습: '習拾濕襲',
    승: '乘承勝昇僧',
    시: '市示是時詩視施試始矢侍',
    식: '食式植識息飾',
    신: '身申神臣信辛新伸晨愼',
    실: '失室實',
    심: '心甚深尋審',
    십: '十',
    쌍: '雙',
    씨: '氏',
    아: '兒我牙芽雅亞餓',
    악: '惡岳',
    안: '安案顔眼岸雁',
    알: '謁',
    암: '暗巖岩',
    압: '壓押',
    앙: '仰央殃',
    애: '愛哀涯',
    액: '厄額',
    야: '也夜野耶',
    약: '弱若約藥躍',
    양: '羊洋養揚陽讓壤樣楊',
    어: '魚漁於語御',
    억: '億憶抑',
    언: '言焉',
    엄: '嚴',
    업: '業',
    여: '余餘如汝與予輿',
    역: '亦易逆譯驛役疫域',
    연: '然煙硏延燃燕沿鉛宴軟演緣',
    열: '熱悅閱',
    염: '炎染鹽',
    엽: '葉',
    영: '永英迎榮泳詠營影映',
    예: '藝豫譽銳',
    오: '五吾悟午誤烏汚嗚娛傲',
    옥: '玉屋獄',
    온: '溫',
    옹: '翁擁',
    와: '瓦臥',
    완: '完緩',
    왈: '曰',
    왕: '王往',
    외: '外畏',
    요: '要腰搖遙謠',
    욕: '欲浴慾辱',
    용: '用容勇庸',
    우: '于宇右友牛雨憂又尤遇羽郵愚偶優',
    운: '云雲運韻',
    웅: '雄',
    원: '元原願遠園怨圓員源援院',
    월: '月越',
    위: '位危爲偉威胃謂圍緯衛違委慰僞',
    유: '由油酉有猶唯遊柔遺幼幽惟維乳儒裕誘愈悠',
    육: '肉育',
    윤: '閏潤',
    은: '恩銀隱',
    을: '乙',
    음: '音吟飮陰淫',
    읍: '邑泣',
    응: '應凝',
    의: '衣依義議矣醫意宜儀疑',
    이: '二以已耳而異移夷',
    익: '益翼',
    인: '人引仁因忍認寅印姻',
    일: '一日逸',
    임: '壬任賃',
    입: '入',
    자: '子字自者姉慈玆紫資姿恣刺',
    작: '作昨酌爵',
    잔: '殘',
    잠: '潛暫',
    잡: '雜',
    장: '長章場將壯丈張帳莊裝奬墻牆葬粧掌藏臟障腸',
    재: '才材財在栽再哉災裁載宰',
    쟁: '爭',
    저: '著貯低底抵',
    적: '的赤適敵滴摘寂籍賊跡蹟積績',
    전: '田全典前展戰電錢傳專轉殿',
    절: '節絶切折竊',
    점: '店占點漸',
    접: '接蝶',
    정: '丁停頂井正政定貞精情靜淨庭亭訂廷程征整',
    제: '弟第祭帝題除諸製提堤制際齊濟',
    조: '兆早造鳥調朝助祖弔燥操照條潮租組',
    족: '足族',
    존: '存尊',
    졸: '卒拙',
    종: '宗種鐘終從縱',
    좌: '左坐佐座',
    죄: '罪',
    주: '主注住朱宙走酒晝舟周株州洲柱奏珠鑄',
    죽: '竹',
    준: '準俊遵',
    중: '中重衆仲',
    즉: '卽',
    증: '曾增證憎贈症蒸',
    지: '只支枝止之知地指志至紙持池誌智遲',
    직: '直職織',
    진: '辰眞進盡振鎭陳陣珍震',
    질: '質秩疾姪',
    집: '集執',
    징: '徵懲',
    차: '且次此借差',
    착: '着錯捉',
    찬: '贊讚',
    찰: '察',
    참: '參慘慙',
    창: '昌唱窓倉創蒼暢',
    채: '菜採彩債',
    책: '責冊策',
    처: '妻處',
    척: '尺斥拓戚',
    천: '千天川泉淺賤踐遷薦',
    철: '鐵哲徹',
    첨: '尖添',
    첩: '妾',
    청: '靑淸晴請聽廳',
    체: '體替滯逮遞',
    초: '初草招肖超抄礎秒',
    촉: '促燭觸',
    촌: '寸村',
    총: '銃總聰',
    최: '最催',
    추: '秋追推抽醜',
    축: '丑祝畜蓄築逐縮',
    춘: '春',
    출: '出',
    충: '充忠蟲衝',
    취: '取吹就臭醉趣',
    측: '側測',
    층: '層',
    치: '治致齒値置恥',
    칙: '則',
    친: '親',
    칠: '七漆',
    침: '針侵浸寢沈枕',
    칭: '稱',
    쾌: '快',
    타: '他打妥墮',
    탁: '濁托濯卓',
    탄: '炭歎彈誕',
    탈: '脫奪',
    탐: '探貪',
    탑: '塔',
    탕: '湯',
    태: '太泰怠殆態',
    택: '宅澤擇',
    토: '土吐討',
    통: '通統痛',
    퇴: '退',
    투: '投透鬪',
    특: '特',
    파: '破波派播罷頗把',
    판: '判板販版',
    팔: '八',
    패: '貝敗',
    편: '片便篇編遍偏',
    평: '平評',
    폐: '閉肺廢弊蔽幣',
    포: '布抱包胞飽浦捕',
    폭: '暴爆幅',
    표: '表票標漂',
    품: '品',
    풍: '風豊',
    피: '皮彼疲被避',
    필: '必匹筆畢',
    하: '下夏賀何河荷',
    학: '學鶴',
    한: '閑寒恨限韓漢旱汗',
    할: '割',
    함: '咸含陷',
    합: '合',
    항: '恒巷港項抗航',
    해: '害海亥解奚該',
    핵: '核',
    행: '行幸',
    향: '向香鄕響享',
    허: '虛許',
    헌: '軒憲獻',
    험: '險驗',
    혁: '革',
    현: '現賢玄絃縣懸顯',
    혈: '血穴',
    협: '協脅',
    형: '兄刑形亨螢衡',
    혜: '惠慧兮',
    호: '戶乎呼好虎號湖互胡浩毫豪護',
    혹: '或惑',
    혼: '婚混昏魂',
    홀: '忽',
    홍: '紅洪弘鴻',
    화: '火化花貨和話畵華禾禍',
    확: '確碻穫擴',
    환: '歡患丸換環還',
    활: '活',
    황: '黃皇況荒',
    회: '回會悔懷',
    획: '獲劃',
    횡: '橫',
    효: '孝效曉',
    후: '後厚侯候',
    훈: '訓',
    훼: '毁',
    휘: '揮輝',
    휴: '休携',
    흉: '凶胸',
    흑: '黑',
    흡: '吸',
    흥: '興',
    희: '希喜戱稀',
};

const hanjaToMeaning = {
    佳: '아름다울 가',
    假: '거짓 가',
    價: '값 가',
    加: '더할 가',
    可: '옳을 가',
    家: '집 가',
    暇: '겨를 가',
    架: '시렁 가',
    歌: '노래 가',
    街: '거리 가',
    刻: '새길 각',
    却: '물리칠 각',
    各: '하나하나 각',
    脚: '다리 각',
    覺: '깨달을 각',
    角: '뿔 각',
    閣: '누각 각',
    刊: '책 펴낼 간',
    姦: '간사할 간',
    干: '방패 간',
    幹: '줄기 간',
    懇: '정성 간',
    看: '볼 간',
    簡: '대쪽 간',
    肝: '간 간',
    間: '사이 간',
    渴: '목마를 갈',
    感: '느낄 감',
    敢: '감히 감',
    減: '덜 감',
    甘: '달 감',
    監: '볼 감',
    鑑: '거울 감',
    鑒: '거울 감',
    甲: '갑옷 갑',
    剛: '굳셀 강',
    康: '편안할 강',
    强: '굳셀 강',
    江: '강 강',
    綱: '벼리 강',
    講: '익힐 강',
    鋼: '강철 강',
    降: '내릴 강',
    介: '끼일 개',
    個: '낱 개',
    慨: '분개할 개',
    改: '고칠 개',
    槪: '대개 개',
    皆: '다 개',
    盖: '덮을 개',
    箇: '낱 개',
    蓋: '덮을 개',
    開: '열 개',
    客: '손님 객',
    去: '갈 거',
    居: '있을 거',
    巨: '클 거',
    拒: '막을 거',
    據: '의거할 거',
    擧: '들 거',
    距: '떨어질 거',
    乾: '하늘 건',
    件: '사건 건',
    健: '튼튼할 건',
    建: '세울 건',
    乞: '빌 걸',
    傑: '뛰어날 걸',
    儉: '검소할 검',
    劍: '칼 검',
    劒: '칼 검',
    檢: '검사할 검',
    擊: '부딪칠 격',
    格: '바로잡을 격',
    激: '과격할 격',
    隔: '사이 뜰 격',
    堅: '굳을 견',
    牽: '끌 견',
    犬: '개 견',
    絹: '명주 견',
    肩: '어깨 견',
    見: '볼 견',
    遣: '보낼 견',
    決: '터질 결',
    潔: '깨끗할 결',
    結: '맺을 결',
    缺: '이지러질 결',
    兼: '겸할 겸',
    謙: '겸손할 겸',
    京: '서울 경',
    傾: '기울 경',
    卿: '벼슬 경',
    境: '지경 경',
    庚: '일곱째 천간 경',
    徑: '지름길 경',
    慶: '경사 경',
    敬: '공경할 경',
    景: '볕 경',
    更: '다시 갱',
    硬: '굳을 경',
    竟: '다할 경',
    競: '겨룰 경',
    經: '경서 경',
    耕: '밭을 갈 경',
    警: '경계할 경',
    輕: '가벼울 경',
    鏡: '거울 경',
    頃: '잠깐 경',
    驚: '놀랄 경',
    係: '걸릴 계',
    啓: '열 계',
    契: '맺을 계',
    季: '끝 계',
    戒: '경계할 계',
    桂: '계수나무 계',
    械: '형틀 계',
    溪: '시내 계',
    界: '지경 계',
    癸: '열째 천간 계',
    系: '이을 계',
    繫: '맬 계',
    繼: '이을 계',
    計: '꾀 계',
    階: '섬돌 계',
    鷄: '닭 계',
    古: '옛 고',
    告: '알릴 고',
    固: '굳을 고',
    姑: '시어미 고',
    孤: '외로울 고',
    庫: '곳집 고',
    攷: '상고할 고',
    故: '옛 고',
    枯: '마를 고',
    稿: '볏짚 고',
    考: '상고할 고',
    苦: '쓸 고',
    顧: '돌아볼 고',
    高: '높을 고',
    鼓: '북 고',
    哭: '울 곡',
    曲: '굽을 곡',
    穀: '곡식 곡',
    谷: '골 곡',
    困: '괴로울 곤',
    坤: '땅 곤',
    骨: '뼈 골',
    供: '이바지할 공',
    公: '공변될 공',
    共: '함께 공',
    功: '공로 공',
    孔: '구멍 공',
    工: '장인 공',
    恐: '두려울 공',
    恭: '공손할 공',
    攻: '칠 공',
    空: '빌 공',
    貢: '바칠 공',
    寡: '적을 과',
    果: '과실 과',
    科: '과정 과',
    誇: '자랑할 과',
    課: '매길 과',
    過: '지날 과',
    郭: '성곽 곽',
    冠: '갓 관',
    官: '벼슬 관',
    寬: '너그러울 관',
    慣: '버릇 관',
    管: '피리 관',
    觀: '볼 관',
    貫: '꿸 관',
    關: '빗장 관',
    館: '객사 관',
    光: '빛 광',
    廣: '넓을 광',
    狂: '미칠 광',
    鑛: '쇳돌 광',
    掛: '걸 괘',
    塊: '흙덩이 괴',
    壞: '무너질 괴',
    怪: '기이할 괴',
    愧: '부끄러워할 괴',
    交: '사귈 교',
    巧: '공교할 교',
    敎: '가르칠 교',
    校: '학교 교',
    橋: '다리 교',
    矯: '바로잡을 교',
    較: '견줄 교',
    郊: '성 밖 교',
    丘: '언덕 구',
    久: '오랠 구',
    九: '아홉 구',
    俱: '함께 구',
    具: '갖출 구',
    區: '지경 구',
    口: '입 구',
    句: '글귀 구',
    懼: '두려워할 구',
    拘: '잡을 구',
    救: '건질 구',
    構: '얽을 구',
    求: '구할 구',
    狗: '개 구',
    球: '공 구',
    究: '궁구할 구',
    舊: '예 구',
    苟: '진실로 구',
    驅: '몰 구',
    龜: '땅 이름 구',
    國: '나라 국',
    局: '판 국',
    菊: '국화 국',
    君: '임금 군',
    群: '무리 군',
    軍: '군사 군',
    郡: '고을 군',
    屈: '굽을 굴',
    宮: '집 궁',
    弓: '활 궁',
    窮: '다할 궁',
    券: '문서 권',
    勸: '권할 권',
    卷: '책 권',
    拳: '주먹 권',
    權: '권세 권',
    厥: '그 궐',
    軌: '길 궤',
    歸: '돌아갈 귀',
    貴: '귀할 귀',
    鬼: '귀신 귀',
    叫: '부르짖을 규',
    糾: '살필 규',
    規: '법 규',
    均: '고를 균',
    菌: '버섯 균',
    克: '이길 극',
    劇: '심할 극',
    極: '다할 극',
    僅: '겨우 근',
    勤: '부지런할 근',
    斤: '도끼 근',
    根: '뿌리 근',
    謹: '삼갈 근',
    近: '가까울 근',
    今: '이제 금',
    琴: '거문고 금',
    禁: '금할 금',
    禽: '날짐승 금',
    錦: '비단 금',
    及: '미칠 급',
    急: '급할 급',
    級: '등급 급',
    給: '넉넉할 급',
    肯: '긍정할 긍',
    企: '꾀할 기',
    其: '그 기',
    器: '그릇 기',
    基: '터 기',
    奇: '기이할 기',
    寄: '부칠 기',
    己: '몸 기',
    幾: '기미 기',
    忌: '꺼릴 기',
    技: '재주 기',
    旗: '기 기',
    旣: '이미 기',
    期: '기약할 기',
    棄: '버릴 기',
    機: '틀 기',
    欺: '속일 기',
    氣: '기운 기',
    畿: '경기 기',
    祈: '빌 기',
    紀: '벼리 기',
    記: '기록할 기',
    豈: '어찌 기',
    起: '일어날 기',
    飢: '주릴 기',
    騎: '말탈 기',
    緊: '긴요할 긴',
    吉: '길할 길',
    金: '쇠 금',
    那: '어찌 나',
    諾: '대답할 낙',
    暖: '따뜻할 난',
    難: '어려울 난',
    南: '남녘 남',
    男: '사내 남',
    納: '바칠 납',
    娘: '아가씨 낭',
    乃: '이에 내',
    內: '안 내',
    奈: '어찌 내',
    耐: '견딜 내',
    女: '계집 녀',
    年: '해 년',
    念: '생각할 념',
    寧: '편안할 녕',
    努: '힘쓸 노',
    奴: '종 노',
    怒: '성낼 노',
    農: '농사 농',
    惱: '괴로워할 뇌',
    腦: '뇌 뇌',
    能: '능할 능',
    泥: '진흙 니',
    多: '많을 다',
    茶: '차 다',
    丹: '붉을 단',
    但: '다만 단',
    單: '홑 단',
    團: '둥글 단',
    壇: '단 단',
    斷: '끊을 단',
    旦: '아침 단',
    檀: '박달나무 단',
    段: '구분 단',
    短: '짧을 단',
    端: '바를 단',
    達: '통달할 달',
    擔: '멜 담',
    淡: '묽을 담',
    談: '말씀 담',
    畓: '논 답',
    答: '대답할 답',
    踏: '밟을 답',
    唐: '당나라 당',
    堂: '집 당',
    當: '당할 당',
    糖: '사탕 당',
    黨: '무리 당',
    代: '대신할 대',
    大: '큰 대',
    對: '대답할 대',
    帶: '띠 대',
    待: '기다릴 대',
    臺: '대 대',
    貸: '빌릴 대',
    隊: '떼 대',
    宅: '집 택',
    德: '덕 덕',
    倒: '넘어질 도',
    刀: '칼 도',
    到: '이를 도',
    圖: '그림 도',
    塗: '진흙 도',
    導: '이끌 도',
    島: '섬 도',
    度: '법도 도',
    徒: '무리 도',
    挑: '휠 도',
    桃: '복숭아나무 도',
    渡: '건널 도',
    盜: '훔칠 도',
    稻: '벼 도',
    跳: '뛸 도',
    逃: '달아날 도',
    途: '길 도',
    道: '길 도',
    都: '도읍 도',
    陶: '질그릇 도',
    毒: '독 독',
    獨: '홀로 독',
    督: '살펴볼 독',
    篤: '도타울 독',
    讀: '읽을 독',
    敦: '도타울 돈',
    豚: '돼지 돈',
    突: '갑자기 돌',
    冬: '겨울 동',
    凍: '얼 동',
    動: '움직일 동',
    同: '한가지 동',
    東: '동녘 동',
    洞: '골짜기 동',
    童: '아이 동',
    銅: '구리 동',
    斗: '말 두',
    豆: '콩 두',
    頭: '머리 두',
    屯: '진칠 둔',
    鈍: '무딜 둔',
    得: '얻을 득',
    燈: '등잔 등',
    登: '오를 등',
    等: '가지런할 등',
    騰: '오를 등',
    羅: '벌릴 라',
    絡: '헌솜 락',
    落: '떨어질 락',
    亂: '어지러울 란',
    卵: '알 란',
    欄: '난간 란',
    蘭: '난초 란',
    濫: '퍼질 람',
    覽: '볼 람',
    廊: '복도 랑',
    浪: '물결 랑',
    郞: '사내 랑',
    來: '올 래',
    冷: '찰 랭',
    掠: '노략질할 략',
    略: '다스릴 략',
    兩: '두 량',
    凉: '서늘할 량',
    梁: '들보 량',
    糧: '양식 량',
    良: '어질 량',
    諒: '믿을 량',
    量: '헤아릴 량',
    勵: '힘쓸 려',
    慮: '생각할 려',
    旅: '군사 려',
    麗: '고울 려',
    力: '힘 력',
    曆: '책력 력',
    歷: '지낼 력',
    憐: '불쌍히 여길 련',
    戀: '사모할 련',
    練: '익힐 련',
    聯: '잇달 련',
    蓮: '연 련',
    連: '잇닿을 련',
    鍊: '불릴 련',
    列: '벌일 렬',
    劣: '못할 렬',
    烈: '세찰 렬',
    裂: '찢을 렬',
    廉: '청렴할 렴',
    獵: '사냥 렵',
    令: '명령할 령',
    嶺: '재 령',
    零: '떨어질 령',
    靈: '신령 령',
    領: '옷깃 령',
    例: '법식 례',
    禮: '예도 례',
    隷: '종 례',
    勞: '일할 로',
    爐: '화로 로',
    老: '늙은이 로',
    路: '길 로',
    露: '이슬 로',
    祿: '복 록',
    綠: '초록빛 록',
    錄: '기록할 록',
    鹿: '사슴 록',
    論: '말할 론',
    弄: '희롱할 롱',
    賴: '힘입을 뢰',
    雷: '우레 뢰',
    了: '마칠 료',
    僚: '동료 료',
    料: '헤아릴 료',
    龍: '용 룡',
    屢: '창 루',
    樓: '다락 루',
    淚: '눈물 루',
    漏: '샐 루',
    累: '묶을 루',
    柳: '버들 류',
    流: '흐를 류',
    留: '머무를 류',
    類: '무리 류',
    六: '여섯 륙',
    陸: '뭍 륙',
    倫: '인륜 륜',
    輪: '바퀴 륜',
    律: '법 률',
    栗: '밤나무 률',
    隆: '클 륭',
    陵: '큰 언덕 릉',
    利: '이로울 리',
    吏: '벼슬아치 리',
    履: '밟을 리',
    李: '오얏 리',
    梨: '배나무 리',
    理: '다스릴 리',
    裏: '속 리',
    裡: '속 리',
    里: '마을 리',
    離: '떼놓을 리',
    隣: '이웃 린',
    林: '수풀 림',
    臨: '임할 림',
    立: '설 립',
    磨: '갈 마',
    馬: '말 마',
    麻: '삼 마',
    幕: '막 막',
    漠: '사막 막',
    莫: '없을 막',
    慢: '게으를 만',
    晩: '늦을 만',
    滿: '찰 만',
    漫: '질펀할 만',
    萬: '일만 만',
    末: '끝 말',
    亡: '망할 망',
    妄: '망령될 망',
    忘: '잊을 망',
    忙: '바쁠 망',
    望: '바랄 망',
    罔: '그물 망',
    茫: '아득할 망',
    埋: '묻을 매',
    妹: '누이 매',
    媒: '중매 매',
    梅: '매화나무 매',
    每: '매양 매',
    買: '살 매',
    賣: '팔 매',
    脈: '맥 맥',
    麥: '보리 맥',
    孟: '맏 맹',
    猛: '사나울 맹',
    盲: '소경 맹',
    盟: '맹세할 맹',
    免: '면할 면',
    勉: '힘쓸 면',
    眠: '잠잘 면',
    綿: '이어질 면',
    面: '낯 면',
    滅: '멸망할 멸',
    冥: '어두울 명',
    名: '이름 명',
    命: '목숨 명',
    明: '밝을 명',
    銘: '새길 명',
    鳴: '울 명',
    侮: '업신여길 모',
    冒: '무릅쓸 모',
    募: '모을 모',
    慕: '그리워할 모',
    暮: '저물 모',
    某: '아무개 모',
    模: '법 모',
    母: '어미 모',
    毛: '털 모',
    謀: '꾀할 모',
    貌: '얼굴 모',
    木: '나무 목',
    牧: '칠 목',
    目: '눈 목',
    睦: '화목할 목',
    沒: '가라앉을 몰',
    夢: '꿈 몽',
    蒙: '입을 몽',
    卯: '토끼 묘',
    墓: '무덤 묘',
    妙: '묘할 묘',
    廟: '사당 묘',
    苗: '모 묘',
    務: '일 무',
    戊: '다섯째 천간 무',
    武: '굳셀 무',
    無: '없을 무',
    舞: '춤출 무',
    茂: '우거질 무',
    貿: '바꿀 무',
    霧: '안개 무',
    墨: '먹 묵',
    默: '묵묵할 묵',
    問: '물을 문',
    文: '글월 문',
    聞: '들을 문',
    門: '문 문',
    勿: '말 물',
    物: '만물 물',
    味: '맛 미',
    尾: '꼬리 미',
    微: '작을 미',
    未: '아닐 미',
    眉: '눈썹 미',
    米: '쌀 미',
    美: '아름다울 미',
    迷: '미혹할 미',
    憫: '근심할 민',
    敏: '재빠를 민',
    民: '백성 민',
    密: '빽빽할 밀',
    蜜: '꿀 밀',
    博: '넓을 박',
    拍: '칠 박',
    朴: '순박할 박',
    泊: '머무를 박',
    薄: '엷을 박',
    迫: '닥칠 박',
    伴: '짝 반',
    半: '반 반',
    反: '되돌릴 반',
    叛: '배반할 반',
    班: '나눌 반',
    盤: '소반 반',
    般: '돌 반',
    返: '돌아올 반',
    飯: '밥 반',
    拔: '뺄 발',
    發: '쏠 발',
    髮: '터럭 발',
    倣: '본뜰 방',
    傍: '곁 방',
    妨: '방해할 방',
    房: '방 방',
    放: '놓을 방',
    方: '모 방',
    芳: '꽃다울 방',
    訪: '찾을 방',
    邦: '나라 방',
    防: '막을 방',
    倍: '곱 배',
    培: '북돋을 배',
    拜: '절 배',
    排: '밀칠 배',
    杯: '잔 배',
    盃: '잔 배',
    背: '등 배',
    輩: '무리 배',
    配: '아내 배',
    伯: '맏 백',
    白: '흰 백',
    百: '일백 백',
    煩: '괴로워할 번',
    番: '차례 번',
    繁: '많을 번',
    飜: '펄럭일 번',
    伐: '칠 벌',
    罰: '죄 벌',
    凡: '무릇 범',
    犯: '범할 범',
    範: '법 범',
    法: '법 법',
    壁: '벽 벽',
    碧: '푸를 벽',
    變: '변할 변',
    辨: '분별할 변',
    辯: '말 잘할 변',
    邊: '가 변',
    別: '나눌 별',
    丙: '남녘 병',
    兵: '군사 병',
    屛: '병풍 병',
    病: '병 병',
    竝: '아우를 병',
    保: '지킬 보',
    報: '갚을 보',
    寶: '보배 보',
    普: '널리 보',
    步: '걸음 보',
    補: '도울 보',
    譜: '족보 보',
    伏: '엎드릴 복',
    卜: '점 복',
    復: '돌아올 복',
    服: '옷 복',
    福: '복 복',
    腹: '배 복',
    複: '겹칠 복',
    覆: '뒤집힐 복',
    本: '밑 본',
    奉: '받들 봉',
    封: '봉할 봉',
    峯: '봉우리 봉',
    峰: '봉우리 봉',
    蜂: '벌 봉',
    逢: '만날 봉',
    鳳: '봉황새 봉',
    不: '아닐 불',
    付: '줄 부',
    副: '버금 부',
    否: '아닐 부',
    夫: '지아비 부',
    婦: '며느리 부',
    富: '넉넉할 부',
    府: '마을 부',
    扶: '도울 부',
    浮: '뜰 부',
    父: '아비 부',
    符: '부신 부',
    簿: '장부 부',
    腐: '썩을 부',
    負: '질 부',
    賦: '구실 부',
    赴: '나아갈 부',
    部: '거느릴 부',
    附: '붙을 부',
    北: '북녘 북',
    分: '나눌 분',
    墳: '무덤 분',
    奔: '달릴 분',
    奮: '떨칠 분',
    憤: '성낼 분',
    粉: '가루 분',
    紛: '어지러워질 분',
    佛: '부처 불',
    拂: '떨 불',
    崩: '무너질 붕',
    朋: '벗 붕',
    備: '갖출 비',
    卑: '낮을 비',
    妃: '왕비 비',
    婢: '여자 종 비',
    悲: '슬플 비',
    批: '칠 비',
    比: '견줄 비',
    碑: '돌기둥 비',
    秘: '숨길 비',
    肥: '살찔비',
    費: '쓸 비',
    非: '아닐 비',
    飛: '날 비',
    鼻: '코 비',
    貧: '가난할 빈',
    賓: '손 빈',
    頻: '자주 빈',
    氷: '얼음 빙',
    聘: '찾아갈 빙',
    事: '일 사',
    仕: '벼슬할 사',
    似: '같을 사',
    使: '시킬 사',
    史: '역사 사',
    司: '맡을 사',
    四: '넉 사',
    士: '선비 사',
    寫: '베낄 사',
    寺: '절 사',
    射: '쏠 사',
    巳: '뱀 사',
    師: '스승 사',
    思: '생각할 사',
    捨: '버릴 사',
    斜: '비낄 사',
    斯: '이 사',
    査: '조사할 사',
    死: '죽을 사',
    沙: '모래 사',
    社: '토지의 신 사',
    祀: '제사 사',
    私: '사사로울 사',
    絲: '실 사',
    舍: '집 사',
    蛇: '뱀 사',
    詐: '속일 사',
    詞: '말씀 사',
    謝: '사례할 사',
    賜: '줄 사',
    辭: '말 사',
    邪: '간사할 사',
    削: '깎을 삭',
    朔: '초하루 삭',
    山: '뫼 산',
    散: '흩어질 산',
    産: '낳을 산',
    算: '셀 산',
    殺: '죽일 살',
    三: '석 삼',
    上: '위 상',
    傷: '상처 상',
    像: '형상 상',
    償: '갚을 상',
    商: '장사 상',
    喪: '죽을 상',
    嘗: '맛볼 상',
    尙: '오히려 상',
    常: '항상 상',
    床: '상 상',
    想: '생각할 상',
    桑: '뽕나무 상',
    狀: '형상 상',
    相: '서로 상',
    祥: '상서로울 상',
    裳: '치마 상',
    詳: '자세할 상',
    象: '코 끼리 상',
    賞: '상줄 상',
    霜: '서리 상',
    塞: '변방 새',
    索: '찾을 색',
    色: '빛 색',
    生: '날 생',
    序: '차례 서',
    庶: '여러 서',
    徐: '천천히 할 서',
    恕: '용서할 서',
    敍: '차례 서',
    暑: '더울 서',
    書: '쓸 서',
    緖: '실마리 서',
    署: '관청 서',
    西: '서녘 서',
    誓: '맹세할 서',
    逝: '갈 서',
    夕: '저녁 석',
    席: '자리 석',
    惜: '아낄 석',
    昔: '옛 석',
    析: '가를 석',
    石: '돌 석',
    釋: '풀 석',
    仙: '신선 선',
    先: '먼저 선',
    善: '착할 선',
    宣: '베풀 선',
    旋: '돌 선',
    禪: '봉선 선',
    線: '줄 선',
    船: '배 선',
    選: '가릴 선',
    鮮: '고울 선',
    舌: '혀 설',
    設: '베풀 설',
    說: '말씀 설',
    雪: '눈 설',
    攝: '당길 섭',
    涉: '건널 섭',
    城: '성 성',
    姓: '성 성',
    性: '성품 성',
    成: '이룰 성',
    星: '별 성',
    盛: '담을 성',
    省: '살필 성',
    聖: '성스러울 성',
    聲: '소리 성',
    誠: '정성 성',
    世: '세상 세',
    勢: '기세 세',
    歲: '해 세',
    洗: '씻을 세',
    稅: '세금 세',
    細: '가늘 세',
    召: '부를 소',
    小: '작을 소',
    少: '적을 소',
    所: '바 소',
    掃: '쓸 소',
    昭: '밝을 소',
    消: '사라질 소',
    燒: '불태울 소',
    疎: '트일 소',
    笑: '웃을 소',
    素: '흴 소',
    蔬: '푸성귀 소',
    蘇: '깨어날 소',
    訴: '하소연할 소',
    騷: '떠들 소',
    俗: '풍속 속',
    屬: '엮을 속',
    束: '묶을 속',
    粟: '조 속',
    續: '이을 속',
    速: '빠를 속',
    孫: '손자 손',
    損: '덜 손',
    率: '헤아릴 률',
    松: '소나무 송',
    訟: '송사할 송',
    誦: '욀 송',
    送: '보낼 송',
    頌: '기릴 송',
    刷: '쓸 쇄',
    鎖: '쇠사슬 쇄',
    衰: '쇠할 쇠',
    修: '닦을 수',
    受: '받을 수',
    囚: '가둘 수',
    垂: '드리울 수',
    壽: '목숨 수',
    守: '지킬 수',
    帥: '장수 수',
    愁: '시름 수',
    手: '손 수',
    授: '줄 수',
    搜: '찾을 수',
    收: '거둘 수',
    數: '셀 수',
    樹: '나무 수',
    殊: '다를 수',
    水: '물 수',
    獸: '짐승 수',
    睡: '잘 수',
    秀: '빼어날 수',
    誰: '누구 수',
    輸: '나를 수',
    遂: '이를 수',
    隨: '따를 수',
    雖: '비록 수',
    需: '구할 수',
    須: '모름지기 수',
    首: '머리 수',
    叔: '아재비 숙',
    孰: '누구 숙',
    宿: '묵을 숙',
    淑: '맑을 숙',
    熟: '익을 숙',
    肅: '엄숙할 숙',
    巡: '돌 순',
    循: '돌 순',
    旬: '열흘 순',
    殉: '따라 죽을 순',
    瞬: '눈깜짝일 순',
    純: '순수할 순',
    脣: '입술 순',
    順: '순할 순',
    戌: '개 술',
    術: '재주 술',
    述: '지을 술',
    崇: '높을 숭',
    濕: '축축할 습',
    拾: '주울 습',
    習: '익힐 습',
    襲: '엄습할 습',
    乘: '탈 승',
    僧: '중 승',
    勝: '이길 승',
    承: '받들 승',
    昇: '오를 승',
    侍: '모실 시',
    始: '처음 시',
    市: '저자 시',
    施: '베 풀 시',
    是: '옳을 시',
    時: '때 시',
    矢: '화살 시',
    示: '보일 시',
    視: '볼 시',
    試: '시험할 시',
    詩: '시 시',
    式: '법 식',
    息: '숨쉴 식',
    植: '심을 식',
    識: '알 식',
    食: '먹을 식',
    飾: '꾸밀 식',
    伸: '펼 신',
    信: '믿을 신',
    愼: '삼갈 신',
    新: '새 신',
    晨: '새벽 신',
    申: '원숭이 신',
    神: '귀신 신',
    臣: '신하 신',
    身: '몸 신',
    辛: '매울 신',
    失: '잃을 실',
    室: '집 실',
    實: '열매 실',
    審: '살필 심',
    尋: '찾을 심',
    心: '마음 심',
    深: '깊을 심',
    甚: '심할 심',
    十: '열 십',
    雙: '쌍 쌍',
    氏: '각시 씨',
    亞: '버금 아',
    兒: '아이 아',
    我: '나 아',
    牙: '어금니 아',
    芽: '싹 아',
    雅: '우아할 아',
    餓: '굶주릴 아',
    岳: '큰 산 악',
    惡: '악할 악',
    樂: '즐길 락',
    安: '편안할 안',
    岸: '언덕 안',
    案: '책상 안',
    眼: '눈 안',
    雁: '기러기 안',
    顔: '얼굴 안',
    謁: '뵐 알',
    岩: '바위 암',
    巖: '바위 암',
    暗: '어두울 암',
    壓: '누를 압',
    押: '누를 압',
    仰: '우러를 앙',
    央: '가운데 앙',
    殃: '재앙 앙',
    哀: '슬플 애',
    愛: '사랑 애',
    涯: '물가 애',
    厄: '재앙 액',
    額: '이마 액',
    也: '어조사 야',
    夜: '밤 야',
    耶: '어조사 야',
    野: '들 야',
    弱: '약할 약',
    約: '약속할 약',
    若: '같을 약',
    藥: '약 약',
    躍: '뛸 약',
    壤: '흙 양',
    揚: '오를 양',
    楊: '버들 양',
    樣: '모양 양',
    洋: '바다 양',
    羊: '양 양',
    讓: '사양할 양',
    陽: '볕 양',
    養: '기를 양',
    御: '어거할 어',
    於: '어조사 어',
    漁: '고기 잡을 어',
    語: '말씀 어',
    魚: '물고기 어',
    億: '억 억',
    憶: '생각할 억',
    抑: '누를 억',
    焉: '어찌 언',
    言: '말씀 언',
    嚴: '엄할 엄',
    業: '업 업',
    予: '나 여',
    余: '나 여',
    如: '같을 여',
    汝: '너 여',
    與: '줄 여',
    輿: '수레 여',
    餘: '남을 여',
    亦: '또 역',
    域: '지경 역',
    役: '부릴 역',
    易: '바꿀 역',
    疫: '염병 역',
    譯: '통변할 역',
    逆: '거스를 역',
    驛: '역참 역',
    宴: '잔치 연',
    延: '끌 연',
    沿: '따를 연',
    演: '연역할 연',
    然: '그러할 연',
    煙: '연기 연',
    燃: '탈 연',
    燕: '제비 연',
    硏: '갈 연',
    緣: '인연 연',
    軟: '연할 연',
    鉛: '납 연',
    悅: '기쁠 열',
    熱: '더울 열',
    閱: '검열할 열',
    染: '물들일 염',
    炎: '불탈 염',
    鹽: '소금 염',
    葉: '잎 엽',
    影: '그림자 영',
    映: '비출 영',
    榮: '영화로울 영',
    永: '길 영',
    泳: '헤엄칠 영',
    營: '경영할 영',
    英: '꽃부리 영',
    詠: '읊을 영',
    迎: '맞이할 영',
    藝: '기예 예',
    譽: '기릴 예',
    豫: '미리 예',
    銳: '날카로울 예',
    五: '다섯 오',
    傲: '거만할 오',
    午: '낮 오',
    吾: '나 오',
    嗚: '탄식소리 오',
    娛: '즐거워할 오',
    悟: '깨달을 오',
    汚: '더러울 오',
    烏: '까마귀 오',
    誤: '그르칠 오',
    屋: '집 옥',
    獄: '옥 옥',
    玉: '구슬 옥',
    溫: '따뜻할 온',
    擁: '안을 옹',
    翁: '늙은이 옹',
    瓦: '기와 와',
    臥: '누울 와',
    完: '완전할 완',
    緩: '느릴 완',
    曰: '가로 왈',
    往: '갈 왕',
    王: '임금 왕',
    外: '밖 외',
    畏: '두려워할 외',
    搖: '흔들 요',
    腰: '허리 요',
    要: '구할 요',
    謠: '노래 요',
    遙: '멀 요',
    慾: '욕심 욕',
    欲: '하고자 할 욕',
    浴: '목욕할 욕',
    辱: '욕보일 욕',
    勇: '날랠 용',
    容: '얼굴 용',
    庸: '쓸 용',
    用: '쓸 용',
    于: '어조사 우',
    偶: '짝 우',
    優: '넉넉할 우',
    又: '또 우',
    友: '벗 우',
    右: '오른쪽 우',
    宇: '집 우',
    尤: '더욱 우',
    愚: '어리석을 우',
    憂: '근심할 우',
    牛: '소 우',
    羽: '깃 우',
    遇: '만날 우',
    郵: '역참 우',
    雨: '비 우',
    云: '이를 운',
    運: '돌 운',
    雲: '구름 운',
    韻: '운 운',
    雄: '수컷 웅',
    元: '으뜸 원',
    原: '근원 원',
    員: '관원 원',
    圓: '둥글 원',
    園: '동산 원',
    怨: '원망할 원',
    援: '당길 원',
    源: '근원 원',
    遠: '멀 원',
    院: '담 원',
    願: '바랄 원',
    月: '달 월',
    越: '넘을 월',
    位: '자리 위',
    偉: '훌륭할 위',
    僞: '거짓 위',
    危: '위태할 위',
    圍: '둘레 위',
    委: '맡길 위',
    威: '위엄 위',
    慰: '위로할 위',
    爲: '할 위',
    緯: '씨 위',
    胃: '밥통 위',
    衛: '지킬 위',
    謂: '이를 위',
    違: '어길 위',
    乳: '젖 유',
    儒: '선비 유',
    唯: '오직 유',
    幼: '어릴 유',
    幽: '그윽할 유',
    悠: '멀 유',
    惟: '생각할 유',
    愈: '나을 유',
    有: '있을 유',
    柔: '부드러울 유',
    油: '기름 유',
    猶: '오히려 유',
    由: '말미암을 유',
    維: '맬 유',
    裕: '넉넉할 유',
    誘: '꾈 유',
    遊: '놀 유',
    遺: '끼칠 유',
    酉: '닭 유',
    肉: '고기 육',
    育: '기를 육',
    潤: '젖을 윤',
    閏: '윤달 윤',
    恩: '은혜 은',
    銀: '은 은',
    隱: '숨길 은',
    乙: '새 을',
    吟: '읊을 음',
    淫: '음란할 음',
    陰: '그늘 음',
    音: '소리 음',
    飮: '마실 음',
    泣: '울 읍',
    邑: '고을 읍',
    凝: '엉길 응',
    應: '응할 응',
    依: '의지할 의',
    儀: '거동 의',
    宜: '마땅할 의',
    意: '뜻 의',
    疑: '의심할 의',
    矣: '어조사 의',
    義: '옳을 의',
    衣: '옷 의',
    議: '의논할 의',
    醫: '의원 의',
    二: '두 이',
    以: '써 이',
    夷: '오랑캐 이',
    已: '이미 이',
    異: '다를 이',
    移: '옮길 이',
    而: '말 이을 이',
    耳: '귀 이',
    益: '더할 익',
    翼: '날개 익',
    人: '사람 인',
    仁: '어질 인',
    印: '도장 인',
    因: '인할 인',
    姻: '혼인 인',
    寅: '동방 인',
    引: '끌 인',
    忍: '참을 인',
    認: '알 인',
    一: '한 일',
    日: '날 일',
    逸: '편안할 일',
    任: '맡길 임',
    壬: '아홉째 천간 임',
    賃: '품팔이 임',
    入: '들 입',
    刺: '찌를 자',
    姉: '손윗누이 자',
    姿: '맵시 자',
    子: '아들 자',
    字: '글자 자',
    恣: '방자할 자',
    慈: '사랑할 자',
    玆: '이 자',
    紫: '자줏빛 자',
    者: '놈 자',
    自: '스스로 자',
    資: '재물 자',
    作: '지을 작',
    昨: '어제 작',
    爵: '벼슬 작',
    酌: '따를 작',
    殘: '해칠 잔',
    暫: '잠시 잠',
    潛: '잠길 잠',
    雜: '섞일 잡',
    丈: '어른 장',
    場: '마당 장',
    墻: '담 장',
    壯: '씩씩할 장',
    奬: '장려할 장',
    將: '장차 장',
    帳: '휘장 장',
    張: '베풀 장',
    掌: '손바닥 장',
    牆: '담 장',
    章: '글 장',
    粧: '단장할 장',
    腸: '창자 장',
    臟: '내장 장',
    莊: '장중할 장',
    葬: '장사지낼 장',
    藏: '감출 장',
    裝: '꾸밀 장',
    長: '길 장',
    障: '가로막을 장',
    再: '두 재',
    哉: '어조사 재',
    在: '있을 재',
    宰: '재상 재',
    才: '재주 재',
    材: '재목 재',
    栽: '심을 재',
    災: '재앙 재',
    裁: '마를 재',
    財: '재물 재',
    載: '실을 재',
    爭: '다툴 쟁',
    低: '밑 저',
    底: '바닥 저',
    抵: '거스를 저',
    著: '나타날 저',
    貯: '쌓을 저',
    寂: '고요할 적',
    摘: '딸 적',
    敵: '원수 적',
    滴: '물방울 적',
    的: '과녁 적',
    積: '쌓을 적',
    籍: '서적 적',
    績: '길쌈할 적',
    賊: '도둑 적',
    赤: '붉을 적',
    跡: '자취 적',
    蹟: '자취 적',
    適: '갈 적',
    傳: '전할 전',
    全: '온전할 전',
    典: '법 전',
    前: '앞 전',
    專: '오로지 전',
    展: '펼 전',
    戰: '싸울 전',
    殿: '큰 집 전',
    田: '밭 전',
    轉: '구를 전',
    錢: '돈 전',
    電: '번개 전',
    切: '끊을 절',
    折: '꺾을 절',
    竊: '훔칠 절',
    節: '마디 절',
    絶: '끊을 절',
    占: '차지할 점',
    店: '가게 점',
    漸: '점점 점',
    點: '점 점',
    接: '사귈 접',
    蝶: '나비 접',
    丁: '고무래 정',
    井: '우물 정',
    亭: '정자 정',
    停: '머무를 정',
    定: '정할 정',
    庭: '뜰 정',
    廷: '조정 정',
    征: '칠 정',
    情: '뜻 정',
    政: '정사 정',
    整: '가지런할 정',
    正: '바를 정',
    淨: '깨끗할 정',
    程: '단위 정',
    精: '정할 정',
    訂: '바로잡을 정',
    貞: '곧을 정',
    靜: '고요할 정',
    頂: '정수리 정',
    制: '억제할 제',
    堤: '방죽 제',
    帝: '임금 제',
    弟: '아우 제',
    提: '끌 제',
    濟: '건널 제',
    祭: '제사 제',
    第: '차례 제',
    製: '지을 제',
    諸: '모두 제',
    除: '덜 제',
    際: '사이 제',
    題: '표제 제',
    齊: '가지런할 제',
    兆: '조짐 조',
    助: '도울 조',
    弔: '조상할 조',
    操: '잡을 조',
    早: '일찍 조',
    朝: '아침 조',
    條: '가지 조',
    潮: '조수 조',
    照: '비출 조',
    燥: '마를 조',
    祖: '조상 조',
    租: '세금 조',
    組: '끈 조',
    調: '고를 조',
    造: '만들 조',
    鳥: '새 조',
    族: '겨레 족',
    足: '발 족',
    存: '있을 존',
    尊: '높을 존',
    卒: '군사 졸',
    拙: '졸할 졸',
    宗: '마루 종',
    從: '좇을 종',
    種: '씨 종',
    終: '끝날 종',
    縱: '늘어질 종',
    鐘: '종 종',
    佐: '도울 좌',
    坐: '앉을 좌',
    左: '왼 좌',
    座: '자리 좌',
    罪: '허물 죄',
    主: '주인 주',
    住: '살 주',
    周: '두루 주',
    奏: '아뢸 주',
    宙: '집 주',
    州: '고을 주',
    晝: '낮 주',
    朱: '붉을 주',
    柱: '기둥 주',
    株: '그루 주',
    注: '물댈 주',
    洲: '섬 주',
    珠: '구슬 주',
    舟: '배 주',
    走: '달릴 주',
    酒: '술 주',
    鑄: '부어 만들 주',
    竹: '대 죽',
    俊: '준걸 준',
    準: '법도 준',
    遵: '좇을 준',
    中: '가운데 중',
    仲: '버금 중',
    衆: '무리 중',
    重: '무거울 중',
    卽: '곧 즉',
    增: '더할 증',
    憎: '미워할 증',
    曾: '일찍 증',
    症: '증세 증',
    蒸: '찔 증',
    證: '증거 증',
    贈: '보낼 증',
    之: '갈 지',
    只: '다만 지',
    地: '땅 지',
    志: '뜻 지',
    持: '가질 지',
    指: '손가락 지',
    支: '지탱할 지',
    智: '지혜 지',
    枝: '가지 지',
    止: '그칠 지',
    池: '못 지',
    知: '알 지',
    紙: '종이 지',
    至: '이를 지',
    誌: '기록할 지',
    遲: '늦을 지',
    直: '곧을 직',
    織: '짤 직',
    職: '벼슬 직',
    振: '떨칠 진',
    珍: '보배 진',
    盡: '다할 진',
    眞: '참 진',
    辰: '별 진',
    進: '나아갈 진',
    鎭: '진압할 진',
    陣: '진칠 진',
    陳: '베풀 진',
    震: '진동할 진',
    姪: '조카 질',
    疾: '병 질',
    秩: '차례 질',
    質: '바탕 질',
    執: '잡을 집',
    集: '모일 집',
    徵: '부를 징',
    懲: '혼날 징',
    且: '또 차',
    借: '빌 차',
    差: '어긋날 차',
    次: '버금 차',
    此: '이 차',
    車: '수레 거',
    捉: '잡을 착',
    着: '붙을 착',
    錯: '섞일 착',
    讚: '기릴 찬',
    贊: '도울 찬',
    察: '살필 찰',
    參: '간여할 참',
    慘: '참혹할 참',
    慙: '부끄러울 참',
    倉: '곳집 창',
    創: '비롯할 창',
    唱: '노래 창',
    昌: '창성할 창',
    暢: '펼 창',
    窓: '창 창',
    蒼: '푸를 창',
    債: '빚 채',
    彩: '무늬 채',
    採: '캘 채',
    菜: '나물 채',
    冊: '책 책',
    策: '꾀 책',
    責: '꾸짖을 책',
    妻: '아내 처',
    處: '곳 처',
    尺: '자 척',
    戚: '겨레 척',
    拓: '열 척',
    斥: '물리칠 척',
    千: '일천 천',
    天: '하늘 천',
    川: '내 천',
    泉: '샘 천',
    淺: '얕을 천',
    薦: '천거할 천',
    賤: '천할 천',
    踐: '밟을 천',
    遷: '옮길 천',
    哲: '밝을 철',
    徹: '뚫을 철',
    鐵: '쇠 철',
    尖: '뾰족할 첨',
    添: '더할 첨',
    妾: '첩 첩',
    廳: '관청 청',
    晴: '갤 청',
    淸: '맑을 청',
    聽: '들을 청',
    請: '청할 청',
    靑: '푸를 청',
    替: '바꿀 체',
    滯: '막힐 체',
    逮: '잡을 체',
    遞: '갈마들 체',
    體: '몸 체',
    初: '처음 초',
    抄: '베낄 초',
    招: '부를 초',
    礎: '주춧돌 초',
    秒: '초 초',
    肖: '닮을 초',
    草: '풀 초',
    超: '넘을 초',
    促: '재촉할 촉',
    燭: '촛불 촉',
    觸: '닿을 촉',
    寸: '마디 촌',
    村: '마을 촌',
    總: '거느릴 총',
    聰: '총명할 총',
    銃: '총 총',
    催: '재촉할 최',
    最: '가장 최',
    抽: '뽑을 추',
    推: '밀 추',
    秋: '가을 추',
    追: '쫓을 추',
    醜: '추할 추',
    丑: '소 축',
    畜: '가축 축',
    祝: '빌 축',
    築: '쌓을 축',
    縮: '다스릴 축',
    蓄: '쌓을 축',
    逐: '쫓을 축',
    春: '봄 춘',
    出: '날 출',
    充: '찰 충',
    忠: '충성 충',
    蟲: '벌레 충',
    衝: '찌를 충',
    取: '취할 취',
    吹: '불 취',
    就: '이룰 취',
    臭: '냄새 취',
    趣: '달릴 취',
    醉: '취할 취',
    側: '곁 측',
    測: '잴 측',
    層: '층 층',
    値: '값 치',
    恥: '부끄러워할 치',
    治: '다스릴 치',
    置: '둘 치',
    致: '보낼 치',
    齒: '이 치',
    則: '법칙 칙',
    親: '친할 친',
    七: '일곱 칠',
    漆: '옻 칠',
    侵: '침노할 침',
    寢: '잠잘 침',
    枕: '베개 침',
    沈: '가라앉을 침',
    浸: '담글 침',
    針: '바늘 침',
    稱: '일컬을 칭',
    快: '쾌할 쾌',
    他: '다를 타',
    墮: '떨어질 타',
    妥: '온당할 타',
    打: '칠 타',
    卓: '높을 탁',
    托: '밀 탁',
    濁: '흐릴 탁',
    濯: '씻을 탁',
    彈: '탄알 탄',
    歎: '탄식할 탄',
    炭: '숯 탄',
    誕: '태어날 탄',
    奪: '빼앗을 탈',
    脫: '벗을 탈',
    探: '찾을 탐',
    貪: '탐할 탐',
    塔: '탑 탑',
    湯: '끓일 탕',
    太: '클 태',
    怠: '게으름 태',
    態: '모양 태',
    殆: '위태할 태',
    泰: '클 태',
    擇: '가릴 택',
    澤: '못 택',
    吐: '토할 토',
    土: '흙 토',
    討: '칠 토',
    痛: '아플 통',
    統: '큰 줄기 통',
    通: '통할 통',
    退: '물러날 퇴',
    投: '던질 투',
    透: '통할 투',
    鬪: '싸움 투',
    特: '수컷 특',
    把: '잡을 파',
    播: '뿌릴 파',
    波: '물결 파',
    派: '물 갈래 파',
    破: '깨뜨릴 파',
    罷: '방면할 파',
    頗: '자못 파',
    判: '판가름할 판',
    板: '널빤지 판',
    版: '판목 판',
    販: '팔 판',
    八: '여덟 팔',
    敗: '패할 패',
    貝: '조개 패',
    便: '편할 편',
    偏: '치우칠 편',
    片: '조각 편',
    篇: '책 편',
    編: '엮을 편',
    遍: '두루 편',
    平: '평평할 평',
    評: '평론할 평',
    幣: '비단 폐',
    廢: '폐할 폐',
    弊: '폐단 폐',
    肺: '허파 폐',
    蔽: '덮을 폐',
    閉: '닫을 폐',
    包: '쌀 포',
    布: '베 포',
    抱: '안을 포',
    捕: '사로잡을 포',
    浦: '물가 포',
    胞: '태보 포',
    飽: '배부를 포',
    幅: '너비 폭',
    暴: '사나울 폭',
    爆: '터질 폭',
    標: '표 표',
    漂: '떠돌 표',
    票: '표 표',
    表: '겉 표',
    品: '물건 품',
    豊: '풍년 풍',
    風: '바람 풍',
    彼: '저 피',
    疲: '지칠 피',
    皮: '가죽 피',
    被: '이불 피',
    避: '피할 피',
    匹: '짝 필',
    必: '반드시 필',
    畢: '마칠 필',
    筆: '붓 필',
    下: '아래 하',
    何: '어찌 하',
    夏: '여름 하',
    河: '물 하',
    荷: '연 하',
    賀: '하례 하',
    學: '배울 학',
    鶴: '학 학',
    寒: '찰 한',
    恨: '한할 한',
    旱: '가물 한',
    汗: '땀 한',
    漢: '한수 한',
    閑: '막을 한',
    限: '한계 한',
    韓: '나라 이름 한',
    割: '나눌 할',
    含: '머금을 함',
    咸: '다 함',
    陷: '빠질 함',
    合: '합할 합',
    巷: '거리 항',
    恒: '항상 항',
    抗: '막을 항',
    港: '항구 항',
    航: '배 항',
    項: '목 항',
    亥: '돼지 해',
    奚: '어찌 해',
    害: '해칠 해',
    海: '바다 해',
    解: '풀 해',
    該: '그 해',
    核: '씨 핵',
    幸: '다행 행',
    行: '다닐 행',
    享: '누릴 향',
    向: '향할 향',
    鄕: '시골 향',
    響: '울림 향',
    香: '향기 향',
    虛: '빌 허',
    許: '허락할 허',
    憲: '법 헌',
    獻: '바칠 헌',
    軒: '추녀 헌',
    險: '험할 험',
    驗: '시험할 험',
    革: '가죽 혁',
    懸: '매달 현',
    玄: '검을 현',
    現: '나타날 현',
    絃: '악기 줄 현',
    縣: '고을 현',
    賢: '어질 현',
    顯: '나타날 현',
    穴: '구멍 혈',
    血: '피 혈',
    協: '화할 협',
    脅: '옆구리 협',
    亨: '형통할 형',
    兄: '맏 형',
    刑: '형벌 형',
    形: '모양 형',
    螢: '반디 형',
    衡: '저울대 형',
    兮: '어조사 혜',
    惠: '은혜 혜',
    慧: '슬기로울 혜',
    乎: '어조사 호',
    互: '서로 호',
    呼: '부를 호',
    好: '좋을 호',
    戶: '지게 호',
    毫: '가는 털 호',
    浩: '클 호',
    湖: '호수 호',
    胡: '오랑캐 호',
    虎: '범 호',
    號: '부르짖을 호',
    護: '보호할 호',
    豪: '호걸 호',
    惑: '미혹할 혹',
    或: '혹 혹',
    婚: '혼인할 혼',
    昏: '어두울 혼',
    混: '섞을 혼',
    魂: '넋 혼',
    忽: '소홀히 할 홀',
    弘: '넓을 홍',
    洪: '큰물 홍',
    紅: '붉을 홍',
    鴻: '큰 기러기 홍',
    化: '될 화',
    和: '화할 화',
    火: '불 화',
    畵: '그림 화',
    禍: '재앙 화',
    禾: '벼 화',
    花: '꽃 화',
    華: '빛날 화',
    話: '말할 화',
    貨: '재화 화',
    擴: '넓힐 확',
    確: '굳을 확:碻',
    穫: '벼 벨 확',
    丸: '알 환',
    患: '근심 환',
    換: '바꿀 환',
    歡: '기뻐할 환',
    環: '고리 환',
    還: '돌아올 환',
    活: '살 활',
    況: '하물며 황',
    皇: '임금 황',
    荒: '거칠 황',
    黃: '누를 황',
    回: '돌 회',
    悔: '뉘우칠 회',
    懷: '품을 회',
    會: '모일 회',
    劃: '그을 획',
    獲: '얻을 획',
    橫: '가로 횡',
    孝: '효도 효',
    效: '본받을 효',
    曉: '새벽 효',
    侯: '제후 후',
    候: '기후 후',
    厚: '두터울 후',
    後: '뒤 후',
    訓: '가르칠 훈',
    毁: '헐 훼',
    揮: '휘두를 휘',
    輝: '빛날 휘',
    休: '쉴 휴',
    携: '가질 휴',
    凶: '흉할 흉',
    胸: '가슴 흉',
    黑: '검을 흑',
    吸: '숨 들이쉴 흡',
    興: '일어날 흥',
    喜: '기쁠 희',
    希: '바랄 희',
    戱: '놀이 희',
    稀: '드물 희',
};

const convertCharToHanja = (c, hanjaDifficultyDegree) => {
    if (toClosestHanjaSound[c] !== undefined) {
        const closest = toClosestHanjaSound[c];
        const hanjaChars = toHanjaChars[closest];
        const chosenHanja =
            hanjaChars[
                Math.floor(
                    (Math.random() *
                        hanjaChars.length *
                        hanjaDifficultyDegree) /
                        100
                )
            ];
        return [closest, chosenHanja];
    } else {
        if (hanjaToMeaning[c] !== undefined) {
            const meaning = hanjaToMeaning[c];
            const sound = meaning.charAt(meaning.length - 1);
            return [sound, c];
        } else {
            return [c, c];
        }
    }
};

export const convertTextToHanja = (text, hanjaDifficultyDegree) => {
    const outputChars = [];
    const outputSoundsChars = [];
    for (let c of text.split('')) {
        const [closest, chosenHanja] = convertCharToHanja(
            c,
            hanjaDifficultyDegree
        );
        outputChars.push(chosenHanja);
        outputSoundsChars.push(closest);
    }
    return [outputChars.join(''), outputSoundsChars.join('')];
};

export const addHanjaMeaningTooltip = (text) => {
    const outputs = [];
    for (let c of text.split('')) {
        if (hanjaToMeaning[c] !== undefined) {
            const meaning = hanjaToMeaning[c];
            outputs.push(
                `<span class="my-tooltip">${c}<span class="my-tooltiptext unselectable">${meaning}</span></span>`
            );
        } else {
            outputs.push(c);
        }
    }
    return outputs.join('');
};
